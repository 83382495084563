import React, {useEffect, useMemo, useRef, useState} from "react";

import {View, Flex, Text, FormLabel} from "../../components/Themed";
import {CONSTANTS, styles} from "../../constants/Styles";
import {useBlockchain} from "../../diamond-realm-api/hooks/useBlockchain";
import useWallet from "../../diamond-realm-api/hooks/useWallet";
import {FormatDecimalAmount, FormatNumber} from "../../utils/NumbersUtils";
import {WalletStackParamList} from "../../types";
import {NativeStackScreenProps} from "@react-navigation/native-stack";
import useTokens from "../../diamond-realm-api/hooks/useTokens";
import TransferToken from "../../components/tokens/TransferToken";
import MintToken from "../../components/tokens/MintToken";
import ClipboardButton from "../../components/ClipboardButton";
import {AddressShort} from "../../utils/addressUtils";
import {Image, ScrollView} from 'react-native';
import ButtonUi from "../../components/ButtonUi";
import {faEnvelopeOpenText as faEnvelopeNew, faMoneyBillTransfer} from "@fortawesome/free-solid-svg-icons"
import {FontAwesomeIcon} from "@fortawesome/react-native-fontawesome";
import Colors from "../../constants/Colors";
import EditMetadata from "../../components/tokens/EditMetadata";
import init from "../../diamond-realm-api/paillier-rust";
import * as paillier_wasm from "../../diamond-realm-api/paillier-rust";

import {PublishTokenAddress, TokenValue} from "../../diamond-realm-api/tokens";
import {toHexString} from "../../diamond-realm-api/crypto";
import ButtonText from "../../components/ButtonText";
import {encodeAddress} from "@polkadot/keyring";
import Loading from "../../components/Loading";
import {ImageDimensions} from "../../utils/imageUtils";

const TokenScreen = ({route, navigation}: NativeStackScreenProps<WalletStackParamList, "Token">) => {
    const blockchain = useBlockchain()
    const wallet = useWallet()
    let {id} = route.params
    const {accounts, metadata, details, subscribeToken,} = useTokens()
    const [showTransfer, setShowTransfer] = useState(false)
    const [showMint, setShowMint] = useState(false)
    const [showUpdate, setShowUpdate] = useState(false)
    const [decryptedNumber, setDecryptedNumber] = useState("")
    const [w, setW] = useState(0)
    const [h, setH] = useState(0)

    useEffect(() => {
      //  console.log("id")
        subscribeToken(id)

    }, [blockchain.api, id, wallet.localWallet])

    const account = accounts[id] && accounts[id]

    const m = metadata[id] && metadata[id]
    const detail = details[id]

    useEffect(() => {
        decrypt()
    }, [account, account?.balance])

    useEffect(() => {
        let {w, h} = ImageDimensions(m?.image)
        setW(w)
        setH(h)
    }, [m])


    //  end of hooks //////


    const decrypt = async () => {
        if (decryptedNumber) {
            console.log("decrypting again, already have decrypted number")
            //return
        }
        console.log("decrypt", account)
        if (account?.encryptedBalance) {
            if (wallet?.localWallet?.secretKey) {
                console.log("start wasm init")
                init().then((_exports) => {
                    console.log("finish wasm init")
                    let seed = `${toHexString(wallet!.localWallet!.secretKey)}::accounts::0`
                    let d = paillier_wasm.decrypt_number(seed, account?.encryptedBalance)
                    console.log(d)
                    setDecryptedNumber(d)
                })
            }
        }
    }

    const acountValue = useMemo(() => {
        if (account?.balance) return TokenValue(account.balance, m)
    }, [account?.balance, m])

    const marketValue = useMemo(() => {
        if (detail?.supply) return TokenValue(detail.supply, m)
    }, [detail?.supply, m])

    const owner = detail?.owner?.toString() === wallet?.ss58Address
    const issuer = detail?.issuer?.toString() === wallet?.ss58Address

    console.log("token screen", account, m, detail)

    return (
        <View style={styles.container}>
            <ScrollView
                keyboardShouldPersistTaps={'handled'}
                style={{
                    //alignItems: "center",
                    // backgroundColor: Colors.dark.backgroundAlpha,
                    width: "100%",
                    padding: 12,
                    paddingBottom: 100,
                    //maxWidth: CONSTANTS.pageWidth,
                }}>
                <View style={{
                    alignItems: "center",
                    flex: 1,
                    paddingTop: 24,
                    paddingHorizontal: 12,

                }}>
                    {/*    <Flex style={{
                        width: "100%",
                        flex: 1,
                        justifyContent: "space-between",
                        ...styles.bordered,
                    }}>*/}
                    <View style={{
                        maxWidth: CONSTANTS.pageWidth,
                        flex: 1,
                        width: "100%",
                    }}>
                        {m?.image &&
                            <Image source={
                                {
                                    width: 256,
                                    height: 256,
                                    uri: m.image
                                }
                            }/>
                        }
                        <Text style={styles.header}>{m?.name}</Text>
                        <ClipboardButton copyText={id}
                                         text={AddressShort(id)}
                        />

                        <View>
                            <Flex style={{
                                width: "100%",
                                flex: 1,
                                marginTop: 6,

                                //justifyContent: "space-between"
                            }}>
                                <Text style={{fontSize: 7}}>
                                    {m && FormatDecimalAmount(parseInt(account?.balance.toString() || "0"), m.decimals, )}
                                </Text>

                                <View style={{paddingLeft: 12}}>
                                    <Text style={{fontSize: 7}}>
                                        {m?.symbol}
                                    </Text>
                                </View>
                            </Flex>
                        </View>
                        <Text style={{fontSize: 6}}>
                            testnet Plaintext Verification
                        </Text>
                        <View>
                            <Flex style={{
                                width: "100%",
                                flex: 1,
                                marginTop: 6,
                                //justifyContent: "space-between"
                            }}>
                                {m && decryptedNumber ? <Text style={styles.titleHeavy}>
                                        {FormatDecimalAmount(parseInt(decryptedNumber), m.decimals, 4)}
                                    </Text> :
                                    <Loading/>}


                                <View style={{paddingLeft: 12}}>
                                    <Text style={styles.title}>
                                        {m?.symbol}
                                    </Text>
                                </View>
                            </Flex>
                        </View>
                        <Text style={{fontSize: 7}}>
                            Ciphertext
                        </Text>
                        {!!acountValue?.worth &&
                            <Text style={styles.item}>
                                {`Worth $${FormatDecimalAmount(acountValue.worth, 0, 0)}`}
                            </Text>
                        }

                        <View style={{
                            marginTop: 12,
                        }}>

                            {!!acountValue?.valueUsd &&
                                <View>
                                    {/*<Flex style={{
                                        width: "100%",
                                        flex: 1,
                                        marginTop: 6,
                                        justifyContent: "space-between"
                                    }}>*/}
                                    <Text style={styles.item}>
                                        {acountValue.valueUsd && `$${FormatNumber(acountValue.valueUsd, 2)} / ${m.name}`}
                                    </Text>


                                </View>
                            }
                            {m?.decimals &&
                                <View style={{
                                    // marginTop: 12,
                                }}>
                                    <Text style={styles.item}>
                                        {`On Chain Capacity ${detail && FormatDecimalAmount(parseInt(detail?.supply.toString() || "0"), m?.decimals, 0)} ${m?.symbol}`}
                                    </Text>
                                    <Text style={styles.item}>
                                        {`Capital Valuation of $${marketValue?.worth && FormatNumber(marketValue.worth, 0)} USD `}
                                    </Text>

                                </View>
                            }
                        </View>

                        <View style={{marginTop: 12}}>
                            <Flex>
                                <ButtonUi minimal={true} onPress={() => {
                                    setShowTransfer(!showTransfer)
                                    setShowMint(false)
                                }
                                }>
                                    <Text>Transfer</Text>
                                </ButtonUi>

                            </Flex>
                            {showTransfer &&
                                <TransferToken id={id}/>
                            }

                            {issuer &&
                                <>
                                    <Flex>
                                        <ButtonUi minimal={true} onPress={() => {
                                            setShowMint(!showMint)
                                            setShowTransfer(false)
                                        }}>
                                            <Text>Mint</Text>
                                        </ButtonUi>
                                    </Flex>
                                    {showMint &&

                                        <MintToken id={id}/>
                                    }
                                </>
                            }

                            {owner &&
                                <>
                                    <Flex>
                                        <ButtonUi minimal={true} onPress={() => {
                                            setShowUpdate(!showUpdate)
                                            setShowTransfer(false)
                                            setShowMint(false)
                                        }}>
                                            <Text>Edit Metadata</Text>
                                        </ButtonUi>
                                    </Flex>
                                    {showUpdate &&

                                        <EditMetadata id={id} onFinish={() => setShowUpdate(false)}/>
                                    }
                                </>
                            }
                        </View>
                    </View>

                </View>
            </ScrollView>
        </View>
    )
}
export default TokenScreen


/*
Public Address:
0x37fc9d9b30381160f4968307b5ce8befb854e35d04aac65e5edd0a8043d838b9
Secret Key:
0x5e2015f169a406fca03a4fa37021a35314628a2c71b973499c24c6980426a78437fc9d9b30381160f4968307b5ce8befb854e35d04aac65e5edd0a8043d838b9
Seed Phrase:
energy trust frame gasp jewel allow analyst vivid immense neither canal problem demand canyon odor vague gorilla fragile spatial divert off educate myth echo
 */