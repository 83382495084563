/**
 * Learn more about deep linking with React Navigation
 * https://reactnavigation.org/docs/deep-linking
 * https://reactnavigation.org/docs/configuring-links
 */

import { LinkingOptions } from '@react-navigation/native';
import * as Linking from 'expo-linking';

import { RootStackParamList } from '../types';

const linking: LinkingOptions<RootStackParamList> = {
  prefixes: [Linking.createURL('/')],
  config: {
    screens: {
      Root: {
        screens: {
          Wallet: {
            screens: {
              TabOneScreen: 'one',
              Wallet: "Wallet",
              Token: "Token",
            },
          },
          Messages: {
            screens: {
              Messages: 'Messages',
            },
          },
          Dao: {
            screens: {
              TabThreeScreen: 'three',
            },
          },
          Files: {
            screens: {
              TabFourScreen: 'four',
            },
          },
          Rolodex: {
            screens: {
              TabFiveScreen: 'five',
            },
          },
          Directory: {
            screens: {
              TabSixScreen: 'six',
            },
          },
          Account: {
            screens: {
              TabSevenScreen: 'seven',
              Settings: "Settings",
              YourKey: "YourKey",
              LoadKey: "LoadKey",
              GenerateKey: "GenerateKey",
            },
          },
        },
      },
      Modal: 'modal',
      NotFound: '*',
    },
  },
};

export default linking;
