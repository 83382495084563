import {ImageBackground, ScrollView, TextInput, TouchableHighlight} from 'react-native';
import {getDocumentAsync} from "expo-document-picker"
//import { DocumentPicker, ImagePicker } from 'expo';
import {Flex, FormLabel, Text, View} from '../components/Themed';

import React, {useEffect, useRef, useState} from "react";

import {CONSTANTS, styles} from "../constants/Styles";
import ButtonText from '../components/ButtonText';
import useWallet from "../diamond-realm-api/hooks/useWallet";
import {blake2AsHex, mnemonicToMiniSecret} from "@polkadot/util-crypto";
import {
    ConvertEdPublic2CurvePublic,
    ConvertEdSecret2CurvePair,
    encrypt,
    toHexString
} from '../diamond-realm-api/crypto';
import {box, sign} from "tweetnacl";
import * as ed2curve from 'ed2curve';

import {
    CreateEncryptedFile, CreateEncryptedFolder,
    GetEncryptedFile,
    GetEncryptedFiles,
    GetEncryptedFolders,

} from "../diamond-realm-api/storage";
import {useForm} from "../hooks/useForm";
import {useBlockchain} from "../diamond-realm-api/hooks/useBlockchain";
import Loading from "../components/Loading";
import {FileHeaderI, FileI, FolderHeaderI} from "../diamond-realm-api/interfaces";
import useInterval from "../hooks/useInterval";
import {FontAwesomeIcon} from "@fortawesome/react-native-fontawesome";
import Colors from "../constants/Colors";
import {faClose, faFile, faFolder, faHome, faFolderPlus, faFileCirclePlus} from "@fortawesome/free-solid-svg-icons";
import FileView from "../components/Storage/FileView";
import {AddressShort} from "../utils/addressUtils";
import ButtonUi from '../components/ButtonUi';

export default function FilesScreen() {
    const blockchain = useBlockchain()
    const wallet = useWallet()
    const input_ref = useRef<TextInput | null>(null);
    const [folders, setFolders] = useState<FolderHeaderI[]>([])
    const [files, setFiles] = useState<FileHeaderI[]>([])
    const [showNewFolder, setShowNewFolder] = useState(false)
    const [showUpload, setShowUpload] = useState(false)

    const initForm = {
        fileName: "",
        newFolderName: "",
        viewFileName: "",
        symbol: "",
        decimals: "12",
        mint: "1_000_000",
        folderHeader: {
            parentId: "",
            name: "/"
        }
    }
    const form = useForm(initForm)

    useEffect(() => {
        work()
        refresh()
        // handleSubscribeFolders()
        // handleSubscribeFiles()
    }, [])

    useEffect(() => {
        refresh()
    }, [wallet?.localWallet?.address, form.formData.folderHeader])

    useInterval(() => {
        refresh()
    }, 6000)

    // end of hooks

    const refresh = async () => {
        handleGetFiles()
        handleGetFolders()
    }


    const work = () => {
        if (!(wallet.localWallet?.seedPhrase)) return
        const seed = mnemonicToMiniSecret(wallet.localWallet.seedPhrase);


        //console.log("Hex Seed",toHexString(seed))

        //const pair = box.keyPair.fromSecretKey(seed)
        //console.log("pair from nacl", toHexString(pair.publicKey))

        const pairSigner = sign.keyPair.fromSeed(seed)
        console.log("pair SIGNER from nacl", toHexString(pairSigner.publicKey))

        const curvePublic = ed2curve.convertPublicKey(pairSigner.publicKey)
        const curvePrivate = ed2curve.convertSecretKey(seed)
        console.log("curve25519 public key from signer public", toHexString(curvePublic))
        console.log("curve25519 private key from signer private", toHexString(curvePrivate))

        const pair = box.keyPair.fromSecretKey(curvePrivate)
        console.log("box pair Public Key from nacl of curvePrivateSeed", toHexString(pair.publicKey))

        const c2 = ed2curve.convertKeyPair(pairSigner)
        //tweetnacl.scalarMult.base(pairSigner.publicKey);

        console.log("curve25519 pair key", toHexString(c2?.publicKey), toHexString(c2?.secretKey))

    }

    const handleCheckSecret = () => {
        if (wallet?.localWallet?.seedPhrase) {
            //let addr = "0x721b1de933d05c645cecd3b1e03876598851a42e6b6b8ea94550f80121e6087a"
            ConvertEdSecret2CurvePair(wallet.localWallet.seedPhrase)
        }
    }
    const handleCheck = () => {
        if (wallet?.address) {
            ConvertEdPublic2CurvePublic(wallet.address)
        }
    }

    const handleCreateFile = async () => {
        form.setSubmitting(true)
        console.log("create file", form.formData.fileName)
        if (blockchain.api && wallet.localWallet && wallet.address && wallet.curvePair) {
            // todo iron this out. leaning towards it being a file only

            let json = {
                donkey: "super",
            }
            let data: FileI = {}
            if (form.formData.uploadFileUri) {
                data.uri = form.formData.uploadFileUri
                data.name = form.formData.uploadFileName
                data.size = form.formData.uploadFileSize
            }

            try {
                let res = await CreateEncryptedFile(
                    blockchain.api,
                    wallet.localWallet,
                    wallet.curvePair,
                    form.formData.folderHeader,
                    form.formData.fileName,
                    data,
                    form.formData.uploadFileSize,
                    () => {
                        form.setSubmitting(false)
                        setShowUpload(false)
                    }
                )
                form.setFormData(initForm)
                //setAssetId(res)
            } catch (err) {
                console.log(err)
                form.setSubmitting(false)
            }
        }
    }

    const handleCreateFolder = async () => {
        form.setSubmitting(true)
        console.log("create folder", form.formData.newFolderName, "in ", form.formData.folderHeader)
        if (blockchain.api && wallet.localWallet && wallet.address && wallet.curvePair) {
            try {
                let res = await CreateEncryptedFolder(
                    blockchain.api,
                    wallet.localWallet,
                    wallet.curvePair,
                    form.formData.folderHeader,
                    form.formData.newFolderName,
                    () => {
                        form.setSubmitting(false)
                        setShowNewFolder(false)
                    }
                )

            } catch (err) {
                console.log(err)
                form.setSubmitting(false)
            }
        }
    }

    const handleGetFiles = async () => {
        if (blockchain.api && wallet.localWallet && wallet.address && wallet.curvePair) {
            let r = await GetEncryptedFiles(
                blockchain.api,
                wallet.curvePair,
                form.formData.folderHeader,
            )
            if (r) {
                setFiles(r)
                console.log("Files", r)
            }
        }
    }

    /*const handleSubscribeFiles = async () => {
      if (blockchain.api && wallet.localWallet && wallet.address && wallet.curvePair) {
        let r = await SubscribeFiles(
          blockchain.api,
          wallet.curvePair,
          form.formData.folderHeader,
          setFiles
        )
      }
    }*/


    const handleGetFolders = async () => {
        if (blockchain.api && wallet.localWallet && wallet.address && wallet.curvePair) {
            let r = await GetEncryptedFolders(
                blockchain.api,
                wallet.curvePair,
                form.formData.folderHeader,
            )
            if (r) {
                setFolders(r)
            }
        }
    }
    /*const handleSubscribeFolders = async () => {
      if (blockchain.api && wallet.localWallet && wallet.address && wallet.curvePair) {
        let r = await SubscribeFolders(
          blockchain.api,
          wallet.curvePair,
          form.formData.folderHeader,
          setFolders
        )
      }
    }*/

    const handleGetFile = async () => {
        if (blockchain.api && wallet.localWallet && wallet.address && wallet.curvePair) {
            let r = await GetEncryptedFile({
                fileName: form.formData.fileName,
                folderHeader: form.formData.fileHeader,
                api: blockchain.api,
                curvePair: wallet.curvePair,
            })
        }
    }

    const handleDocumentPick = async () => {
        setShowUpload(true)
        let res = await getDocumentAsync({
            //type: "image/*"
        })
        if (res.output && res.output[0] && res.assets && res.assets[0]) {
            form.setFormData({
                ...form.formData,
                uploadFileUri: res.assets[0].uri,
                uploadFileName: res.output[0].name,
                uploadFileSize: res.output[0].size,
                fileName: res.output[0].name,
            })
        }
        console.log("pick file", res)
    }

    /*const handleDocumentPickOld = async () => {
        // somehow the file api changed underneath this is the old...
        setShowUpload(true)
        let res = await getDocumentAsync({
            //type: "image/!*"
        })
        if (res.type == "success") {
            form.setFormData({
                ...form.formData,
                uploadFileUri: res.uri,
                uploadFileName: res.name,
                uploadFileSize: res.size,
                fileName: res.name,
            })
        }
        console.log("pick file", res)
    }*/


    return (
        <View style={styles.container}>
            <ScrollView
                keyboardShouldPersistTaps={'handled'}
                style={{
                    //alignItems: "center",
                    // backgroundColor: Colors.dark.backgroundAlpha,
                    width: "100%",
                    padding: 12,
                    paddingBottom: 100,
                    maxWidth: CONSTANTS.pageWidth,
                }}>
                <View style={{

                    paddingBottom: 100,
                }}>

                    <Flex style={{
                        flex: 1,
                        justifyContent: "space-between",
                    }}>

                        <Flex>
                            <ButtonText minimal={true} style={{marginRight: 6}} disabled={false} onPress={() => {
                                form.setFormData({
                                    ...form.formData,
                                    folderHeader: {
                                        parentId: "",
                                        name: "/"
                                    }
                                })
                            }}>
                                <FontAwesomeIcon color={Colors.dark.text} icon={faHome}/>
                            </ButtonText>
                            {/*       <View>
                                <Text style={styles.header}>{AddressShort(form.formData.folderHeader.parentId)}</Text>
                            </View>
                     */}
                            {/*<View>
                                <Text> / </Text>
                            </View>*/}
                            <View>
                                <Text style={styles.header}>{form.formData.folderHeader.name}</Text>
                            </View>
                        </Flex>

                        <Flex style={{
                            flex: 1,
                            justifyContent: "flex-end",
                        }}>
                            <ButtonText selected={showNewFolder} minimal={true} style={{marginRight: 6}}
                                        disabled={false} onPress={() => {
                                setShowNewFolder(!showNewFolder)
                            }}>
                                <FontAwesomeIcon color={Colors.dark.text} icon={faFolderPlus}/>
                            </ButtonText>
                            <ButtonText selected={showUpload} minimal={true} style={{}} disabled={false}
                                        onPress={() => {
                                            showUpload ? setShowUpload(false) : handleDocumentPick()
                                        }}>
                                <FontAwesomeIcon color={Colors.dark.text} icon={faFileCirclePlus}/>
                            </ButtonText>
                        </Flex>

                    </Flex>

                    {showNewFolder && (
                        <>
                            <Text style={styles.header}>
                                New Encrypted Folder
                            </Text>

                            <View
                                style={{
                                    minWidth: 60,
                                }}
                            >
                                <FormLabel>
                                    Folder Name
                                </FormLabel>
                                <TextInput
                                    style={styles.input}
                                    placeholder=""
                                    autoFocus={false}
                                    value={form.formData.newFolderName}
                                    onChangeText={form.handleChange("newFolderName")}
                                    //onSubmitEditing={handleSubmit}
                                />


                                <View>
                                    <ButtonText selected={form.submitting}
                                                disabled={form.submitting || !form.formData.newFolderName}
                                                onPress={() => {
                                                    handleCreateFolder()
                                                }}>
                                        {form.submitting ? <Loading/> : "Create new Folder"}
                                    </ButtonText>
                                </View>
                            </View>
                        </>
                    )}


                    {showUpload && (
                        <>
                            <Text style={styles.header}>
                                Save Encrypted File
                            </Text>

                            <View
                                style={{
                                    minWidth: 60,
                                }}
                            >


                                <Flex style={{flex: 1}}>
                                    {form.formData.uploadFileSize &&
                                        <View>
                                            <Text>
                                                {form.formData.uploadFileName}
                                            </Text>
                                            <Text>
                                                {`${form.formData.uploadFileSize} Bytes`}
                                            </Text>
                                        </View>
                                    }
                                    <ButtonText disabled={false} onPress={() => {
                                        handleDocumentPick()
                                    }}>
                                        Pick File
                                    </ButtonText>
                                </Flex>

                                <FormLabel>
                                    File Name
                                </FormLabel>
                                <TextInput
                                    style={styles.input}
                                    placeholder=""
                                    autoFocus={false}
                                    value={form.formData.fileName}
                                    onChangeText={form.handleChange("fileName")}
                                    //onSubmitEditing={handleSubmit}
                                />
                                {form.formData.uploadFileUri &&
                                    <View>
                                        <img src={form.formData.uploadFileUri}/>
                                    </View>
                                }

                                <View>
                                    <ButtonText selected={form.submitting}
                                                disabled={form.submitting || !form.formData.fileName} onPress={() => {
                                        handleCreateFile()
                                    }}>
                                        {form.submitting ? <Loading/> : "Save File"}
                                    </ButtonText>
                                </View>
                            </View>

                        </>
                    )}


                    <View>
                        {folders && folders[0] && folders.map((folder, i) => (
                            <Flex key={"folder" + folder.name}>
                                <ButtonText minimal={true} disabled={false} onPress={() => {
                                    form.setFormData({
                                        ...form.formData,
                                        folderHeader: folder
                                    })
                                }}>
                                    <FontAwesomeIcon color={Colors.dark.text} icon={faFolder}
                                                     style={{marginRight: 6}}/>
                                    <Text>
                                        {folder.name}
                                    </Text>
                                </ButtonText>
                            </Flex>
                        ))}
                    </View>

                    <View>
                        {files.map((file, i) => {
                            if (file) return (
                                <View key={"file" + i}>
                                    <Flex>
                                        <ButtonUi minimal={true} disabled={false} onPress={() => {
                                            if (form.formData.viewFileName === file.name) {
                                                let fd = {...form.formData}
                                                delete fd.viewFileName
                                                form.setFormData(fd)
                                            } else {
                                                form.setFormData({
                                                    ...form.formData,
                                                    viewFileName: file.name
                                                })
                                            }

                                        }}>

                                            {/*   {form.formData.viewFileName &&
                                                <FontAwesomeIcon color={Colors.dark.text} icon={faClose}
                                                                 style={{marginRight: 6}}/>
                                            }*/}
                                            <FontAwesomeIcon color={Colors.dark.text} icon={faFile}
                                                             style={{marginRight: 6}}/>
                                            <Text>
                                                {file.name}
                                            </Text>
                                        </ButtonUi>
                                    </Flex>

                                    {(form.formData.viewFileName === file.name) &&
                                        <View style={{
                                            flex: 1,
                                        }}>
                                            <FileView folderHeader={form.formData.folderHeader}
                                                      fileName={form.formData.viewFileName}/>
                                        </View>
                                    }
                                </View>
                            )
                        })}
                    </View>

                </View>
            </ScrollView>
        </View>

    );
}


/*
   <View style={{marginTop: 120}}>
            <UiButton disabled={false} onPress={handleCheckSecret}>
              Check Secret
            </UiButton>
            <UiButton disabled={false} onPress={handleCheck}>
              Check Public
            </UiButton>

            <UiButton disabled={false} onPress={handleCreateFile}>
              {form.submitting ? <Loading/> : "Create Store"}
            </UiButton>

            <UiButton disabled={false} onPress={handleGetFile}>
              Get Store
            </UiButton>
          </View>
 */