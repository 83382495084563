import {ImageBackground, ScrollView, TextInput, TouchableHighlight} from 'react-native';


import {Flex, FormLabel, Text, View} from '../Themed';
import React, {useEffect, useRef, useState} from "react";

import {FontAwesomeIcon} from "@fortawesome/react-native-fontawesome";

import {
    faPaperPlane as faSent,
    faEnvelope,
} from "@fortawesome/free-solid-svg-icons";

import ButtonUi from '../ButtonUi';
import {MessageHeader} from "../../diamond-realm-api/interfaces";
import Colors from "../../constants/Colors";
import AccountNickText from "../AccountNickText";
import {blockTimeStampToDate} from "../../utils/TimeDate";
import MessageView from "./MessageView";
import {styles} from "../../constants/Styles";
import useWallet from "../../diamond-realm-api/hooks/useWallet";


interface propsI {
    messageHeader: MessageHeader
    viewMessageHeader: MessageHeader | undefined
    setViewMessageHeader: React.Dispatch<React.SetStateAction<MessageHeader | undefined>>
}

const MessageListItem = ({messageHeader, viewMessageHeader, setViewMessageHeader}: propsI) => {
    const wallet = useWallet()
    const sent = messageHeader.sender === wallet.address
    return (
        <View style={{
            marginVertical: 4,
            ...(sent && {marginLeft: 24}),
        }}>
            <ButtonUi selected={viewMessageHeader?.id === messageHeader.id} minimal={true} onPress={() => {
                if (viewMessageHeader?.id === messageHeader.id) {
                    setViewMessageHeader(undefined)
                } else {
                    setViewMessageHeader(messageHeader)
                }
            }}>
                <View style={{flex: 1}}>
                    <Flex style={{
                        alignItems: "center",
                        flexWrap: "wrap",
                        justifyContent: "space-between"
                    }}>
                        <Flex style={{
                            alignItems: "center",
                            flexWrap: "wrap",
                        }}>

                            <FontAwesomeIcon color={Colors.dark.text} icon={sent ? faSent : faEnvelope}
                                             style={{marginHorizontal: 6}}/>
                            <Text style={{fontWeight: "200", marginRight: 12}}>
                                <AccountNickText address={messageHeader.sender}/>
                            </Text>
                        </Flex>

                        <Text style={{fontWeight: "200"}}>
                            {messageHeader.date &&
                                blockTimeStampToDate(messageHeader.date)}
                        </Text>
                    </Flex>

                    <Text style={{fontWeight: "500", marginTop: 16}}>
                        {messageHeader.subject}
                    </Text>
                </View>
            </ButtonUi>

            {(viewMessageHeader?.id === messageHeader.id) &&
              <MessageView messageHeader={messageHeader}/>
            }
        </View>
    )
}

export default MessageListItem