import {GestureResponderEvent, TouchableHighlight} from "react-native";
import {Flex, Text, View} from "./Themed";
import {styles} from "../constants/Styles";
import React from "react";
import Colors from "../constants/Colors";

interface UiButtonI {
    disabled?: boolean
    onPress?: ((event: GestureResponderEvent) => void) | undefined
    children: React.ReactNode,
    minimal?: boolean,
    style?: any
    selected?: boolean
}

const ButtonUi = (
    {
        disabled,
        onPress,
        children,
        minimal,
        style,
        selected
    }: UiButtonI) => {
    let styleBase = minimal ? styles.button : styles.buttonPrimary
    if (selected) {
        styleBase = {
            ...styleBase,
            ...styles.buttonSelected
        }
    }
    return (
        <TouchableHighlight
            underlayColor={Colors.dark.buttonUnderlay}
            disabled={disabled}
            onPress={onPress}
            style={{
                flexWrap: "wrap",
            }}
        >
            <Flex style={{
                ...styleBase,
                flexWrap: "wrap",
                ...(disabled && {
                    backgroundColor: 'none',//'rgba(1,2,3,0.6)',
                }),
                //backgroundColor: disabled ? 'rgba(1,2,3,0.6)' : styleBase.backgroundColor,
                ...style,
            }}>
                {children}
            </Flex>
        </TouchableHighlight>
    )
}

export default ButtonUi