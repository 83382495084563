export const randomId = function (length = 6) {
  return Math.random().toString(36).substring(2, length + 2);
};

export const rollDie = (sides: number) => {
  let crypto = window.crypto
  let arr = new Uint32Array(1)
  let r = crypto.getRandomValues(arr)[0]
  //let r = Math.floor(Math.random() * Math.pow(10, 10))
  let m = (r % sides) + 1
  //console.log(sides,m)
  return m

}