import React, {useEffect, useState} from "react";
import {AddressShort} from "../utils/addressUtils"
import {View, Flex, Text} from "./Themed";
import useNameService from "../diamond-realm-api/hooks/useNameService";


const {
    u8aToHex
} = require('@polkadot/util');


interface props {
    address?: string
    abbr?:boolean
}

const AccountSenderListItem = ({address,abbr}: props) => {
    const {nick} = useNameService({address})

    const initials = (n?:string) => {
        let t = ""
        if (n?.length) {
            let s = n.split(" ")

            for(let i=0;i<s.length;i++) {
                t += s[i][0]
                if (i >= 2) break
            }
        }
        return t
    }

    if (!address) {
        return null
    } else {
        if (abbr) {
            return (
                <View>
                    <Text>
                        <strong>{`${initials(nick)}`}</strong>
                    </Text>

                </View>
            )
        } else {
            return (
                <View>
                    <Text>
                        @<strong>{`${nick}`}</strong>
                    </Text>
                    <Text style={{fontWeight: "200", marginRight: 12}}>
                        {` ${AddressShort(address)}`}

                    </Text>
                </View>
            )
        }
    }
}

export default AccountSenderListItem