import {Button, ImageBackground, ScrollView, TextInput, TouchableHighlight} from 'react-native';

import {Flex, FormLabel, Text, View} from '../Themed';
import React, {useEffect, useRef, useState} from "react";
import {useForm} from "../../hooks/useForm";
import {CONSTANTS, styles} from "../../constants/Styles";
import ButtonText from '../ButtonText';

import {useBlockchain} from "../../diamond-realm-api/hooks/useBlockchain";
import useWallet from "../../diamond-realm-api/hooks/useWallet";
import Loading from "../Loading";
import {RegisterName,} from "../../diamond-realm-api/nameService";
import useNameService from "../../diamond-realm-api/hooks/useNameService";
import {ToastError} from "../Toast";

interface props {
    onFinish?: () => any
}

const RegisterNameView = (
    {
        onFinish,
    }: props
) => {

    //const {nameInfo} = useNameService({id: id})
    const initForm = {
        name: "",
        www: "",
    }
    const form = useForm(initForm)
    const blockchain = useBlockchain()
    const wallet = useWallet()


    const input_ref = useRef<TextInput | null>(null);

    useEffect(() => {

    }, [])

    useEffect(() => {
        //  work()
    }, [blockchain.api, wallet.localWallet])

    // end of hooks // //////////////////////////////////////////////

    const handleSetName = async (e: any) => {
        e.preventDefault()

        if (blockchain.api && wallet.localWallet && wallet.address) {
            form.setSubmitting(true)
            try {
                let res = await RegisterName(
                    blockchain.api,
                    wallet.localWallet,
                    wallet.address,
                    form.formData.nick,
                    form.formData.www, // || null,
                    () => {
                    form.setSubmitting(false)
                    onFinish && onFinish()
                })
                console.log("registered name", res)
            } catch (err) {
                ToastError(err)
            }
        }
    }

    return (
        <View style={{}}>
            <Flex style={{}}>
                <Text style={{fontWeight: "800", fontSize: 16}}>{`@ `}</Text>
                <View style={{flex: 1,}}>
                    <TextInput
                        ref={input_ref}
                        style={styles.input}
                        placeholder="Name, brand... etc."
                        autoFocus={true}
                        value={form.formData.nick}
                        onChangeText={form.handleChange("nick")}
                        onSubmitEditing={handleSetName}
                    />
                </View>
            </Flex>
            {/*<FormLabel>Minimum 4 characters</FormLabel>*/}
            <Flex>
                <ButtonText disabled={form.submitting} onPress={handleSetName}>
                    {form.submitting ? <Loading/> : "Register Name"}
                </ButtonText>
            </Flex>
        </View>

    );
}

export default RegisterNameView