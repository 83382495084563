import { format } from 'date-fns'

export const blockTimeStampToDate = (timestamp: string | number) => {
    //return format(new Date(Number(timestamp) ), 'yyyy iii MMM dd HH:mm')
    return format(new Date(Number(timestamp) ), 'MMM dd HH:mm')
}

export const timeToHours = (time: Date) => {
    let hours = time.getUTCHours() + time.getUTCMinutes() / 60
    return hours
}