import React, {useEffect, useState} from "react";

import useNameService from "../diamond-realm-api/hooks/useNameService";
import {useBlockchain} from "../diamond-realm-api/hooks/useBlockchain";
import useWallet from "../diamond-realm-api/hooks/useWallet";
import {Flex, View, Text} from "./Themed";
import {styles} from "../constants/Styles";
import {AddressShort} from "../utils/addressUtils";
import ClipboardButton from "./ClipboardButton";
import {diamondHash} from "../diamond-realm-api/crypto";
import {Header} from "@polkadot/types/interfaces/runtime/types";
import {Event} from "@polkadot/types/interfaces/system";
import EventListItem from "./EventListItem";

interface propsI {

}


const EventViewer = ({}: propsI) => {
    const blockchain = useBlockchain()

    const [chainEvents, setChainEvents] = useState<Event[]>([])

    useEffect(() => {
        setupEventSubscriptions()
    }, [blockchain.api])

    /// end of hooks
    const setupEventSubscriptions = async () => {

        if (!blockchain.isConnected || !blockchain?.api) return
        //subscribe to new events

        let unsub = await blockchain.api.query.system.events((events) => {
            console.log(`\nReceived ${events.length} events:`);

            // Loop through the Vec<EventRecord>
            let e: Event[] = []
            for (let i = 0; i < events.length; i++) {
                const record = events[i]
                // Extract the phase, event and the event types
                const {event, phase} = record;

                e = [
                    event,
                    ...e,
                ]
                const types = event.typeDef;

                // Show what we are busy with
                console.log(`\t${event.section}:${event.method}:: (phase=${phase.toString()})`);
                console.log(event);
                //console.log(`\t\t${event.meta.documentation.toString()}`);

                /*  // Loop through each of the parameters, displaying the type and data
                  event.data.forEach((data, index) => {
                      console.log(`\t\t\t${types[index].type}: ${data.toString()}`);
                  });*/
            }


            setChainEvents(prev => [
                ...e,
                ...prev,
            ]);

        });


        return unsub
    }

    return (
        <View style={{
            ...styles.card,
            flex: 1,
            //alignItems:"flex-start",
        }}>
            <Flex style={{
                flex: 1,
                justifyContent: "space-between",
                alignItems: "center"
            }}>
                <View>
                    <Text style={styles.headerHeavy}>
                        Chain Events
                    </Text>
                </View>

                <View>

                </View>
            </Flex>
            {chainEvents && chainEvents[0] &&
                chainEvents.map((e, i) => (
                    <EventListItem key={"event" + i} event={e}/>
                ))}
        </View>
    )
}
export default EventViewer