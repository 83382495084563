import React, {useEffect, useState} from "react";
import {useBlockchain} from "../../diamond-realm-api/hooks/useBlockchain";
import useWallet from "../../diamond-realm-api/hooks/useWallet";
import {View, Text, Flex} from "../Themed";
import {styles} from "../../constants/Styles";
import Loading from "../Loading";
import {GetMessage, GetSentMessage} from "../../diamond-realm-api/messages";
import {Message, MessageHeader} from "../../diamond-realm-api/interfaces";
import {AddressShort} from "../../utils/addressUtils";
import ClipboardButton from "../ClipboardButton";
import {blockTimeStampToDate} from "../../utils/TimeDate";
import ButtonText from "../ButtonText";
import {NativeStackNavigationProp, NativeStackScreenProps} from "@react-navigation/native-stack";

import {NavigationProp, useNavigation} from "@react-navigation/native";
import {MessagesStackParamList, RootTabParamList, WalletStackParamList} from "../../types";
import {FontAwesomeIcon} from "@fortawesome/react-native-fontawesome";
import {
    faReply,
} from "@fortawesome/free-solid-svg-icons";
import ButtonUi from "../ButtonUi";
import Colors from "../../constants/Colors";
import useNameService from "../../diamond-realm-api/hooks/useNameService";

interface propsI {
    messageHeader: MessageHeader
}

const MessageView = ({messageHeader}: propsI) => {
    const blockchain = useBlockchain()
    const wallet = useWallet()
    const {nick} = useNameService({address: messageHeader.sender})
    const {nick: recipientNick} = useNameService({address: messageHeader.recipient})
    const navigation = useNavigation<NavigationProp<MessagesStackParamList>>()

    const [message, setMessage] = useState<Message>()
    useEffect(() => {
        handleGetMessage()
    }, [messageHeader.id, blockchain.api])

    /// end of hooks
    const handleGetMessage = async () => {
        if (blockchain.api && wallet.localWallet && wallet.address && wallet.curvePair) {
            let r
            if (messageHeader.sender === wallet.address) {
                r = await GetSentMessage(
                    blockchain.api,
                    wallet.curvePair,
                    messageHeader
                )
            } else {
                r = await GetMessage(
                    blockchain.api,
                    wallet.curvePair,
                    messageHeader
                )
            }
            if (r) {
                setMessage(r)
            }
        }
    }

    if (!message) return <Loading/>

    const sent = messageHeader.sender === wallet.address
    return (
        <View style={{
            ...styles.card,
            marginHorizontal: 0,
            flex: 1,
            //width: "100%",
            flexWrap: "wrap",
        }}>
            <View>
                <Flex
                    style={{
                        justifyContent: "space-between",
                        flexWrap: "wrap",
                    }}
                >
                    <View style={{
                        //marginBottom: "4"
                    }}>
                        <Text>
                            From: {nick &&
                            <Text style={{...styles.item, marginRight: 12}}>
                                @{nick}
                            </Text>
                        }
                            {message.sender &&
                                <ClipboardButton copyText={message.sender} text={AddressShort(message.sender)}/>
                            }
                        </Text>


                        {messageHeader.date &&
                            <Text style={{}}>
                                {`Sent: ${blockTimeStampToDate(messageHeader.date)} `}
                            </Text>
                        }

                        <Text>
                            To: {recipientNick &&
                            <Text style={{...styles.item, marginRight: 12}}>
                                @{recipientNick}
                            </Text>
                        }
                            {messageHeader.recipient &&
                                <ClipboardButton copyText={messageHeader.recipient} text={AddressShort(messageHeader.recipient)}/>
                            }
                        </Text>
                    </View>
                    <View>
                        <ButtonUi minimal={true}
                                  onPress={() => {
                                      navigation.setParams({
                                          to: message?.sender,
                                          subject: `Re: ${message?.subject}`,
                                          quote: `\n\n---- \nSender: ${message?.sender && `@${nick} ${AddressShort(message.sender)}`} \n${message?.message}`
                                      });
                                      //navigation.navigate('Messages', {Messages: {to: message?.sender}})
                                  }
                                  }
                        >
                            <FontAwesomeIcon color={Colors.dark.text} icon={faReply}/>
                        </ButtonUi>
                    </View>
                </Flex>
                <Flex style={{
                    alignItems: "baseline"
                }}>
                    <Text>Subject:</Text>
                    <Text style={styles.headerHeavy}>
                        {` ${message?.subject} `}
                    </Text>
                </Flex>
                <View style={{
                    flex: 1,
                    paddingBottom: 24,
                    marginTop: 12,
                }}>
                    <Text>
                        {`${message?.message}`}
                    </Text>
                </View>

            </View>

        </View>
    )
}
export default MessageView