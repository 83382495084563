import React, {useEffect, useState} from "react";

import {FileI, FolderHeaderI} from "../../diamond-realm-api/interfaces";
import {GetEncryptedFile} from "../../diamond-realm-api/storage";
import {useBlockchain} from "../../diamond-realm-api/hooks/useBlockchain";
import useWallet from "../../diamond-realm-api/hooks/useWallet";
import {Flex, View, Text} from "../Themed";
import Colors from "../../constants/Colors";
import {Link} from "@react-navigation/native";
import {faDownload, faHome} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-native-fontawesome";
import {styles} from "../../constants/Styles";
import Loading from "../Loading";
import {Image} from "react-native";

interface propsI {
    folderHeader: FolderHeaderI
    fileName: string
}


const FileView = ({folderHeader, fileName}: propsI) => {
    const blockchain = useBlockchain()
    const wallet = useWallet()
    const [w, setW] = useState(0)
    const [h, setH] = useState(0)
    const [file, setFile] = useState<FileI>()

    useEffect(() => {
        handleGetFile()
    }, [folderHeader, fileName])


    /*

    useEffect(() => {
        let {w, h} = ImageDimensions(file?.uri)
        let screen = 420.0
        let scale = screen / w
        setW(w * scale)
        setH(h * scale)
    }, [file])
     */
    useEffect(() => {

        if (file?.uri) {
            let memoryImg = document.createElement('img');
            memoryImg.src = file.uri;
            let screen = 420.0
            let width = memoryImg.width
            let scale = screen / width
            let height = memoryImg.height
            setW(width * scale)
            setH(height * scale)
        }
    }, [file])
    /// end of hooks
    const handleGetFile = async () => {
        if (blockchain.api && wallet.localWallet && wallet.address && wallet.curvePair) {
            let r = await GetEncryptedFile({
                fileName,
                folderHeader,
                api: blockchain.api,
                curvePair: wallet.curvePair,
            })
            if (r) {
                setFile(r)
            }
        }
    }

    if (!file) return <Loading/>
    console.log(file)
    return (
        <View style={{
            //alignItems: "center",
            //width: "100%",
            //justifyContent: "space-between",
            padding: "2%",
            flexWrap: "wrap",
        }}>
            <Text style={styles.headerHeavy}>
                {file?.name}
            </Text>
            {file?.uri &&
                <Text style={{
                    ...styles.header,
                    margin: 6,
                }}>
                    <a href={file.uri} download={file.name} style={styles.item}>
                        <FontAwesomeIcon color={Colors.dark.text} icon={faDownload}/> Download
                    </a>
                </Text>
            }
            {file?.uri &&
                <View>
                    {file.uri?.startsWith("data:image") ? (
                        <Image source={
                            {
                                width: w,
                                height: h,
                                uri: file.uri
                            }
                        }/>
                    ) : (
                        <Text numberOfLines={10}
                              style={{margin: 6, flexShrink: 1, flexWrap: 'wrap', fontWeight: "900"}}>
                            {file?.uri}
                        </Text>
                    )}
                </View>
            }

        </View>
    )
}
export default FileView
