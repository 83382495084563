import {ImageBackground, ScrollView, TextInput, TouchableHighlight} from 'react-native';
import {Flex, FormLabel, Text, View} from '../components/Themed';
import React, {useEffect, useRef, useState} from "react";
import {CONSTANTS, styles} from "../constants/Styles";
import useWallet from "../diamond-realm-api/hooks/useWallet";

import {
    asHexAddress,
    ConvertEdPublic2CurvePublic,
    ConvertEdSecret2CurvePair,
    encrypt,
    toHexString
} from '../diamond-realm-api/crypto';

import {useForm} from "../hooks/useForm";
import {useBlockchain} from "../diamond-realm-api/hooks/useBlockchain";
import Loading from "../components/Loading";
import {Contact, ContactHeader, FileHeaderI, FileI, FolderHeaderI} from "../diamond-realm-api/interfaces";
import useInterval from "../hooks/useInterval";
import {FontAwesomeIcon} from "@fortawesome/react-native-fontawesome";
import Colors from "../constants/Colors";
import {faContactBook, faFolder, faContactCard, faHome, faAddressCard} from "@fortawesome/free-solid-svg-icons";
import ButtonUi from "../components/ButtonUi";
import AccountNickText from "../components/AccountNickText";
import DirectoryView from "../components/DirectoryView";
import {GetAllNameIds, GetAllOwnedNameIds, NameInfo} from "../diamond-realm-api/nameService";
import NameText from "../components/NameText";
import NameView from "../components/Names/NameView";
import EditNameInfo from "../components/Names/EditNameInfo";
import TransferToken from "../components/tokens/TransferToken";


export default function OwnedNames() {
    const blockchain = useBlockchain()
    const wallet = useWallet();
    const input_ref = useRef<TextInput | null>(null);
    const [folders, setFolders] = useState<FolderHeaderI[]>([])
    const [names, setNames] = useState<NameInfo[]>([])
    const [viewEntry, setViewEntry] = useState("")


    useEffect(() => {
        refresh()
    }, [blockchain.api])


    useInterval(() => {
        refresh()
    }, 8000)

    // end of hooks

    const refresh = async () => {
        handleGetAllNameIds();
    }

    const handleGetAllNameIds = async () => {
        if (blockchain.api && wallet.address) {
            let data = await GetAllOwnedNameIds(blockchain.api, wallet.address)
            data && setNames(data)
        }
    }

    return (
        <View style={styles.container}>
            <ScrollView
                keyboardShouldPersistTaps={'handled'}
                style={{
                    //alignItems: "center",
                    // backgroundColor: Colors.dark.backgroundAlpha,
                    width: "100%",
                    //padding: 12,
                    // paddingBottom: 100,
                    //maxWidth: CONSTANTS.pageWidth,
                }}>
                <View style={{
                    // paddingBottom: 100,
                }}>

                    <View style={{
                        //maxWidth: 420,
                    }}>
                        {names.map((name, i) => (
                            <View key={"dir" + i}>
                                <Flex>
                                    <ButtonUi selected={viewEntry === name.id} minimal={true} disabled={false} onPress={() => {
                                        if (viewEntry === name.id) {
                                            setViewEntry("")
                                        } else {
                                            setViewEntry(name.id)
                                        }

                                    }}>
                                        <Flex style={{alignItems: "center", flexWrap: "wrap"}}>
                                            {/*   <FontAwesomeIcon color={Colors.dark.text} icon={faAddressCard}
                                                             style={{marginRight: 6}}/>*/}
                                            {name && <NameText id={name.id}/>}
                                        </Flex>
                                    </ButtonUi>
                                </Flex>

                                {(viewEntry === name.id) &&
                                    <View style={{
                                        flex: 1,
                                    }}>
                                        <NameView id={name.id}/>
                                    </View>
                                }


                            </View>
                        ))}
                    </View>


                </View>

            </ScrollView>
        </View>

    );
}

