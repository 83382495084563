import {ImageBackground, ScrollView, TextInput, TouchableHighlight} from 'react-native';
import {getDocumentAsync} from "expo-document-picker"

//import { DocumentPicker, ImagePicker } from 'expo';
import {Flex, FormLabel, Text, View} from '../components/Themed';


import React, {useEffect, useRef, useState} from "react";

import {CONSTANTS, styles} from "../constants/Styles";
import ButtonText from '../components/ButtonText';
import useWallet from "../diamond-realm-api/hooks/useWallet";
import {blake2AsHex, mnemonicToMiniSecret} from "@polkadot/util-crypto";
import {
    ConvertEdPublic2CurvePublic,
    ConvertEdSecret2CurvePair,
    encrypt,
    toHexString
} from '../diamond-realm-api/crypto';
import {box, sign} from "tweetnacl";
import * as ed2curve from 'ed2curve';
import {Keypair} from "@polkadot/util-crypto/types";
import {
    CreateContact,
    GetContact, GetContacts,
} from "../diamond-realm-api/contacts";
import {useForm} from "../hooks/useForm";
import {useBlockchain} from "../diamond-realm-api/hooks/useBlockchain";
import Loading from "../components/Loading";
import {Contact, ContactHeader, FileHeaderI, FileI, FolderHeaderI} from "../diamond-realm-api/interfaces";
import useInterval from "../hooks/useInterval";
import {FontAwesomeIcon} from "@fortawesome/react-native-fontawesome";
import Colors from "../constants/Colors";
import {faContactBook,faPlus, faFolder, faContactCard, faHome, faAddressCard} from "@fortawesome/free-solid-svg-icons";
import FileView from "../components/Storage/FileView";
import {AddressShort} from "../utils/addressUtils";
import ContactView from "../components/Contacts/ContactView";
import ButtonUi from "../components/ButtonUi";
import useNameService from "../diamond-realm-api/hooks/useNameService";
import ContactListItem from "../components/Contacts/ContactListItem";

export default function RolodexScreen() {

    const blockchain = useBlockchain()
    const wallet = useWallet()
    const input_ref = useRef<TextInput | null>(null);
    const [folders, setFolders] = useState<FolderHeaderI[]>([])
    const [contacts, setContacts] = useState<ContactHeader[]>([])

    const [showNewContact, setShowNewContact] = useState(false)

    const initForm = {
        firstName: "",
        lastName: "",
        diamondAddress: "",
        email: "",
        phone: "",
        websiteUrl: "",
        avatarUr: "",
        company: "",
        folderHeader: {
            parentId: "",
            name: "/"
        }
    }
    const form = useForm(initForm)

    useEffect(() => {
        refresh()
    }, [blockchain.api])

    useEffect(() => {
        refresh()
    }, [form.formData.folderHeader])

    useInterval(() => {
        refresh()
    }, 8000)

    const {nick} = useNameService({address: form.formData.diamondAddress})

    useEffect(() => {
        if (nick && !form.formData.firstName) {
            form.setFormData({
                ...form.formData,
                firstName: nick
            })
        }
    }, [nick])
    // end of hooks

    const refresh = async () => {
        handleGetContacts()
        handleAllNicks();
    }


    const handleCreateContact = async () => {
        form.setSubmitting(true)
        console.log("create contact", form.formData)
        if (blockchain.api && wallet.localWallet && wallet.address && wallet.curvePair) {
            const f = form.formData
            let data: Contact = {
                firstName: f.firstName,
                lastName: f.lastName,
                email: f.email,
                phone: f.phone,
                websiteUrl: f.websiteUrl,
                avatarUrl: f.avatarUrl,
                company: f.company,
                diamondAddress: f.diamondAddress,
            }
            let contactName = data.firstName + " " + data.lastName
            try {
                let res = await CreateContact(
                    blockchain.api,
                    wallet.localWallet,
                    wallet.curvePair,
                    form.formData.folderHeader,
                    contactName,
                    data,
                    () => {
                        form.setFormData(initForm)
                        form.setSubmitting(false)
                        setShowNewContact(false)
                    }
                )

            } catch (err) {
                console.log(err)
                form.setSubmitting(false)
            }
        }
    }

    const handleAllNicks = async () => {

            if (!blockchain.api) return
        let res = await blockchain.api.query.nicks.nameOf.entries()
        console.log("nicks entries", res);

    }

    const handleGetContacts = async () => {
        if (blockchain.api && wallet.localWallet && wallet.address && wallet.curvePair) {
            let r = await GetContacts(
                blockchain.api,
                wallet.curvePair,
                form.formData.folderHeader,
            )
            if (r) {
                setContacts(r)
            }
        }
    }


    // todo folder on contacts
    /*   const handleGetFolders = async () => {
           if (blockchain.api && wallet.localWallet && wallet.address && wallet.curvePair) {
               let r = await GetFolders(
                   blockchain.api,
                   wallet.curvePair,
                   form.formData.folderHeader,
               )
               if (r) {
                   setFolders(r)
               }
           }
       }
   */

    const handleGetContact = async () => {
        if (blockchain.api && wallet.localWallet && wallet.address && wallet.curvePair) {
            let r = await GetContact(
                blockchain.api,
                wallet.curvePair,
                form.formData.folderHeader,
                // todo
                form.formData.contactName,
            )
        }
    }


    return (
        <View style={styles.container}>
            <ScrollView
                keyboardShouldPersistTaps={'handled'}
                style={{
                    //alignItems: "center",
                    // backgroundColor: Colors.dark.backgroundAlpha,
                    width: "100%",
                    padding: 12,
                    paddingBottom: 100,
                    maxWidth: CONSTANTS.pageWidth,
                }}>
                <View style={{
                    // paddingBottom: 100,
                }}>
                    <Flex style={{
                        //flex: 1,
                        justifyContent: "space-between",

                    }}>


                        <Flex style={{
                            flex: 1,
                            //justifyContent: "flex-end",
                        }}>
                            <ButtonText selected={showNewContact} minimal={true} style={{marginRight: 6}} disabled={false} onPress={() => {
                                setShowNewContact(!showNewContact)
                            }}>
                                {/*<Text style={{marginRight: 12}}>New Contact</Text>*/}
                                <FontAwesomeIcon color={Colors.dark.text} icon={faPlus}/>
                            </ButtonText>
                        </Flex>
                    </Flex>
                    {showNewContact && (
                        <>
                            <Text style={styles.header}>
                                Add Contact
                            </Text>

                            <View
                                style={{
                                    minWidth: 60,
                                }}
                            >
                                <TextInput
                                    style={styles.input}
                                    placeholder="First Name"
                                    autoFocus={false}
                                    value={form.formData.firstName}
                                    onChangeText={form.handleChange("firstName")}
                                />

                                <TextInput
                                    style={styles.input}
                                    placeholder="Last Name"
                                    autoFocus={false}
                                    value={form.formData.lastName}
                                    onChangeText={form.handleChange("lastName")}
                                />

                                <TextInput
                                    style={styles.input}
                                    placeholder="Email"
                                    autoFocus={false}
                                    value={form.formData.email}
                                    onChangeText={form.handleChange("email")}
                                />

                                <TextInput
                                    style={styles.input}
                                    placeholder="Diamond Realm Address"
                                    autoFocus={false}
                                    value={form.formData.diamondAddress}
                                    onChangeText={form.handleChange("diamondAddress")}
                                />

                                <TextInput
                                    style={styles.input}
                                    placeholder="Phone"
                                    autoFocus={false}
                                    value={form.formData.phone}
                                    onChangeText={form.handleChange("phone")}
                                />

                                <TextInput
                                    style={styles.input}
                                    placeholder="Website URL"
                                    autoFocus={false}
                                    value={form.formData.websiteUrl}
                                    onChangeText={form.handleChange("websiteUrl")}
                                />

                                <TextInput
                                    style={styles.input}
                                    placeholder="Avatar URL"
                                    autoFocus={false}
                                    value={form.formData.avatarUr}
                                    onChangeText={form.handleChange("avatarUr")}
                                />

                                <TextInput
                                    style={styles.input}
                                    placeholder="Company"
                                    autoFocus={false}
                                    value={form.formData.company}
                                    onChangeText={form.handleChange("company")}
                                />

                                <View>
                                    <ButtonText selected={form.submitting} disabled={form.submitting || !form.formData.firstName} onPress={() => {
                                        handleCreateContact()
                                    }}>
                                        {form.submitting ? <Loading/> : "Save Contact"}
                                    </ButtonText>
                                </View>
                            </View>
                        </>
                    )}


                    <View style={{
                        //maxWidth: 420,
                    }}>
                        {contacts && contacts[0] && contacts.map((contact, i) => (
                            <View key={"contact" + i}>
                                <Flex>
                                    <ButtonUi minimal={true} disabled={false} onPress={() => {
                                        if (form.formData.viewContactName === contact.name) {
                                            let fd = {...form.formData}
                                            delete fd.viewContactName
                                            form.setFormData(fd)
                                        } else {
                                            form.setFormData({
                                                ...form.formData,
                                                viewContactName: contact.name
                                            })
                                        }

                                    }}>
                                        <ContactListItem folderHeader={form.formData.folderHeader}
                                                         contactName={contact.name}/>
                                    </ButtonUi>
                                </Flex>

                                {(form.formData.viewContactName === contact.name) &&
                                    <View style={{
                                        flex: 1,
                                    }}>

                                        <ContactView folderHeader={form.formData.folderHeader}
                                                     contactName={form.formData.viewContactName}/>
                                    </View>
                                }
                            </View>
                        ))}
                    </View>


                </View>

            </ScrollView>
        </View>

    );
}

