import React, {useEffect, useState} from "react";
import useNameService from "../diamond-realm-api/hooks/useNameService";
import {useBlockchain} from "../diamond-realm-api/hooks/useBlockchain";
import useWallet from "../diamond-realm-api/hooks/useWallet";
import {Flex, View, Text} from "./Themed";
import {styles} from "../constants/Styles";
import {AddressShort} from "../utils/addressUtils";
import ClipboardButton from "./ClipboardButton";
import {diamondHash} from "../diamond-realm-api/crypto";

interface propsI {
    address: string
}


const DirectoryView = ({address}: propsI) => {
    const blockchain = useBlockchain()
    const wallet = useWallet()

    const {nick} = useNameService({address})
    const {nameInfo} = useNameService({id: diamondHash(nick)})

    useEffect(() => {

    }, [])

    /// end of hooks


    return (
        <View style={{
            ...styles.card,
            flexWrap: "wrap",
        }}>

            <View>
                <Flex style={{
                    flex: 1,
                    justifyContent: "space-between",
                    alignItems: "center"
                }}>
                    <View>
                        <Text style={styles.headerHeavy}>
                            {`@${nick}`}
                        </Text>
                    </View>

                    <View>

                    </View>
                </Flex>
                {nameInfo?.www&&
                <View>
                    <Text style={styles.subTitle}>
                    </Text>
                    <Text style={styles.subTitle}>
                        <ClipboardButton //copyText={address}
                                         text={nameInfo.www}
                        />
                    </Text>
                </View>
                }

                <Flex>
                    <Text style={styles.item}>
                        {`Address: `}
                    </Text>
                    <ClipboardButton copyText={address}
                                     text={AddressShort(address)}
                    />
                </Flex>
            </View>

        </View>
    )
}
export default DirectoryView