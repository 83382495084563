import {Flex, View} from "./Themed";
import ContactsPicker from "./Contacts/ContactsPicker";
import {TextInput} from "react-native";
import {styles} from "../constants/Styles";
import ButtonUi from "./ButtonUi";
import {FontAwesomeIcon} from "@fortawesome/react-native-fontawesome";
import {faAddressBook} from "@fortawesome/free-solid-svg-icons";
import Colors from "../constants/Colors";
import React, {useState} from "react";
import AccountNickText from "./AccountNickText";

interface propsI {
    placeholder?: string,
    form: any,
    contactsPickerSetValue: (adr: string) => void
}

const AddressContactsInput = (props: propsI) => {
    const [showContacts, setShowContacts] = useState(false);
    return (
        <View>
        <Flex>
            {showContacts ? (
                <View style={{flex: 1}}>
                    <ContactsPicker setValue={(addr: string) => {
                        props.contactsPickerSetValue(addr)
                        setShowContacts(false)
                    }}/>
                </View>
            ) : (
                <View style={{flex: 1}}>
                    <TextInput
                        style={styles.input}
                        placeholder={props.placeholder}
                        autoFocus={false}
                        value={props.form.formData.diamondAddress}
                        onChangeText={props.form.handleChange("diamondAddress")}
                    />
                </View>
            )}

            <ButtonUi selected={showContacts} minimal={true}
                      onPress={() => {
                          setShowContacts(!showContacts)
                      }}>
                <FontAwesomeIcon icon={faAddressBook}
                                 color={ Colors.dark.text}/>
            </ButtonUi>
        </Flex>
            {props.form.formData.diamondAddress &&
                <View style={{marginVertical: "2%"}}>
                    <AccountNickText address={props.form.formData.diamondAddress}/>
                </View>
            }
        </View>
    )
}

export default AddressContactsInput