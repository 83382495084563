import {Button, ImageBackground, ScrollView, TextInput, TouchableHighlight} from 'react-native';
import {Flex, FormLabel, Text, View} from '../components/Themed';
import React, {useEffect, useRef, useState} from "react";
import {useForm} from "../hooks/useForm";
import {CONSTANTS, styles} from "../constants/Styles";
import Colors from "../constants/Colors";
import {useBlockchain} from "../diamond-realm-api/hooks/useBlockchain";
import useWallet from "../diamond-realm-api/hooks/useWallet";
import YourKeychain from "./keys/YourKeychain";
import ButtonText from "../components/ButtonText";
import {createNativeStackNavigator, NativeStackScreenProps} from "@react-navigation/native-stack";
import {RootStackParamList, RootTabParamList, AccountStackParamList} from "../types";
import LoadKey from "./keys/LoadKey";
import GenerateKey from "./keys/GenerateKey";
import AccountMenu from "../components/AccountMenu";
import ClipboardButton from "../components/ClipboardButton";
import {DiamondKeyring} from "../diamond-realm-api/crypto";
import Loading from "../components/Loading";
import {FontAwesomeIcon} from "@fortawesome/react-native-fontawesome";
import {faPenToSquare} from "@fortawesome/free-solid-svg-icons";
import {RegisterName} from "../diamond-realm-api/nameService";
import {FaucetDrip} from "../diamond-realm-api/diamond-realm";
import RegisterNameView from "../components/Names/RegisterNameView";
import RegisterAccount from "../components/tokens/RegisterAccount";
import BlockViewer from "../components/BlockViewer";
import EventViewer from "../components/EventViewer";
import {FormatDecimalAmount} from "../utils/NumbersUtils";
import Toast from "react-native-toast-message";

const SettingsStack = createNativeStackNavigator<AccountStackParamList>();

export default function SettingsStackScreen() {
    return (
        <SettingsStack.Navigator
            initialRouteName={"Settings"}
            screenOptions={{
                headerStyle: styles.headerStyle,
                headerRight: () => (
                    <View style={{margin: 12}}>
                        <AccountMenu/>
                    </View>
                ),
            }}>
            <SettingsStack.Screen name="Settings" component={SettingsScreen}/>
            <SettingsStack.Screen name="YourKey" component={YourKeychain} options={{
                title: 'Keychain',
            }}/>
            <SettingsStack.Screen name="LoadKey" component={LoadKey} options={{
                title: 'Load Key',
            }}/>
            <SettingsStack.Screen name="GenerateKey" component={GenerateKey} options={{
                title: 'Generate Key Pair',
            }}/>
        </SettingsStack.Navigator>
    );
}

type Props = NativeStackScreenProps<AccountStackParamList>;

function SettingsScreen({navigation}: Props) {
    const blockchain = useBlockchain()
    const wallet = useWallet()
    const [showEditName, setShowEditName] = useState(false)
    const initForm = {
        nick: ""
    }
    const form = useForm({
        ...initForm,
    })
    const input_ref = useRef<TextInput | null>(null);

    useEffect(() => {
        if (wallet.nick) {
            form.setFormData({
                ...form.formData,
                nick: wallet.nick
            })
        } else {
            form.resetFormData(initForm)
        }
    }, [wallet.nick])

    // end of hooks

    const handleFaucetDrip = async () => {
        try {
            if (blockchain?.api && wallet?.address) {
                await FaucetDrip(blockchain.api, wallet.address, () => {

                })
            }
        } catch (err) {
            Toast.show({
                type: 'tomatoToast',
                text1: `Error`,
                text2: `${err}`,
                visibilityTime: 16000,
            });
        }
    }


    const handleConnectBlockchain = async () => {
        window.postMessage({
            to: "DIAMOND_REALM_EXTENSION",
            action: "CONNECT_BLOCKCHAIN"
        })
    }

// if (isLoading) return <span>Loading ...</span>;
    return (
        <View style={styles.container}>
            <ScrollView
                keyboardShouldPersistTaps={'handled'}
                style={{
                    paddingTop: 12,
                    paddingHorizontal: 12,
                    paddingBottom: 100,
                    width: "100%",
                }}>
                <View style={{
                    alignItems: "center",
                    flex: 1,
                }}>
                    <View style={{
                        maxWidth: CONSTANTS.pageWidth,
                        flex: 1,
                        width: "100%",
                    }}>
                        <View style={{paddingVertical: 12}}>
                            <Flex>
                                <Text style={styles.header}>Name:</Text>
                                <ButtonText selected={showEditName} minimal={true} disabled={false} onPress={e => {
                                    e.preventDefault()
                                    setShowEditName(!showEditName)
                                }}>
                                    <FontAwesomeIcon color={Colors.dark.text} icon={faPenToSquare}/>
                                </ButtonText>
                            </Flex>

                            {showEditName ? (
                                <RegisterNameView onFinish={() => setShowEditName(false)}/>
                            ) : (
                                <Text style={styles.titleHeavy}>
                                    {wallet.nick || ""}
                                </Text>
                            )}


                        </View>


                        <Text style={styles.header}>Account Public Address: </Text>
                        <View style={{
                            ...styles.listItem,
                            padding: 12,
                            marginBottom: 24,
                        }}>
                            <ClipboardButton text={wallet.address}/>
                        </View>
                        <ButtonText disabled={false} onPress={() => navigation.navigate('YourKey')}>Your
                            Keychain
                        </ButtonText>

                        <ButtonText disabled={false} onPress={() => navigation.navigate('LoadKey')}>Load a new
                            Keychain
                        </ButtonText>

                        <ButtonText disabled={false} onPress={() => navigation.navigate('GenerateKey')}>Generate a new
                            Key
                        </ButtonText>


                        <ButtonText disabled={false} onPress={() => handleFaucetDrip()}>
                            {`${wallet.balance && FormatDecimalAmount(wallet.balance.toString(), 12, 4)} testnet Gas. Get more from the testnet Faucet`}
                        </ButtonText>

                        <RegisterAccount/>

                        <ButtonText disabled={false}
                                    onPress={() => {
                                        handleConnectBlockchain()
                                    }}>
                            Connect Diamond Realm via the Browser Extension
                        </ButtonText>


                        <Flex style={{
                            flex: 1,
                            justifyContent: "space-between",
                            alignItems: "flex-start",
                        }}>

                            <BlockViewer/>
                            <EventViewer/>
                        </Flex>

                    </View>
                </View>
            </ScrollView>

        </View>

    );
}

