import {useEffect, useState} from "react";

import {AddressShort} from "../../utils/addressUtils";
import {useBlockchain} from "./useBlockchain";
import {NameServiceNameInfo} from "@polkadot/types/lookup";
import {NameInfo, NameServiceNameInfoToNameInfo} from "../nameService";

interface props {
    address?: string,
    id?: string
}

const useNameService = ({
                            address,
                            id
                        }: props) => {
    const blockchain = useBlockchain()
    const [nick, setNick] = useState("")
    const [nameInfo, setNameInfo] = useState<NameInfo>()
    useEffect(() => {
        subscribeNameOf()
    }, [blockchain, address])

    useEffect(() => {
        subscribeId()
    }, [id])

    useEffect(() => {
        //example_tfhe_wasm()
        handleMessagesFromExtension()
    }, [])

    const handleMessagesFromExtension = async () => {
        console.log("in browser name service setup listener for messages from the extension")
        document.addEventListener('FromExtensionToPage', (event) => {
            console.log('Event Data received from extension:', event);
        });

        window.addEventListener("message", function (event) {
            if (event.data?.origin?.startsWith('substrate-connect')) {
                return
            }
            if (event.data.origin === "DIAMOND_REALM_EXTENSION") {
                console.log("Received message:", event);
            }
            if (event.data && event.data.action === "TRANSACTION_SIGNED") {
                console.log("Transaction signed event received:", event.data.transactionId);
            }

            /*
            console.log("in browser all events", event)
            if (event.data.type === "TRANSACTION_SIGNED") {
                console.log("in browser found transaction signed", event)
                const transactionId = event.data.transactionId;
                // todo
                // Set up the WebSocket subscription using the transaction ID
                // subscribeToWebSocket(transactionId);
            }*/
        });

    }

    /* runtime.onMessage.addListener((request, sender, sendResponse) => {
         if (request.action === "NAME_SUBSCRIBED") {
             console.log("Name subscribed Got response in useNameService", request)
             setNick(request.nick);
         }
     });*/

    const subscribeNameOf = async () => {
        if (blockchain.api && address) {

            /*  const unsub = await blockchain.api.query.nicks.nameOf(address, (res) => {
                  let _name = res.toHuman() as any[]
                  //console.log(res, _name)
                  _name && setName(_name[0])
              });*/

            const unsub = await blockchain.api.query.nameService.nameOf(address, (res) => {
                //  let res = res
                let _nick = res.toHuman() as string
                if (_nick) {
                    setNick(_nick)
                } else {
                    setNick("")
                }
            });
            return unsub
        }
    }

    const subscribeId = async () => {
        if (blockchain.api && id) {
            const unsub = await blockchain.api.query.nameService.names(id, (res) => {
                let data = res.unwrap()
                if (data) {
                    setNameInfo(NameServiceNameInfoToNameInfo(data))
                }
            })
            return unsub
        }
    }

    return {
        nick,
        nameInfo,
    }
}

export default useNameService