import create from 'zustand'
import {persist} from "zustand/middleware"
import {useEffect, useMemo, useState} from "react";
import {fromHexString, hexStringToUint8Array, toHexString} from "../crypto";
import {useBlockchain} from "./useBlockchain";
import {omit} from "lodash-es";
import BN from "bn.js";
import useWallet from './useWallet';
import {
    TokensTokenAccount,
    TokensTokenDetails
} from "@polkadot/types/lookup";
import {AssetI} from '../interfaces';
import {DecimalAmount} from "../../utils/NumbersUtils";
import {AccountId32, H256} from "@polkadot/types/interfaces/runtime";
import {u128} from "@polkadot/types-codec";
import {GetTokenPublicAddress} from "../tokens";

const {
    blake2AsHex,
    blake2AsU8a,
    keccakAsHex,
    mnemonicGenerate,
    mnemonicToMiniSecret,
    mnemonicValidate,
    ed25519PairFromSeed,
} = require('@polkadot/util-crypto');


type TokensStore = {}

type TokensEphemeralStore = {
    tokenAddress?: string,
    setTokenAddress: (n: string) => void
    metadata: { [key: string]: AssetI },
    setMetadata: (id: string, m: AssetI) => void
    accounts: { [key: string]: TokensTokenAccount },
    setAccounts: (id: string, n: TokensTokenAccount) => void
    details: { [key: string]: TokensTokenDetails },
    setDetail: (id: string, n: TokensTokenDetails) => void
}

const useTokensEphemeralStore = create<TokensEphemeralStore>((set, get) => ({
    setTokenAddress: (a) => {
        set((state) => ({tokenAddress: a}))
    },
    accounts: {},
    metadata: {},
    details: {},
    setMetadata: (id, m) => {
        let metadata = get().metadata || {}
        metadata[id] = m
        set((state) => ({
            metadata,
        }))
    },
    setAccounts: (id, b) => {
        let accounts = get().accounts || {}
        accounts[id] = b
        set((state) => ({
            accounts,
        }))
    },
    setDetail: (id, b) => {
        let details = get().details || {}
        details[id] = b
        set((state) => ({
            details,
        }))
    },
}))

const useTokensStore = create(persist<TokensStore>((set, get) => ({
        /*    setEncryptedLocalTokens: (s) => {
              set((state: TokensStore) => ({encryptedLocalTokens: s}))
            },
            setLocalTokens: (w) => {
              set((state: TokensStore) => ({localTokens: w}))
            },
            clearLocalTokens: () => set((state) => omit(state, ['localTokens']), true),
            */
    }), {
        name: "tokens",
    }
))

const useTokens = () => {
    const {} = useTokensStore()
    const {
        tokenAddress,
        setTokenAddress,
        accounts,
        setAccounts,
        metadata,
        setMetadata,
        details,
        setDetail,
    } = useTokensEphemeralStore()
    const blockchain = useBlockchain()
    const wallet = useWallet()

    const address = wallet.address

    useEffect(() => {
        //    test()
        //subscribeBalance().then((r) => r)
        subscribeAddress()
    }, [blockchain.api, address])

    /// end of hooks

    const subscribeAddress = async () => {
        if (blockchain.api && address) {
            try {
                let unsub = await blockchain.api.query.tokens.publicKeys(address, (res) => {
                    if (!res) return
                    let data = res.unwrap()
                    console.log("public key info", data);
                    let s = data.pubKey.toHuman() as string
                    console.log("return address from chain", s)
                    setTokenAddress(s)
                })
                return unsub
            } catch (err) {
                console.log(err)
            }
        }
    }

    const test = async () => {
        let id = "43"
        let u = await blockchain.api?.query.tokens.token(id, (res) => {
            console.log(res)
            let data = res?.unwrap()
            console.log("tokens", data)
        })
        return u
    }
    const subscribeToken = async (id: string) => {
        //todo check if already subscribed
        subscribeMeta(id)
        subscribeBalance(id)
        subscribeDetails(id)
    }

    const subscribeBalance = async (id: string) => {
        //  console.log("sub balance for", id)
        if (!blockchain.api || !address) return
        try {
            const unsub = await blockchain.api.query.tokens.account(id, address, (res) => {
                if (res.isNone) {
                    //     console.log("NONE")
                    // new BN(0),
                    //
                    return
                }
                let a = res.unwrap()
                setAccounts(id, a)
                //   console.log(`free balance is ${a.balance.toHuman()} `);
            });
            return unsub
        } catch (err) {
            console.log(err)
        }
    }


    const subscribeMeta = async (id: string) => {
        //  console.log("sub meta for", id)

        if (!blockchain.api || !address) return
        try {
            let arr = fromHexString(id)
            console.log("id", id, arr)
            const unsub = await blockchain.api.query.tokens.metadata(id, (res) => {
                let data = res.unwrap()
                console.log("Get new Token Metadata Result", data)
                let a: AssetI = {
                    name: data.name.toHuman() as any[][0],
                    symbol: data.symbol.toHuman() as any[][0],
                    decimals: data.decimals.toNumber(),
                    ...(data.image?.value.initialU8aLength && {image: data.image.value.toUtf8() }),
                }
                console.log("Get new Token Metadata", a)
                setMetadata(id, a)
                //   console.log(`Token metadata ${a.name}`);
            });
            return unsub
        } catch (err) {
            console.log(err)
        }
    }

    const subscribeDetails = async (id: string) => {
        if (!blockchain.api || !address) return
        try {

            const unsub = await blockchain.api.query.tokens.token(id, (res) => {
                const info = res.unwrap()
                console.log("token info", info)
                if (info) {
                    // todo fix
                    setDetail(id, info)
                    /*{
                         id: info.id,
                    owner: info.owner,
                     issuer: info.issuer,
                     admin: info.admin,
                    freezer: info.freezer,
                    supply: info.supply,
                    }*/
                }
            });
            return unsub
        } catch (err) {
            console.log(err)
        }
    }

    return {
        tokenAddress,
        accounts,
        metadata,
        details,
        subscribeToken,
    }
}

export default useTokens

