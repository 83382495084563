import React, {useEffect, useState} from "react";


import {Contact, FileI, FolderHeaderI} from "../../diamond-realm-api/interfaces";
import {GetEncryptedFile} from "../../diamond-realm-api/storage";
import {CreateContact, GetContact} from "../../diamond-realm-api/contacts";
import {useBlockchain} from "../../diamond-realm-api/hooks/useBlockchain";
import useWallet from "../../diamond-realm-api/hooks/useWallet";
import {Flex, View, Text} from "../Themed";
import Colors from "../../constants/Colors";
import {Link} from "@react-navigation/native";
import {faAddressCard, faEdit, faEnvelope, faHome} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-native-fontawesome";
import {styles} from "../../constants/Styles";
import {AddressShort} from "../../utils/addressUtils";
import Loading from "../Loading";
import ClipboardButton from "../ClipboardButton";
import ButtonUi from "../ButtonUi";

import {TextInput} from "react-native";
import ButtonText from "../ButtonText";
import {useForm} from "../../hooks/useForm";
import useNameService from "../../diamond-realm-api/hooks/useNameService";
import AccountNickText from "../AccountNickText";

interface propsI {
    folderHeader: FolderHeaderI
    contactName: string,
    setValue?: (addr: string) => void
}


const ContactListItem = ({folderHeader, contactName, setValue}: propsI) => {
    const blockchain = useBlockchain()
    const wallet = useWallet()

    const [contact, setContact] = useState<Contact>()
    const {nick} = useNameService({address: contact?.diamondAddress})


    useEffect(() => {
        handleGetContact()

    }, [folderHeader, contactName])

    /// end of hooks
    const handleGetContact = async () => {
        if (blockchain.api && wallet.localWallet && wallet.address && wallet.curvePair) {
            let r = await GetContact(
                blockchain.api,
                wallet.curvePair,
                folderHeader,
                contactName,
            )
            if (r) {
                setContact(r)
            }
        }
    }

    if (!contact) return <Loading/>

    return (
        <Flex style={{alignItems: "center", flexWrap: "wrap"}}>
        <View style={{
            //alignItems: "center",
            //width: "100%",
            //justifyContent: "space-between",
            //flex: 1,
            flexWrap: "wrap",
        }}>
            <Flex>
                <FontAwesomeIcon color={Colors.dark.text} icon={faAddressCard}
                                 style={{marginRight: 6}}/>
                <Flex style={{
                    flex: 1,
                    justifyContent: "space-between",
                    alignItems: "center"
                }}>
                    <Text style={styles.item}>
                        {`${contact?.firstName} ${contact?.lastName}`}
                    </Text>
                    <AccountNickText address={contact.diamondAddress}/>
                </Flex>
            </Flex>
        </View>
        </Flex>
    )
}
export default ContactListItem