///
/// Decentralized Storage
///

// todo maybe be more generic
import {BoxKeyPair} from "tweetnacl";
import {CypherMsgI, decode, decrypt, DiamondKeyring, encode, encrypt, newNonce, toHexString} from "./crypto";
import {blake2AsHex} from "@polkadot/util-crypto";
import {ApiPromise} from "@polkadot/api";
import {LocalWallet} from "./hooks/useWallet";
import {FileHeaderI, FileI, FolderHeaderI} from "./interfaces";

import {ParseResultEvents, ToastTx} from "../components/Toast";

export enum StorageTypes {
    STORAGE = "storage"
}

export const GenerateStorageId = (
    curvePair: BoxKeyPair,
    parentIdHash: string,
    appName: string,
    folder: string,
    file?: string,
    fileKey?: string, //keyname for file
) => {
    let hexSecret = toHexString(curvePair.secretKey)
    //const appName = `storage`
    let id = `${hexSecret}::${appName}::folder::${parentIdHash}::${folder}`
    if (file) {
        id += `::${fileKey}::${file}`
    }
    const hash = blake2AsHex(id)
    //console.log("id", id, "hash", hash)
    return hash
}


export const GenerateFileStorageId = (
    curvePair: BoxKeyPair,
    parentIdHash: string,
    folder: string,
    file?: string,
) => {
    let hexSecret = toHexString(curvePair.secretKey)
    const appName = `storage`
    let id = `${hexSecret}::${appName}::folder::${parentIdHash}::${folder}`
    if (file) {
        id += `::file::${file}`
    }

    const hash = blake2AsHex(id)
    //console.log("id", id, "hash", hash)
    //return hash

    return GenerateStorageId(curvePair, parentIdHash, appName, folder, file, "file")
}

export const GenerateStorageRootParentId = (
    curvePair: BoxKeyPair,
) => {
    const parentIdHash = GenerateFileStorageId(curvePair, "", "/")
    console.log(parentIdHash)
    return parentIdHash

}


export const GenerateContactStorageId = (
    curvePair: BoxKeyPair,
    parentIdHash: string,
    folder: string,
    contactName?: string,
) => {
    let hexSecret = toHexString(curvePair.secretKey)

    const appName = `contacts`
    let id = `${hexSecret}::${appName}::folder::${parentIdHash}::${folder}`
    if (contactName) {
        id += `::contact::${contactName}`
    }

    const hash = blake2AsHex(id)
    // console.log("contact_id", id, "hash", hash)
    return hash
}


export const GenerateContactsRootParentId = (
    curvePair: BoxKeyPair,
) => {
    const parentIdHash = GenerateContactStorageId(curvePair, "", "/")
    console.log(parentIdHash)
    return parentIdHash
}

/*
// todo  not used ? remove
interface CreateStorageI {
    //Dispatch<SetStateAction<boolean>>,
}
*/

/// 
/// Encrypted Files Storage
///


export const CreateEncryptedFile = async (
    api: ApiPromise,
    localWallet: LocalWallet,
    curvePair: BoxKeyPair,
    folderHeader: FolderHeaderI,
    fileName: string,
    json: FileI,
    fileSize?: number,
    callback?: () => any,
    //setSubmitting?: (t: boolean) => any,
) => {
    if (folderHeader.parentId === "") {
        folderHeader.parentId = GenerateStorageRootParentId(curvePair)
    }

    const folderIdHash = GenerateFileStorageId(curvePair, folderHeader.parentId, folderHeader.name)
    const fileIdHash = GenerateFileStorageId(curvePair, folderHeader.parentId, folderHeader.name, fileName)

    const nonce = newNonce();

    let encData = encrypt({
        secretOrSharedKey: curvePair.secretKey,
        json,
        key: curvePair.publicKey,
        senderPublicKey: curvePair.publicKey,
        nonce,
    })
    console.log("cypher message length", encData.cypherText.length)

    let header: FileHeaderI = {
        name: fileName,
        size: fileSize,
    }

    let encHeader = encrypt({
        secretOrSharedKey: curvePair.secretKey,
        json: header,
        key: curvePair.publicKey,
        senderPublicKey: curvePair.publicKey,
        nonce,
    })

    console.log("header", toHexString(encHeader.nonce), "data", toHexString(encData.nonce))
    let kp = DiamondKeyring.addFromUri(localWallet.seedPhrase)

    api.tx.decentralizedStorage.createEncryptedStorage(
        folderIdHash,
        fileIdHash,
        encHeader.cypherText,
        encData.cypherText,
        encData.nonce,
    ).signAndSend(kp, (res) => {
        console.log(res)
        ToastTx(res)
        ParseResultEvents(api, res)
        if (res.status.isInBlock) {
            callback && callback()
        }
    })

    return {
        folderIdHash,
        fileIdHash
    }

}


export const CreateEncryptedFolder = async (
    api: ApiPromise,
    localWallet: LocalWallet,
    curvePair: BoxKeyPair,
    folderHeader: FolderHeaderI,
    folderName: string,
    callback?: () => any,
    //setSubmitting?: (t: boolean) => any,//Dispatch<SetStateAction<boolean>>,
) => {
    if (folderHeader.parentId === "" || folderHeader.name === "/") {
        folderHeader.parentId = GenerateStorageRootParentId(curvePair)
    }
    const parentIdHash = GenerateFileStorageId(curvePair, folderHeader.parentId, folderHeader.name)
    const folderIdHash = GenerateFileStorageId(curvePair, parentIdHash, folderName)
    console.log(folderHeader, folderIdHash)

    const nonce = newNonce();

    const header: FolderHeaderI = {
        name: folderName,
        parentId: parentIdHash,
    }
    let encHeader = encrypt({
        secretOrSharedKey: curvePair.secretKey,
        json: header,
        key: curvePair.publicKey,
        senderPublicKey: curvePair.publicKey,
        nonce,
    })

    console.log("header", toHexString(encHeader.nonce), folderHeader, folderIdHash)
    let kp = DiamondKeyring.addFromUri(localWallet.seedPhrase)

    api.tx.decentralizedStorage.createEncryptedFolder(
        parentIdHash,
        folderIdHash,
        encHeader.cypherText,
        encHeader.nonce,
    ).signAndSend(kp, (res) => {
        console.log(res)
        ToastTx(res)
        ParseResultEvents(api, res)
        if (res.status.isInBlock) {
            callback && callback()
        }
    })

    return folderIdHash


}

interface GetEncryptedStorageI {
    api: ApiPromise,
    curvePair: BoxKeyPair,
    folderHeader: FolderHeaderI,
    fileName: string,
}


export const GetEncryptedFile = async (
    {
        api,
        curvePair,
        folderHeader,
        fileName,
    }: GetEncryptedStorageI) => {
    if (folderHeader.parentId === "") {
        folderHeader.parentId = GenerateStorageRootParentId(curvePair)
    }
    const fileIdHash = GenerateFileStorageId(curvePair, folderHeader.parentId, folderHeader.name, fileName)


    let res = await api.query.decentralizedStorage.encryptedStorage(fileIdHash)
    if (res.isEmpty) {
        console.log("no storage found for that hash key")
        return
    }
    let data = res.unwrap()
    //console.log("GET FILE ",data)
    let msg: CypherMsgI = {
        nonce: data.nonce.toUtf8(), //.toString(),
        cypherText: data.data.toUtf8(), //.toString(),
        senderPublicKey: curvePair.publicKey,
    }
    //console.log("msg converted from chain", msg)

    let d: FileI = decrypt(curvePair.secretKey, msg)
    //console.log("decrypted data from the chain", d)

    return d
}


export const GetEncryptedFolders = async (
    api: ApiPromise,
    curvePair: BoxKeyPair,
    folderHeader: FolderHeaderI,
) => {
    if (folderHeader.parentId === "") {
        //folderHeader.parentId = GenerateStorageRootParentId(curvePair)
        folderHeader.parentId = GenerateStorageRootParentId(curvePair)
    }
    let folderIdHash = GenerateFileStorageId(curvePair, folderHeader.parentId, folderHeader.name)
    console.log("get folders folderId hash", folderHeader, folderIdHash)
    let res = await api.query.decentralizedStorage.encryptedFolderHeaders.entries(folderIdHash)
    console.log(folderHeader, res)
    if (!res) {
        console.log("no storage found for that hash key")
        return
    }
    let headers: FolderHeaderI[] = []

    res.forEach(([key, folderHeader]) => {
        let h = folderHeader.unwrap()

        console.log('key arguments:', key.args.map((k) => k.toHuman()));
        console.log('     folder:', folderHeader.toHuman(), h);

        let msg: CypherMsgI = {
            nonce: h.nonce.toUtf8(), //.toString(),
            cypherText: h.header.toUtf8(), //.toString(),
            senderPublicKey: curvePair.publicKey,
        }
        console.log("msg from chain", msg)

        let d: FolderHeaderI = decrypt(curvePair.secretKey, msg)
        console.log("decrypted data from the chain", d)

        headers.push(d)
    });

    return headers
}

export const GetEncryptedFiles = async (
    api: ApiPromise,
    curvePair: BoxKeyPair,
    folderHeader: FolderHeaderI,
) => {
    const folderHeaderCopy = {...folderHeader};

    if (folderHeaderCopy.parentId === "") {
        folderHeaderCopy.parentId = GenerateStorageRootParentId(curvePair);
    }
    let folderIdHash = GenerateFileStorageId(curvePair, folderHeaderCopy.parentId, folderHeaderCopy.name)

    let res = await api.query.decentralizedStorage.encryptedFileHeaders.entries(folderIdHash)
    console.log("Get Files folderId", folderHeader.name, "parentID: ", folderHeader.parentId)
    console.log("currentFolder IDhash", folderIdHash, "Response", res)
    if (!res) {
        console.log("no storage found for that hash key")
        return
    }
    let headers: FileHeaderI[] = []

    res.forEach(([key, fileHeader]) => {
        let h = fileHeader.unwrap()

        //  console.log('key arguments:', key.args.map((k) => k.toHuman()));
        //  console.log('     file:', fileHeader.toHuman(), h);

        let msg: CypherMsgI = {
            nonce: h.nonce.toUtf8(), //.toString(),
            cypherText: h.header.toUtf8(), //.toString(),
            senderPublicKey: curvePair.publicKey,
        }
        //  console.log("msg from chain", msg)

        let d: FileHeaderI = decrypt(curvePair.secretKey, msg)
        //   console.log("decrypted data from the chain", d)

        headers.push(d)
    });

    return headers
}


///
/// Public Files Storage
///


export const CreatePublicFile = async (
    api: ApiPromise,
    localWallet: LocalWallet,
    curvePair: BoxKeyPair,
    folderHeader: FolderHeaderI,
    fileName: string,
    json: FileI,
    fileSize?: number,
    callback?: () => any,
    //setSubmitting?: (t: boolean) => any,
) => {
    if (folderHeader.parentId === "") {
        folderHeader.parentId = GenerateStorageRootParentId(curvePair)
    }

    const folderIdHash = GenerateFileStorageId(curvePair, folderHeader.parentId, folderHeader.name)
    const fileIdHash = GenerateFileStorageId(curvePair, folderHeader.parentId, folderHeader.name, fileName)

    let encodedData = encode(json,)
    console.log("cypher message object", encodedData)

    let header: FileHeaderI = {
        name: fileName,
        size: fileSize,
    }

    let encodedHeader = encode(header    )

    console.log("header", toHexString(encodedHeader), "data", toHexString(encodedData))
    let kp = DiamondKeyring.addFromUri(localWallet.seedPhrase)

    await api.tx.decentralizedStorage.createPublicStorage(
        folderIdHash,
        fileIdHash,
        encodedHeader,
        encodedData,
    ).signAndSend(kp, (res) => {
        console.log(res)
        ToastTx(res)
        ParseResultEvents(api, res)
        if (res.status.isInBlock) {
            callback && callback()
        }
    })

    return {
        folderIdHash,
        fileIdHash
    }

}


export const CreatePublicFolder = async (
    api: ApiPromise,
    localWallet: LocalWallet,
    curvePair: BoxKeyPair,
    folderHeader: FolderHeaderI,
    folderName: string,
    callback?: () => any,
    //setSubmitting?: (t: boolean) => any,//Dispatch<SetStateAction<boolean>>,
) => {
    if (folderHeader.parentId === "" || folderHeader.name === "/") {
        folderHeader.parentId = GenerateStorageRootParentId(curvePair)
    }
    const parentIdHash = GenerateFileStorageId(curvePair, folderHeader.parentId, folderHeader.name)
    const folderIdHash = GenerateFileStorageId(curvePair, parentIdHash, folderName)
    console.log(folderHeader, folderIdHash)

    const nonce = newNonce();

    const header: FolderHeaderI = {
        name: folderName,
        parentId: parentIdHash,
    }
    let encodedHeader = encode(header)
    console.log("header", toHexString(encodedHeader))
    let kp = DiamondKeyring.addFromUri(localWallet.seedPhrase)

    api.tx.decentralizedStorage.createPublicFolder(
        parentIdHash,
        folderIdHash,
        encodedHeader,
    ).signAndSend(kp, (res) => {
        console.log(res)
        ToastTx(res)
        ParseResultEvents(api, res)
        if (res.status.isInBlock) {
            callback && callback()
        }
    })

    return folderIdHash


}

interface GetPublicStorageI {
    api: ApiPromise,
    curvePair: BoxKeyPair,
    folderHeader: FolderHeaderI,
    fileName: string,
}


export const GetPublicFile = async (
    {
        api,
        curvePair,
        folderHeader,
        fileName,
    }: GetPublicStorageI) => {
    if (folderHeader.parentId === "") {
        folderHeader.parentId = GenerateStorageRootParentId(curvePair)
    }
    const fileIdHash = GenerateFileStorageId(curvePair, folderHeader.parentId, folderHeader.name, fileName)

    let res = await api.query.decentralizedStorage.publicStorage(fileIdHash)
    if (res.isEmpty) {
        console.log("no storage found for that hash key")
        return
    }
    let data = res.unwrap()
    //console.log("GET FILE ",data)
    let msg = data.data.toUtf8()

    let d: FileI = decode(msg)
    //console.log("decrypted data from the chain", d)

    return d
}


export const GetPublicFolders = async (
    api: ApiPromise,
    curvePair: BoxKeyPair,
    folderHeader: FolderHeaderI,
) => {
    if (folderHeader.parentId === "") {
        //folderHeader.parentId = GenerateStorageRootParentId(curvePair)
        folderHeader.parentId = GenerateStorageRootParentId(curvePair)
    }
    let folderIdHash = GenerateFileStorageId(curvePair, folderHeader.parentId, folderHeader.name)
    console.log("get folders folderId hash", folderHeader, folderIdHash)
    let res = await api.query.decentralizedStorage.publicFolderHeaders.entries(folderIdHash)
    console.log(folderHeader, res)
    if (!res) {
        console.log("no storage found for that hash key")
        return
    }
    let headers: FolderHeaderI[] = []

    res.forEach(([key, folderHeader]) => {
        let h = folderHeader.unwrap()

        console.log('key arguments:', key.args.map((k) => k.toHuman()));
        console.log('     folder:', folderHeader.toHuman(), h);

        let msg = h.header.toUtf8()
        console.log("msg from chain", msg)

        let d: FolderHeaderI = decode(msg)
        console.log("decoded data from the chain", d)

        headers.push(d)
    });

    return headers
}

export const GetPublicFiles = async (
    api: ApiPromise,
    curvePair: BoxKeyPair,
    folderHeader: FolderHeaderI,
) => {
    const folderHeaderCopy = {...folderHeader};

    if (folderHeaderCopy.parentId === "") {
        folderHeaderCopy.parentId = GenerateStorageRootParentId(curvePair);
    }
    let folderIdHash = GenerateFileStorageId(curvePair, folderHeaderCopy.parentId, folderHeaderCopy.name)

    let res = await api.query.decentralizedStorage.publicFileHeaders.entries(folderIdHash)
    console.log("Get Files folderId", folderHeader.name, "parentID: ", folderHeader.parentId)
    console.log("currentFolder IDhash", folderIdHash, "Response", res)
    if (!res) {
        console.log("no storage found for that hash key")
        return
    }
    let headers: FileHeaderI[] = []

    res.forEach(([key, fileHeader]) => {
        let h = fileHeader.unwrap()

        //  console.log('key arguments:', key.args.map((k) => k.toHuman()));
        //  console.log('     file:', fileHeader.toHuman(), h);

        let msg =  h.header.toUtf8()
         // console.log("msg from chain", msg)

        let d: FileHeaderI = decode(msg)
        //   console.log("decrypted data from the chain", d)

        headers.push(d)
    });

    return headers
}