import React, { useState} from "react";
import {Flex, View, Text} from "./Themed";
import {styles} from "../constants/Styles";
import {Event} from "@polkadot/types/interfaces/system";
import ButtonUi from "./ButtonUi";


interface propsI {
    event: Event
}


const EventListItem = ({event}: propsI) => {
    const [showDetails, setShowDetails] = useState(false)

    return (
        <ButtonUi selected={showDetails} minimal={true} onPress={() => setShowDetails(!showDetails)}>
            <View style={{flex: 1}}>
                <Flex style={{
                    flex: 1,
                    alignItems: "center",
                    flexWrap: "wrap",
                    justifyContent: "space-between"
                }}>
                    <View>
                        <Text style={{}}>
                            #{event.index}
                        </Text>
                    </View>
                    <View>
                        <Text style={{}}>
                            {event.section}
                        </Text>
                    </View>
                    <View>
                        <Text style={styles.item}>
                            {event.method}
                        </Text>
                    </View>
                </Flex>
                {showDetails &&
                    <View>
                        <View>
                            <Text numberOfLines={12} style={styles.item}>
                                {JSON.stringify(event.data.toJSON(), undefined, 4)}
                            </Text>
                        </View>
                        <View>
                            <Text numberOfLines={12} style={styles.item}>
                                {JSON.stringify(event.meta. toJSON(), undefined, 4)}
                            </Text>
                        </View>
                    </View>
                }
            </View>
        </ButtonUi>

    )
}
export default EventListItem