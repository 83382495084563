import React, {useEffect, useState} from "react";
import useNameService from "../../diamond-realm-api/hooks/useNameService";
import {useBlockchain} from "../../diamond-realm-api/hooks/useBlockchain";
import useWallet, {LocalWallet} from "../../diamond-realm-api/hooks/useWallet";
import {Flex, View, Text} from "../Themed";
import {styles} from "../../constants/Styles";
import {AddressShort} from "../../utils/addressUtils";
import ClipboardButton from "../ClipboardButton";
import TransferNameView from "./TransferNameView";
import ButtonUi from "../ButtonUi";
import TransferToken from "../tokens/TransferToken";
import {ApiPromise} from "@polkadot/api";
import {BurnName, SetDefaultName} from "../../diamond-realm-api/nameService";
import EditNameInfo from "./EditNameInfo";
import Toast from "react-native-toast-message";
import {ToastError} from "../Toast";

interface propsI {
    id: string
}


const NameView = ({id}: propsI) => {
    const blockchain = useBlockchain()
    const wallet = useWallet()

    const {nameInfo} = useNameService({id})
    const [showTransfer, setShowTransfer] = useState(false)
    const [showEditNameInfo, setShowEditNameInfo] = useState(false)

    useEffect(() => {

    }, [])

    /// end of hooks

    const handleSetDefault = async () => {
        if (blockchain.api && wallet.localWallet && nameInfo?.name) {
            try {
                let res = await SetDefaultName(
                    blockchain.api,
                    wallet.localWallet,
                    nameInfo.name,
                    () => {
                    })
            } catch (err) {
                ToastError(err)
            }
        }
    }

    const handleBurnName = async () => {
        if (blockchain.api && wallet.localWallet && nameInfo?.name) {
            try {
                let res = await BurnName(
                    blockchain.api,
                    wallet.localWallet,
                    nameInfo.name,
                    () => {

                    })
            } catch (err) {
                ToastError(err)
            }
        }


    }
    return (
        <View style={{
            ...styles.card,
            flexWrap: "wrap",
        }}>

            <View>
                <Flex style={{
                    flex: 1,
                    justifyContent: "space-between",
                    alignItems: "center"
                }}>
                    <View>
                        <Text style={styles.headerHeavy}>
                            {`@${nameInfo?.name}`}
                        </Text>
                    </View>


                </Flex>
                {nameInfo?.www &&
                <View>
                    <Text style={styles.subTitle}>
                    </Text>
                    <Text style={styles.subTitle}>
                        {`${nameInfo.www}`}
                    </Text>
                </View>
                }
                {nameInfo?.owner &&
                    <Flex>
                        <Text style={styles.item}>
                            {`Owner: `}
                        </Text>
                        <ClipboardButton copyText={nameInfo.owner}
                                         text={AddressShort(nameInfo.owner)}
                        />
                    </Flex>
                }
                <Flex style={{marginBottom: 12}}>
                    <Text style={styles.item}>
                        {`id: `}
                    </Text>
                    <ClipboardButton copyText={id}
                                     text={AddressShort(id)}
                    />
                </Flex>
                {nameInfo && nameInfo?.owner === wallet.address &&
                    <>
                        <Flex>
                            <ButtonUi selected={showTransfer} minimal={true} onPress={() => {
                                setShowTransfer(!showTransfer)
                            }
                            }>
                                <Text>Transfer</Text>
                            </ButtonUi>
                        </Flex>
                        {showTransfer &&
                            <TransferNameView id={id}/>
                        }

                        <Flex>
                            <ButtonUi selected={showEditNameInfo} minimal={true} onPress={() => {
                                setShowEditNameInfo(!showEditNameInfo)
                            }}>
                                <Text>Edit Name Info</Text>
                            </ButtonUi>

                        </Flex>
                        {showEditNameInfo &&
                            <EditNameInfo id={nameInfo.id} onFinish={() => {
                                setShowEditNameInfo(false)
                            }}/>
                        }

                        <Flex>
                            <ButtonUi minimal={true} onPress={() => {
                                handleBurnName()
                            }
                            }>
                                <Text>Burn (Destroy)</Text>
                            </ButtonUi>

                        </Flex>

                        {nameInfo.name !== wallet.nick &&
                            <Flex>
                                <ButtonUi minimal={true} onPress={() => {
                                    handleSetDefault()
                                }
                                }>
                                    <Text>Set Active</Text>
                                </ButtonUi>

                            </Flex>
                        }
                    </>
                }


            </View>

        </View>
    )
}
export default NameView