import React from "react";

import {BaseToast, ErrorToast} from "react-native-toast-message";
import {View, Text, Flex} from "./Themed";
import {ToastConfig} from "react-native-toast-message/lib/src/types";
import Colors from "../constants/Colors";

const toastConfig: ToastConfig = {
    /*
      Overwrite 'success' type,
      by modifying the existing `BaseToast` component
    */
    success: (props) => (
        <BaseToast
            {...props}
            style={{borderLeftColor: Colors.dark.tint}}
            contentContainerStyle={{backgroundColor: Colors.dark.modal}}
            text1Style={{
                fontSize: 14,
                fontWeight: '400',
                color: Colors.dark.text
            }}
            text2Style={{
                fontSize: 12,
                fontWeight: '800',
                color: Colors.dark.text
            }}
        />
    ),
    info: (props) => (
        <BaseToast
            {...props}
            style={{borderLeftColor: Colors.dark.brightBlue}}
            contentContainerStyle={{backgroundColor: Colors.dark.modal}}
            text1Style={{
                fontSize: 14,
                fontWeight: '400',
                color: Colors.dark.text
            }}
            text2Style={{
                fontSize: 12,
                fontWeight: '800',
                color: Colors.dark.text
            }}
        />
    ),
    /*
      Overwrite 'error' type,
      by modifying the existing `ErrorToast` component
    */
    error: (props) => (
        <ErrorToast
            {...props}
            text1Style={{
                fontSize: 16
            }}
            text2Style={{
                fontSize: 11
            }}
        />
    ),
    /*
      Or create a completely new type - `tomatoToast`,
      building the layout from scratch.

      I can consume any custom `props` I want.
      They will be passed when calling the `show` method (see below)
    */
    tomatoToast: ({text1, text2, props}) => (
        <View style={{
            padding: 12,
            height: 60,
            width: '100%',
            backgroundColor: 'tomato',

        }}
        >
            <Flex style={{
                alignContent: "center"
            }}>
                <View style={{flex: 1}}>
                    <View>
                        <Text style={{fontSize: 14, fontWeight: "300"}}>{text1}</Text>
                    </View>
                    <View>
                        <Text style={{fontSize: 12, fontWeight: "700"}}>{text2}</Text>
                    </View>
                </View>
            </Flex>

        </View>
    )
};

export default toastConfig