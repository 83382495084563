import React, {useEffect, useState} from "react";


import {Contact, FileI, FolderHeaderI} from "../../diamond-realm-api/interfaces";
import {GetEncryptedFile} from "../../diamond-realm-api/storage";
import {CreateContact, GetContact} from "../../diamond-realm-api/contacts";
import {useBlockchain} from "../../diamond-realm-api/hooks/useBlockchain";
import useWallet from "../../diamond-realm-api/hooks/useWallet";
import {Flex, View, Text} from "../Themed";
import Colors from "../../constants/Colors";
import {Link} from "@react-navigation/native";
import {faAddressCard, faEdit, faEnvelope, faHome} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-native-fontawesome";
import {styles} from "../../constants/Styles";
import {AddressShort} from "../../utils/addressUtils";
import Loading from "../Loading";
import ClipboardButton from "../ClipboardButton";
import ButtonUi from "../ButtonUi";

import {TextInput} from "react-native";
import ButtonText from "../ButtonText";
import {useForm} from "../../hooks/useForm";
import useNameService from "../../diamond-realm-api/hooks/useNameService";

interface propsI {
    folderHeader: FolderHeaderI
    contactName: string,
    setValue?: (addr: string) => void
}


const ContactView = ({folderHeader, contactName, setValue}: propsI) => {
    const blockchain = useBlockchain()
    const wallet = useWallet()

    const [contact, setContact] = useState<Contact>()
    const [showEdit, setShowEdit] = useState(false)
    const {nick} = useNameService({address: contact?.diamondAddress})

    const initForm = {
        firstName: "",
        lastName: "",
        diamondAddress: "",
        email: "",
        phone: "",
        websiteUrl: "",
        avatarUr: "",
        company: "",
        folderHeader: {
            parentId: "",
            name: "/"
        }
    }
    const form = useForm(initForm)

    useEffect(() => {
        if (contact) {
            form.setFormData({
                ...form.formData,
                ...contact
            })
        }
    }, [contact])

    useEffect(() => {
        handleGetContact()

    }, [folderHeader, contactName])

    /// end of hooks
    const handleGetContact = async () => {
        if (blockchain.api && wallet.localWallet && wallet.address && wallet.curvePair) {
            let r = await GetContact(
                blockchain.api,
                wallet.curvePair,
                folderHeader,
                contactName,
            )
            if (r) {
                setContact(r)
            }
        }
    }

    const handleCreateContact = async () => {
        form.setSubmitting(true)
        console.log("create contact", form.formData)
        if (blockchain.api && wallet.localWallet && wallet.address && wallet.curvePair) {
            const f = form.formData
            let data: Contact = {
                firstName: f.firstName,
                lastName: f.lastName,
                email: f.email,
                phone: f.phone,
                websiteUrl: f.websiteUrl,
                avatarUrl: f.avatarUrl,
                company: f.company,
                diamondAddress: f.diamondAddress,
            }
            //let contactName = data.firstName + " " + data.lastName
            try {
                let res = await CreateContact(
                    blockchain.api,
                    wallet.localWallet,
                    wallet.curvePair,
                    form.formData.folderHeader,
                    contactName,
                    data,
                    () => {
                        form.setFormData(initForm)
                        handleGetContact()
                        form.setSubmitting(false)
                        setShowEdit(false)
                    }
                )
            } catch (err) {
                console.log(err)
                form.setSubmitting(false)
            }
        }
    }


    if (!contact) return <Loading/>

    return (
        <View style={{
            //alignItems: "center",
            //width: "100%",
            //justifyContent: "space-between",
            //flex: 1,
            ...styles.card,
            flexWrap: "wrap",
        }}>
            <View>
                <Flex style={{
                    flex: 1,
                    justifyContent: "space-between",
                    alignItems: "center"
                }}>
                    <Text style={styles.headerHeavy}>
                        {`${contact?.firstName} ${contact?.lastName}`}
                    </Text>
                    <ButtonUi selected={showEdit} minimal={true} onPress={() => setShowEdit(!showEdit)}>
                        <Flex style={{alignItems: "center", flexWrap: "wrap"}}>
                            <FontAwesomeIcon color={Colors.dark.text} icon={faEdit}
                                             style={{marginHorizontal: 6}}/>

                            <Text>
                                Edit
                            </Text>
                        </Flex>
                    </ButtonUi>
                </Flex>

                {showEdit ? (
                    <>
                        <TextInput
                            style={styles.input}
                            placeholder="First Name"
                            autoFocus={false}
                            value={form.formData.firstName}
                            onChangeText={form.handleChange("firstName")}
                        />

                        <TextInput
                            style={styles.input}
                            placeholder="Last Name"
                            autoFocus={false}
                            value={form.formData.lastName}
                            onChangeText={form.handleChange("lastName")}
                        />

                        <TextInput
                            style={styles.input}
                            placeholder="Email"
                            autoFocus={false}
                            value={form.formData.email}
                            onChangeText={form.handleChange("email")}
                        />

                        <TextInput
                            style={styles.input}
                            placeholder="Diamond Realm Address"
                            autoFocus={false}
                            value={form.formData.diamondAddress}
                            onChangeText={form.handleChange("diamondAddress")}
                        />

                        <TextInput
                            style={styles.input}
                            placeholder="Phone"
                            autoFocus={false}
                            value={form.formData.phone}
                            onChangeText={form.handleChange("phone")}
                        />

                        <TextInput
                            style={styles.input}
                            placeholder="Website URL"
                            autoFocus={false}
                            value={form.formData.websiteUrl}
                            onChangeText={form.handleChange("websiteUrl")}
                        />

                        <TextInput
                            style={styles.input}
                            placeholder="Avatar URL"
                            autoFocus={false}
                            value={form.formData.avatarUr}
                            onChangeText={form.handleChange("avatarUr")}
                        />

                        <TextInput
                            style={styles.input}
                            placeholder="Company"
                            autoFocus={false}
                            value={form.formData.company}
                            onChangeText={form.handleChange("company")}
                        />

                        <View>
                            <ButtonText disabled={form.submitting || !form.formData.firstName} onPress={() => {
                                handleCreateContact()
                            }}>
                                {form.submitting ? <Loading/> : "Save Contact"}
                            </ButtonText>
                        </View>
                    </>
                ) : (
                    <>
                        <Text style={styles.item}>
                            {`Email: ${contact?.email}`}
                        </Text>

                        {contact?.diamondAddress &&
                            <Flex>
                                <Text style={styles.item}>
                                    {`Address: `}
                                </Text>
                                {setValue ? (
                                    <ButtonUi minimal={true} disabled={false} onPress={() => {
                                        setValue(contact.diamondAddress!)
                                    }}>
                                        <FontAwesomeIcon color={Colors.dark.text} icon={faAddressCard}/>
                                        <Text>{AddressShort(contact.diamondAddress)}</Text>
                                    </ButtonUi>

                                ) : (
                                    <ClipboardButton copyText={contact.diamondAddress}
                                                     text={AddressShort(contact.diamondAddress)}/>
                                )}
                            </Flex>
                        }

                        {nick &&
                            <Text style={styles.item}>
                                {`@ ${nick}`}
                            </Text>
                        }

                        <Text style={styles.item}>
                            {`Phone: ${contact?.phone}`}
                        </Text>

                        <Text style={styles.item}>
                            {`Website URL: ${contact?.websiteUrl}`}
                        </Text>

                        <Text style={styles.item}>
                            {`Avatar URL: ${contact?.avatarUrl}`}
                        </Text>

                        <Text style={styles.item}>
                            {`Company: ${contact?.company}`}
                        </Text>
                    </>
                )}


            </View>

        </View>
    )
}
export default ContactView