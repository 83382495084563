import React, {useEffect, useState} from "react";
import useNameService from "../diamond-realm-api/hooks/useNameService";
import {useBlockchain} from "../diamond-realm-api/hooks/useBlockchain";
import useWallet from "../diamond-realm-api/hooks/useWallet";
import {Flex, View, Text} from "./Themed";
import {styles} from "../constants/Styles";
import {AddressShort} from "../utils/addressUtils";
import ClipboardButton from "./ClipboardButton";
import {diamondHash} from "../diamond-realm-api/crypto";
import {Header} from "@polkadot/types/interfaces/runtime/types";
import {FrameSystemEventRecord} from "@polkadot/types/lookup";

interface propsI {

}


const BlockViewer = ({}: propsI) => {
    const blockchain = useBlockchain()

    const [headers, setHeaders] = useState<Header[]>([])

    useEffect(() => {
        setupSubscriptions()

    }, [blockchain.api])

    /// end of hooks
    const setupSubscriptions = async () => {

        if (!blockchain.isConnected || !blockchain?.api) return
        //subscribe to new headers
        let unsub = await blockchain.api.rpc.chain.subscribeNewHeads((lastHeader) => {
            console.log(`last block #${lastHeader.number} has hash ${lastHeader.hash}`);
            setHeaders(prevHeaders => [
                lastHeader,
                ...prevHeaders,
            ]);
        });
        return unsub
    }

    const addToHeaders = (header: Header) => {

    }


    return (
        <View style={{
            ...styles.card,
            flex: 1,

        }}>
            <Flex style={{
                flex: 1,

                justifyContent: "space-between",
                alignItems: "center",
            }}>
                <View>
                    <Text style={styles.headerHeavy}>
                        Latest Blocks
                    </Text>
                </View>


            </Flex>
            {headers && headers[0] &&
                headers.map((h, i) => (
                    <View key={"header" + i} style={styles.listItem}>
                        <Flex style={{
                            flex: 1,
                            justifyContent: "space-between",

                        }}>
                            <View>
                                <Text style={styles.item}>
                                    #{h.number.toString()}
                                </Text>
                            </View>

                            <View>
                                <ClipboardButton text={AddressShort(h.hash.toHex())} copyText={h.hash.toHex()}/>
                            </View>

                        </Flex>
                    </View>
                ))}
        </View>
    )
}
export default BlockViewer