import React, {useRef, useState} from 'react';


import {Alert, Modal, StyleSheet, Pressable, TextInput} from 'react-native';
import {heightCalc, widthCalc, styles, CONSTANTS, stylesModal,} from '../../constants/Styles';
import {useBlockchain} from "../../diamond-realm-api/hooks/useBlockchain";
import useWallet from "../../diamond-realm-api/hooks/useWallet";
import {useForm} from "../../hooks/useForm";
import Toast from "react-native-toast-message";
import {amountToSats, DiamondKeyring} from "../../diamond-realm-api/crypto";
import Loading from '../Loading';
import ButtonText from '../ButtonText';
import Colors from '../../constants/Colors';
import {View, Flex, Text, FormLabel} from "../Themed";

import {Balance} from "@polkadot/types/interfaces/runtime";
import useTokens from "../../diamond-realm-api/hooks/useTokens";
import {MintTokens} from "../../diamond-realm-api/tokens";
import {FontAwesomeIcon} from "@fortawesome/react-native-fontawesome";
import {faAddressBook} from "@fortawesome/free-solid-svg-icons";
import ButtonUi from "../ButtonUi";
import ContactsPicker from "../Contacts/ContactsPicker";
// balance is not exported is the above balance my balance.
//import  {Balance} from '../../interfaces/augment-types';

interface props {
    id: string,
    onFinish?: () => any
}

const MintToken = (
    {

        id,
        onFinish,
    }: props) => {
    const [modalVisible, setModalVisible] = useState(false);
    const blockchain = useBlockchain()
    const wallet = useWallet()
    const {accounts, metadata, details} = useTokens()
    const [showContacts, setShowContacts] = useState(false)

    const md = metadata[id] && metadata[id]
    const detail = details[id]

    const [fee, setFee] = useState<Balance>()
    const [docs, setDocs] = useState("")

    const input_ref = useRef<TextInput | null>(null);
    const initForm = {
        addr: "",
        amount: "",
    }
    const form = useForm(initForm)

    const checkMintTx = async () => {
        if (id && blockchain.api && wallet.localWallet?.seedPhrase) {
            let amount = (parseFloat(form.formData.amount) * Math.pow(10, 12)).toFixed(0);
            console.log(amount)
            const mint = blockchain.api.tx.tokens
                .mint(id, form.formData.addr, amount)
            let meta = mint.meta.docs[0].toString()
            console.log(meta)
            setDocs(meta)
            let key = DiamondKeyring.addFromUri(wallet.localWallet.seedPhrase)
            const {partialFee, weight} = await mint.paymentInfo(key);
            console.log(`transaction will have a weight of ${weight}, with ${partialFee.toHuman()} fees`);
            setFee(partialFee)
        }
    }

    // todo refactor this out and use the MintTokens in tokens.ts
    const handleMint = async () => {
        console.log("mint token")

        if (blockchain.api && wallet.localWallet?.seedPhrase) {
            setModalVisible(!modalVisible)

            let amount = amountToSats(form.formData.amount, md.decimals);
            MintTokens(blockchain.api, wallet.localWallet, id, form.formData.addr, amount, () => {
                form.setSubmitting(false)
                form.resetFormData(initForm)
                onFinish && onFinish()
            })
        }
    }

    const handleShowConfirm = () => {
        form.setSubmitting(true)
        checkMintTx()
        setModalVisible(true)
    }

    //const ss58Address = wallet?.ss58Address
    if (detail?.issuer?.toString() !== wallet?.ss58Address) return null

    return (
        <View style={{
            //...styles.listItem,
            marginTop: heightCalc(1),
            paddingVertical: heightCalc(1),
            paddingHorizontal: heightCalc(2),
        }}>
            <View style={{
                width: "100%"
            }}>
                <Text style={styles.title}>Mint {md?.name}</Text>
            </View>
            <View style={{
                width: "100%"
            }}>

                <Flex>
                    {showContacts ? (
                        <View style={{flex: 1}}>
                            <ContactsPicker setValue={(addr: string) => {
                                form.setFormData({
                                    ...form.formData,
                                    addr,
                                })
                                setShowContacts(false)
                            }}/>
                        </View>
                    ) : (
                        <View style={{flex: 1}}>
                        <TextInput
                            ref={input_ref}
                            style={styles.input}
                            placeholder="Recipient Address"
                            //autoFocus={true}
                            value={form.formData.addr}
                            onChangeText={form.handleChange("addr")}
                            //onSubmitEditing={handleTransfer}
                        />
                        </View>
                    )}

                    <ButtonUi minimal={false} onPress={() => {
                        setShowContacts(!showContacts)
                    }}>
                        <FontAwesomeIcon icon={faAddressBook}
                                         color={showContacts ? Colors.dark.tint : Colors.dark.text}/>
                    </ButtonUi>
                </Flex>
            </View>

            <View style={{
                width: "100%"
            }}>
                {/*  <FormLabel>Amount</FormLabel>*/}
                <TextInput
                    ref={input_ref}
                    style={styles.input}
                    placeholder={`Amount ${md?.symbol}`}
                    //autoFocus={true}
                    value={form.formData.amount}
                    onChangeText={form.handleChange("amount")}
                    onSubmitEditing={handleMint}
                />
            </View>

            {/* <UiButton disabled={form.submitting} onPress={handleTransfer}>
          {form.submitting ? <Loading/> : "Transfer"}
        </UiButton>
*/}
            <View style={{
                // stylesModal.centeredView
            }}>
                <Modal
                    animationType="slide"
                    transparent={true}
                    visible={modalVisible}
                    onPointerCancel={() => setModalVisible(false)}
                    onRequestClose={() => {
                        Alert.alert('Modal has been closed.');
                        setModalVisible(!modalVisible);
                    }}>
                    <View style={stylesModal.centeredView}>
                        <View style={{
                            ...stylesModal.modalView,
                            padding: 42
                        }}>
                            <Text style={styles.header}>Mint {md?.name}</Text>
                            <Text style={{marginTop: 1}}>{docs}</Text>
                            <Text style={{
                                ...styles.listItem,
                                marginTop: 6
                            }}>{`Amount: ${form.formData.amount}`} {md?.symbol}</Text>

                            <Text numberOfLines={4} style={{
                                ...styles.item,
                                marginTop: 6
                            }}>
                                {`To: ${form.formData.addr}`}
                            </Text>

                            <Text style={{marginVertical: 6}}>
                                {`Transaction Fee: ${fee?.toHuman()}`}
                            </Text>

                            <Flex style={{
                                marginTop: 12,
                                justifyContent: "space-between",
                            }}>
                                <ButtonText
                                    disabled={false}
                                    style={stylesModal.buttonClose}
                                    onPress={() => {
                                        setModalVisible(false)
                                        form.setSubmitting(false)
                                    }}>
                                    Cancel
                                </ButtonText>
                                <ButtonText
                                    disabled={false}
                                    onPress={() => handleMint()}
                                >
                                    Sign Transaction
                                </ButtonText>
                            </Flex>
                        </View>
                    </View>
                </Modal>
                <ButtonText disabled={form.submitting || (!form.formData.amount || !form.formData.addr)}
                            onPress={() => handleShowConfirm()}>
                    {form.submitting ? <Loading/> : "Mint"}
                </ButtonText>
            </View>
        </View>
    )
        ;
};

export default MintToken;


