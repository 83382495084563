import { ApiPromise } from "@polkadot/api";

import { LocalWallet } from "./hooks/useWallet";
import { DiamondKeyring, toHexString } from "./crypto";
import Toast from "react-native-toast-message";
import BN from "bn.js";
import { hash_string } from "./paillier-rust";
import init, * as paillier_wasm from "../diamond-realm-api/paillier-rust/paillier_rust";
import { u128, U8 } from "@polkadot/types-codec";
import { blake2AsHex } from "@polkadot/util-crypto";
import { DecimalAmount } from "../utils/NumbersUtils";
import { AssetI } from "./interfaces";
import { NameServiceNameInfoToNameInfo } from "./nameService";
import { TokensPublicKey } from "@polkadot/types/lookup";
import { Keypair } from "@polkadot/util-crypto/types";
import { ParseResultEvents, ToastTx } from "../components/Toast";


export const TokenValue = (balance?: BN, metadata?: AssetI) => {
    console.log(metadata)
    //  "USD": 1.0

    let valueUsd = 0.0
    let worth = 0.0
    if (metadata?.symbol) {
        switch (metadata.symbol) {
            case "TEA":
                valueUsd = 28.3
                break;
            case "CANNA":
                valueUsd = 40.2
                break;
            case "USD":
                valueUsd = 1.0
                break;
            case "AU":
                valueUsd = 2300.0
                break;
            case "AG":
                valueUsd = 23.23
                break;
            case "CU":
                valueUsd = 0.25
                break;
            case "DMD":
                valueUsd = 0.25
                break;
            case "SDH":
                valueUsd = 0.01
                break;
            case "DNKY":
                valueUsd = 0.001
                break;
            case "EUR":
                valueUsd = 1.07
                break;
            default:
        }
        worth = balance ? DecimalAmount(parseInt(balance.toString(10)), metadata.decimals) * valueUsd : 0.0
    }
    return { worth, valueUsd }

}


export const PublishTokenAddress = async (
    api: ApiPromise,
    localWallet: LocalWallet,
    addr: string,
    callback?: () => any,
) => {
    Toast.show({
        type: 'info',
        text1: `Processing`,
        text2: `Transaction is being processed on chain`
    });
    console.log(addr, "addr length", addr.length)
    //const seed = mnemonicToMiniSecret(localKey.localWallet.seedPhrase);
    let kp = DiamondKeyring.addFromUri(localWallet.seedPhrase)
    try {
        await api.tx.tokens.publishKey(
            addr,
        ).signAndSend(kp, (result) => {
            console.log(" token published", result)
            ToastTx(result)
            ParseResultEvents(api, result)
            if (result.status.isInBlock) {
                callback && callback()
            }
        })
        return addr
    } catch (err) {


        throw err
    }
}


export interface CreateTokenI {
    api: ApiPromise,
    ownerAddress: string,
    name: string,
    symbol: string,
    decimals: string,
    localWallet: LocalWallet,
    mint: string,
    image?: string,
    callback?: () => any,
    //setSubmitting: (t: boolean) => any,//Dispatch<SetStateAction<boolean>>,
}

export const CreateToken = async (
    {
        api,
        ownerAddress,
        name,
        symbol,
        decimals,
        localWallet,
        mint,
        image,
        callback,
        //setSubmitting,
    }: CreateTokenI) => {
    console.log("create token")
    Toast.show({
        type: 'info',
        text1: `Processing`,
        text2: `Transaction is being processed on chain`
    });
    const num = (Math.floor(Math.random() * Math.pow(2, 30))).toString();
    await init()
    const id = blake2AsHex(num) // paillier_wasm.hash_string(num)
    console.log("id", id)

    let kp = DiamondKeyring.addFromUri(localWallet.seedPhrase)
    const tx = api.tx.tokens.create(
        id,
        ownerAddress,
        name,
        symbol,
        decimals,
        mint,
        image || null,
    )
    try {
        await tx.signAndSend(kp, (result) => {
            console.log("create result", result)
            ToastTx(result)
            ParseResultEvents(api, result)
            if (result.status.isInBlock) {
                callback && callback()
            }
        })
        return id
    } catch (err) {
        Toast.show({
            type: 'tomatoToast',
            text1: `Error`,
            text2: `${err}`,
            visibilityTime: 12000,
        });
        callback && callback()
    }
}


export const UpdateMetadata = async (
    api: ApiPromise,
    localWallet: LocalWallet,
    id: string,
    name: string,
    symbol: string,
    decimals: string,
    image: string | null,
    callback?: () => any,
) => {
    Toast.show({
        type: 'info',
        text1: `Processing`,
        text2: `Transaction is being processed on chain`
    });

    let kp = DiamondKeyring.addFromUri(localWallet.seedPhrase)
    const tx = api.tx.tokens.update(
        id,
        name,
        symbol,
        decimals,
        image || null
    )
    try {
        await tx.signAndSend(kp, (result) => {
            console.log("update result", result)
            ToastTx(result)
            ParseResultEvents(api, result)
            if (result.status.isInBlock) {
                callback && callback()
            }
        })
        return id
    } catch (err) {
        Toast.show({
            type: 'tomatoToast',
            text1: `Error`,
            text2: `${err}`,
            visibilityTime: 12000,
        });
        callback && callback()
    }
}


export const MintTokens = async (
    api: ApiPromise,
    localWallet: LocalWallet,
    id: string,
    beneficiary: string,
    amount: string,
    callback?: () => any,
) => {
    Toast.show({
        type: 'info',
        text1: `Processing`,
        text2: `Transaction is being processed on chain`
    });
    console.log(id, "id length", id.length)
    //const seed = mnemonicToMiniSecret(localKey.localWallet.seedPhrase);
    //let kp = DiamondKeyring.addFromUri(localWallet.seedPhrase)
    let k = localWallet.secretKey.slice(localWallet.secretKey.length - 62, localWallet.secretKey.length)
    console.log("length", k)
    //const kp = DiamondKeyring.addFromSeed(k);
    let keypair: Keypair = {
        secretKey: localWallet.secretKey,
        publicKey: localWallet.address,
    }
    const kp = DiamondKeyring.addFromPair(keypair)
    try {
        await api.tx.tokens.mint(
            id,
            beneficiary,
            new BN(amount),
        ).signAndSend(kp, (result) => {
            console.log(result)
            ToastTx(result)
            ParseResultEvents(api, result)
            if (result.status.isInBlock) {
                callback && callback()
            }
        })
        return id
    } catch (err) {
        Toast.show({
            type: 'tomatoToast',
            text1: `Error`,
            text2: `${err}`,
            visibilityTime: 12000,
        });
        callback && callback()
    }
}

export const TransferTokens = async (
    api: ApiPromise,
    localWallet: LocalWallet,
    id: string,
    source: string,
    destination: string,
    amount: string,
    callback?: () => any,
) => {
    Toast.show({
        type: 'info',
        text1: `Processing`,
        text2: `Transaction is being processed on chain`
    });
    console.log(id, "id length", id.length)

    // first we need to get our token address
    // then get destination token address
    // then encrypt the amount to both
    // then send that along

    let address_source = (await GetTokenPublicAddress(api, source))?.pubKey.toHuman() as string
    if (!address_source) return
    console.log(address_source)
    let address_destination = (await GetTokenPublicAddress(api, destination))?.pubKey.toHuman() as string
    if (!address_destination) return
    console.log(address_destination)
    await init()
    let amount_big_number = new BN(amount)
    let nonce = new BN("23534522365")
    let nonce2 = new BN("43335353565")
    let encrypted_amount_source = paillier_wasm.encrypt_number_as_string(address_source, new Uint8Array(amount_big_number.toArray()), new Uint8Array(nonce.toArray()))
    let encrypted_amount_destination = paillier_wasm.encrypt_number_as_string(address_destination, new Uint8Array(amount_big_number.toArray()), new Uint8Array(nonce2.toArray()))
    /*const chars = Array.from(encrypted_amount_source, byte => String.fromCharCode(byte));
    const enc_source_str = chars.join('');

    const charsd = Array.from(encrypted_amount_destination, byte => String.fromCharCode(byte));
    const enc_dest_str = charsd.join('');
    //let enc_source_str = toHexString(Array.from(encrypted_amount_source))
    //let enc_dest_str = toHexString(Array.from(encrypted_amount_destination))
    */
    console.log("enc source :", encrypted_amount_source.length, encrypted_amount_source,)
    console.log("enc dest length", encrypted_amount_destination.length, encrypted_amount_destination)

    let kp = DiamondKeyring.addFromUri(localWallet.seedPhrase)
    try {
        await api.tx.tokens.transfer(
            id,
            source,
            destination,
            encrypted_amount_source,
            encrypted_amount_destination,
            amount_big_number,
        ).signAndSend(kp, (result) => {
            console.log(result)
            ToastTx(result)
            ParseResultEvents(api, result)
            if (result.status.isInBlock) {
                callback && callback()
            }
        })
        return id
    } catch (err) {
        console.log(err)
        Toast.show({
            type: 'tomatoToast',
            text1: `Error`,
            text2: `${err}`,
            visibilityTime: 16000,
        });
        callback && callback()
    }
}


//// Queries


export const GetTokenPublicAddress = async (
    api: ApiPromise,
    addr: string
): Promise<TokensPublicKey | undefined> => {
    try {
        let res = await api.query.tokens.publicKeys(addr)
        if (!res) return
        let data = res.unwrap()
        console.log("public key info", data);
        return data
    } catch (err) {
        console.log("error getting public key info", err)
        Toast.show({
            type: 'tomatoToast',
            text1: `Account Not Registered on chain`,
            text2: `${err}`,
            visibilityTime: 12000,
        });
    }
}
