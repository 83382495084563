import React, {useRef, useState} from 'react';
import {Alert, Modal, StyleSheet, Pressable, TextInput} from 'react-native';
import {heightCalc, widthCalc, styles, CONSTANTS, stylesModal,} from '../../constants/Styles';
import {useBlockchain} from "../../diamond-realm-api/hooks/useBlockchain";
import useWallet from "../../diamond-realm-api/hooks/useWallet";
import {useForm} from "../../hooks/useForm";
import Toast from "react-native-toast-message";
import {amountToSats, DiamondKeyring} from "../../diamond-realm-api/crypto";
import Loading from '../Loading';
import ButtonText from '../ButtonText';
import Colors from '../../constants/Colors';
import {View, Flex, Text, FormLabel} from "../Themed";
import {Balance} from "@polkadot/types/interfaces/runtime";
import useTokens from "../../diamond-realm-api/hooks/useTokens";
import {TransferTokens} from "../../diamond-realm-api/tokens";
import useNameService from "../../diamond-realm-api/hooks/useNameService";
import {TransferName} from "../../diamond-realm-api/nameService";
import AddressContactsInput from "../AddressContactsInput";
import AccountNickText from "../AccountNickText";

interface props {
    id: string,
}

const TransferNameView = (
    {
        id,
    }: props) => {
    const [modalVisible, setModalVisible] = useState(false);
    const blockchain = useBlockchain()
    const wallet = useWallet()
    const {nameInfo} = useNameService({id})

    const [fee, setFee] = useState<Balance>()
    const [docs, setDocs] = useState("")

    const input_ref = useRef<TextInput | null>(null);
    const form = useForm({
        diamondAddress: "",
    })

    const checkTx = async () => {
        if (id && form.formData.diamondAddress && blockchain.api && wallet.address && wallet.localWallet?.seedPhrase) {
            const transfer = blockchain.api.tx.nameService
                .transfer(id, form.formData.diamondAddress)
            let meta = transfer.meta.docs[0].toString()
            console.log(meta)
            setDocs(meta)
            let key = DiamondKeyring.addFromUri(wallet.localWallet.seedPhrase)
            const {partialFee, weight} = await transfer.paymentInfo(key);
            console.log(`transaction will have a weight of ${weight}, with ${partialFee.toHuman()} fees`);
            setFee(partialFee)
        }
    }

    const handleTransfer = async () => {
        console.log("transfer token")

        if (nameInfo?.name && blockchain.api && wallet.address && wallet.localWallet?.seedPhrase) {
            setModalVisible(!modalVisible)
            TransferName(blockchain.api, wallet.localWallet, nameInfo.name, form.formData.diamondAddress,
                () => {
                    form.setSubmitting(false)
                })
        }
    }

    const handleShowConfirm = () => {
        form.setSubmitting(true)
        checkTx()
        setModalVisible(true)
    }

    return (
        <View style={{
            //...styles.listItem,
            marginTop: heightCalc(1),
            paddingVertical: heightCalc(1),
            paddingHorizontal: heightCalc(2),
        }}>
            <View style={{
                width: "100%"
            }}>
                <Text style={styles.title}>Transfer {nameInfo?.name}</Text>
            </View>
            {/*  <View style={{
                width: "100%"
            }}>
                 <FormLabel>Recipient Address</FormLabel>
                <TextInput
                    ref={input_ref}
                    style={styles.input}
                    placeholder="Recipient Address"
                    //autoFocus={true}
                    value={form.formData.addr}
                    onChangeText={form.handleChange("addr")}
                    //onSubmitEditing={handleTransfer}
                />
            </View>*/}
            <AddressContactsInput
                placeholder="Recipient Address"
                form={form}
                contactsPickerSetValue={(addr: string) => {
                    form.setFormData({
                        ...form.formData,
                        diamondAddress: addr,
                    })
                }}/>
            {form.formData.diamondAddress &&
                <View style={{marginVertical: "2%"}}>
                    <AccountNickText address={form.formData.diamondAddress}/>
                </View>
            }
            <View style={{
                // stylesModal.centeredView
            }}>
                <Modal
                    animationType="slide"
                    transparent={true}
                    visible={modalVisible}
                    onPointerCancel={() => setModalVisible(false)}
                    onRequestClose={() => {
                        Alert.alert('Modal has been closed.');
                        setModalVisible(!modalVisible);
                    }}>
                    <View style={stylesModal.centeredView}>
                        <View style={{
                            ...stylesModal.modalView,
                            padding: 42
                        }}>
                            <Text style={styles.header}>Transfer {nameInfo?.name as string}</Text>
                            <Text style={{marginTop: 1}}>{docs}</Text>


                            <Text numberOfLines={4} style={{
                                ...styles.item,
                                marginTop: 6
                            }}>
                                {`To: ${form.formData.addr}`}
                            </Text>

                            <Text style={{marginVertical: 6}}>
                                {`Transaction Fee: ${fee?.toHuman()}`}
                            </Text>

                            <Flex style={{
                                marginTop: 12,
                                justifyContent: "space-between",
                            }}>
                                <ButtonText
                                    disabled={false}
                                    style={stylesModal.buttonClose}
                                    onPress={() => {
                                        setModalVisible(false)
                                        form.setSubmitting(false)
                                    }}>
                                    Cancel
                                </ButtonText>
                                <ButtonText
                                    disabled={false}
                                    onPress={() => handleTransfer()}
                                >
                                    Sign Transaction
                                </ButtonText>
                            </Flex>
                        </View>
                    </View>
                </Modal>
                <ButtonText disabled={form.submitting || !form.formData.diamondAddress}
                            onPress={() => handleShowConfirm()}>
                    {form.submitting ? <Loading/> : "Transfer"}
                </ButtonText>
            </View>
        </View>
    )
        ;
};


export default TransferNameView;


