import {ScrollView} from 'react-native';
import React, {useEffect, useMemo, useState} from "react";
import BN from "bn.js";
import {createNativeStackNavigator, NativeStackScreenProps} from "@react-navigation/native-stack";
import Toast from "react-native-toast-message";
import {PublishTokenAddress} from "../../diamond-realm-api/tokens";
import {toHexString} from "../../diamond-realm-api/crypto";
import init from "../../diamond-realm-api/paillier-rust";
import * as paillier_wasm from "../../diamond-realm-api/paillier-rust";
import {useBlockchain} from "../../diamond-realm-api/hooks/useBlockchain";
import useWallet from "../../diamond-realm-api/hooks/useWallet";
import useTokens from "../../diamond-realm-api/hooks/useTokens";
import {useForm} from "../../hooks/useForm";
import {Text, View} from "../Themed";
import {CONSTANTS, styles} from "../../constants/Styles";
import ButtonText from '../ButtonText';
import Loading from "../Loading";


interface propsI {

}

export default function RegisterAccount({}: propsI) {
    const blockchain = useBlockchain()
    const wallet = useWallet()
    const {accounts, metadata} = useTokens()
    const [assetIds, setAssetIds] = useState<string[]>([])
    // const [tokenAddr, setTokenAddr] = useState("")
    const {tokenAddress} = useTokens()

    const [nonce, setNonce] = useState<BN>()
    const initForm = {}
    const form = useForm(initForm)

    useEffect(() => {
        work()

    }, [blockchain.api, wallet.localWallet])

    useEffect(() => {
        //generatePaillierPublicTokenAddress()
        //testPaillierWasm()
    }, [wallet?.localWallet?.secretKey])

    const work = async () => {
        if (blockchain.api) {
            if (wallet.address) {
                let t = await blockchain.api.query.contracts.contractInfoOf(wallet.address)
                console.log(" Contract info for your address", t)
            }
            //let keys = await api.query.assets.asset.keys()
            let keys = await blockchain.api.query.tokens.token.keys()
            let ids: string[] = []
            for (let i = 0; i < keys.length; i++) {
                let id = keys[i].args[0].toString()
                console.log("id", id)
                ids = ids.concat(id)
            }
            setAssetIds(ids)
        }
    }



    const handlePublishTokenAddress = async () => {
        if (blockchain.api && wallet.localWallet) {
            form.setSubmitting(true)
            await init()
            console.log("finish wasm init")

            let seed = `${toHexString(wallet!.localWallet!.secretKey)}::accounts::0`
            console.log('Seed ', seed)
            let hash = paillier_wasm.hash_string(seed)
            console.log('Wasm hash ', toHexString(hash))

            let publicAddress = paillier_wasm.generate_public_key_from_seed(seed)
            // setTokenAddr(publicAddress)

            Toast.show({
                type: 'info',
                text1: `Processing`,
                text2: `Transaction is being processed on chain`
            });
            try {
                let res = await PublishTokenAddress(blockchain.api, wallet.localWallet, publicAddress, () => {
                    form.setSubmitting(false)
                })
                console.log(res)
            } catch (err) {
                console.log(err)
                Toast.show({
                    type: 'tomatoToast',
                    text1: `Error`,
                    text2: `${err}`,
                    visibilityTime: 12000,
                });
            }

        }
    }
    const generatePaillierPublicTokenAddress = async () => {
        if (wallet?.localWallet?.secretKey) {
            console.log("start wasm init")
            init().then((_exports) => {
                console.log("finish wasm init")

                let seed = `${toHexString(wallet!.localWallet!.secretKey)}::accounts::0`
                console.log('Seed ', seed)
                let hash = paillier_wasm.hash_string(seed)
                console.log('Wasm hash ', toHexString(hash))

                let publicAddress = paillier_wasm.generate_public_key_from_seed(seed)
                //   setTokenAddr(publicAddress)

            })
        }
    }
    /*const testPaillierWasm = async () => {
        if (wallet?.localWallet?.secretKey) {
            console.log("start wasm init")
            const _exports = await init()
            console.log("finish wasm init")

            let seed = `${toHexString(wallet!.localWallet!.secretKey)}::accounts::0`
            let hash = paillier_wasm.hash_string(seed)
            console.log('Wasm hash ', seed, toHexString(hash))

            let publicAddress = paillier_wasm.generate_public_key_from_seed(seed)


            let n1 = new BN("12000000000000000000")
            let n2 = new BN("1000000000000")
            let n3 = new BN("1")


            let nonce = new BN("234525265")
            let c1 = paillier_wasm.encrypt_number(publicAddress, new Uint8Array(n1.toArray()), new Uint8Array(nonce.toArray()))
            let nonce2 = new BN("23452542342432423265") // randomAsU8a()
            let c2 = paillier_wasm.encrypt_number(publicAddress, new Uint8Array(n2.toArray()), new Uint8Array(nonce2.toArray()))

            let e3 = paillier_wasm.add_ciphertexts(publicAddress, c1, c2)
            let e4 = paillier_wasm.mul_ciphertext_plaintext(publicAddress, c1, new Uint8Array(n2.toArray()))

            let nonce3 = new BN("23452542342432423265") // randomAsU8a()
            let e5 = paillier_wasm.add_ciphertext_plaintext(publicAddress, c1, new Uint8Array(n3.toArray()), new Uint8Array(nonce3.toArray()))

            let e6 = paillier_wasm.sub_ciphertexts(publicAddress, c1, c2)


            console.log("===== ====== ====== decrypted values ==== ===== ======= ")
            console.log("c1 = ", paillier_wasm.decrypt_number(seed, c1))
            console.log("c2 = ", paillier_wasm.decrypt_number(seed, c2))

            console.log("encrypted add")
            console.log(paillier_wasm.decrypt_number(seed, e3))

            console.log("encrypted sub")
            console.log(paillier_wasm.decrypt_number(seed, e6))

            console.log("encrypted multiply")
            console.log(paillier_wasm.decrypt_number(seed, e4))

            console.log("+ plaintext")
            console.log(n3.toString())
            console.log("_______")
            let d5 = paillier_wasm.decrypt_number(seed, e5)

            console.log(d5)


        }
    }*/


    //if (isLoading) return <div>Loading ...</div>;
    if (wallet?.address && !tokenAddress) {
        return (

            <View style={{marginTop: 12}}>
                <ButtonText selected={form.submitting} minimal={false} style={{marginRight: 6}} disabled={false}
                            onPress={() => {
                                handlePublishTokenAddress()
                            }}>
                    {form.submitting ? <Loading/> :
                        <Text style={{marginRight: 12}}>Register your Account</Text>
                    }
                </ButtonText>
            </View>

        );
    }
}

