import {asHexAddress, toHexString} from "../../diamond-realm-api/crypto";
import React from "react";
import {LocalWallet} from "../../diamond-realm-api/hooks/useWallet";
import {View, Text} from "../Themed";
import {CONSTANTS, styles} from "../../constants/Styles";
import ClipboardButton from "../ClipboardButton";


interface DisplayKeyI {
  localWallet: LocalWallet
}

const DisplayKey = ({localWallet}: DisplayKeyI) => {

  return (
    <View style={{
      padding: 12,
      borderRadius: CONSTANTS.borderRadius,
      backgroundColor: "rgba(0,1,2,0.2)",


    }}>
      <Text>
        Public Address:
      </Text>
      <View style={{ flexDirection:'row', }}>
        <Text numberOfLines={4} style={{ flexShrink: 1, flexWrap: 'wrap', fontWeight:"900" }}>
          <ClipboardButton text={asHexAddress(localWallet.address)}/>

      </Text>
      </View>
      <Text>
        Secret Key:
      </Text>
      <View style={{flexDirection:'row'}}>
        <Text numberOfLines={4} style={{ flexShrink: 1, flexWrap: 'wrap', fontWeight:"900" }}>

        0x{localWallet.secretKey && localWallet.secretKey[0] && toHexString(localWallet.secretKey)}
      </Text>
      </View>
      {localWallet.seedPhrase && (
        <>
          <Text>
            Seed Phrase:
          </Text>
          <View style={{flexDirection:'row'}}>
            <Text style={{flexShrink: 1, flexWrap: 'wrap', fontWeight:"900" }}>
            {localWallet.seedPhrase}
            </Text>
          </View>
        </>
      )}
    </View>
  )

}

export default DisplayKey