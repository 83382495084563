import {Button, Image, ImageBackground, ScrollView, TextInput, TouchableHighlight} from 'react-native';

import {Flex, FormLabel, Text, View} from '../components/Themed';
import React, {useEffect, useRef, useState} from "react";
import {useForm} from "../hooks/useForm";
import {CONSTANTS, styles} from "../constants/Styles";
import Colors from "../constants/Colors";

import {ChatCompletionResponseMessage} from "openai";
import {
    faFileCirclePlus,
    faMoneyBill1Wave as faAsset
} from "@fortawesome/free-solid-svg-icons"
import {FontAwesomeIcon,} from '@fortawesome/react-native-fontawesome';
import ButtonText from '../components/ButtonText';
import {randomId} from "../utils/rando";

import {useBlockchain} from "../diamond-realm-api/hooks/useBlockchain";
import useWallet from "../diamond-realm-api/hooks/useWallet";
import Loading from "../components/Loading";
import {encodeAddress} from "@polkadot/keyring";
import {FormatDecimalAmount, FormatNumber} from "../utils/NumbersUtils";
import AccountNickText from "../components/AccountNickText";
import {CreateToken} from "../diamond-realm-api/tokens";
import TokenListItem from "../components/tokens/TokenListItem";
import {amountToSats, asHexAddress} from "../diamond-realm-api/crypto";
import Toast from "react-native-toast-message";
import RegisterAccount from "../components/tokens/RegisterAccount";
import {getDocumentAsync} from "expo-document-picker";

export default function DaoScreen() {
    const [userId, setUserId] = useState(randomId())
    const [namesResponse, setNamesResponse] = useState<ChatCompletionResponseMessage>()
    const [showActions, setShowActions] = useState(false)
    const [tokenId, setTokenId] = useState<string>()
    const [showUpload, setShowUpload] = useState(false)
    const [tokenIds, setTokenIds] = useState<string[]>([])
    const [showNew, setShowNew] = useState(false)

    const initForm = {
        name: "",
        symbol: "",
        decimals: "8",
        mint: "1000000",
    }
    const form = useForm(initForm)
    const blockchain = useBlockchain()
    const wallet = useWallet()

    const input_ref = useRef<TextInput | null>(null);
    //validate
    useEffect(() => {
//if(form.formData.symbol < )
    }, [form.formData])


    useEffect(() => {

        work()

    }, [blockchain.api, wallet.localWallet])
// end of hooks //


    const handleChange = (name: string) => {
        return (value: any) => form.setFormData({
            ...form.formData,
            [name]: value
        })
    }


    const work = async () => {
        if (blockchain.api && wallet.address) {

            let t = await blockchain.api.query.contracts.contractInfoOf(wallet.address)
            //  console.log(" Contract info for your address", t)

            let keys = await blockchain.api.query.tokens.token.keys()
            let ids: string[] = []
            for (let i = 0; i < keys.length; i++) {
                let id = keys[i].args[0]
                //  console.log(id, id.toString())
                let res = await blockchain.api.query.tokens.token(id)
                const info = res.unwrap()
                const ss58Address = encodeAddress(wallet.address);
                //console.log("asset info and wallet", info, ss58Address, info.issuer.toString())
                if (info.issuer.toString() === ss58Address) {
                    ids = ids.concat(id.toString())
                }

            }
            setTokenIds(ids)
        }
    }

    const handleCreateToken = async () => {
        if (form.formData.symbol.length < 2) {
            Toast.show({
                type: 'error',
                text1: `Symbol must be at least 2 characters`,
                //text2: `Transaction is being processed on chain`
            });
            return
        }
        form.setSubmitting(true)
        console.log("create token", form.formData)
        if (blockchain.api && wallet.localWallet && wallet.address) {
            let mint = form.formData.mint ? amountToSats(form.formData.mint, form.formData.decimals) : "0";
            let res = await CreateToken({
                api: blockchain.api,
                ownerAddress: wallet.address,
                symbol: form.formData.symbol,
                name: form.formData.name,
                decimals: form.formData.decimals,
                localWallet: wallet.localWallet,
                mint: mint,
                ...(form.formData.uploadFileUri && {image: form.formData.uploadFileUri}),
                callback: () => {
                    form.setSubmitting(false)
                    form.setFormData(initForm)
                    setShowNew(false)
                    work()
                }
            },)
            if (res) {
                setTokenId(asHexAddress(res))
                form.resetFormData(initForm)
            }
        }
    }

    const handleDocumentPick = async () => {
        setShowUpload(true)
        let res = await getDocumentAsync({
            //type: "image/*"
        })
        if (res.output && res.output[0] && res.assets && res.assets[0]) {
            form.setFormData({
                ...form.formData,
                uploadFileUri: res.assets[0].uri,
                uploadFileName: res.output[0].name,
                uploadFileSize: res.output[0].size,
              //  fileName: res.output[0].name,
            })
        }
        console.log("pick file", res)
    }


    return (
        <View style={styles.container}>
            <ScrollView
                keyboardShouldPersistTaps={'handled'}
                style={{
                    width: "100%",
                    padding: 12,
                    paddingBottom: 100,
                    maxWidth: CONSTANTS.pageWidth,
                }}>
                <View style={{flex: 1,}}>
                    <RegisterAccount />
                    <Flex style={{flex: 1, alignItems: "center"}}>

                        <ButtonText selected={showActions} minimal={true} style={{marginRight: 6}} disabled={false}
                                    onPress={() => {
                                        setShowActions(!showActions)
                                    }}>
                            <FontAwesomeIcon color={Colors.dark.text} icon={faAsset}/>
                        </ButtonText>
                        {showActions &&
                            <ButtonText selected={showNew} minimal={true} style={{marginRight: 6}}
                                        disabled={false}
                                        onPress={() => {
                                            setShowNew(!showNew)
                                        }}>
                                New Contract
                            </ButtonText>
                        }
                    </Flex>
                    {showNew && (
                        <View
                            style={{
                        //        flex: 1,
                                //flexDirection: "column",
                                marginBottom: 12,
                                marginTop: 12,
                                //maxWidth: CONSTANTS.pageWidth,
                                width: "100%",
                            }}
                        >
                            <Text style={[styles.header, styles.titleTop]}>
                                Create a Contract/Trust Ownership Token
                            </Text>

                            <View //flexDirection={"column"} flexGrow={1}
                                style={{
                                    minWidth: 60,
                                }}
                            >
                                <FormLabel>
                                    Name
                                </FormLabel>
                                <TextInput
                                    ref={input_ref}
                                    style={styles.input}
                                    placeholder=""
                                    autoFocus={false}
                                    value={form.formData.name}
                                    onChangeText={form.handleChange("name")}
                                    //onSubmitEditing={handleSubmit}
                                />

                                <FormLabel>
                                    Symbol
                                </FormLabel>
                                <TextInput
                                    autoCapitalize={"characters"}
                                    autoCorrect={false}
                                    //ref={input_ref}
                                    style={styles.input}
                                    placeholder=""
                                    autoFocus={false}
                                    value={form.formData.symbol}
                                    onChangeText={form.handleChange("symbol")}
                                    //onSubmitEditing={handleCreateToken}
                                />




                                <FormLabel>
                                    Amount to Mint
                                </FormLabel>
                                <TextInput
                                    //ref={input_ref}
                                    style={styles.input}
                                    //placeholder="12"
                                    autoFocus={false}
                                    value={form.formData.mint}
                                    onChangeText={form.handleChange("mint")}
                                    onSubmitEditing={handleCreateToken}
                                />

                                <FormLabel>
                                    Decimals
                                </FormLabel>
                                <TextInput
                                    //ref={input_ref}
                                    style={styles.input}
                                    //placeholder="12"
                                    autoFocus={false}
                                    value={form.formData.decimals}
                                    onChangeText={form.handleChange("decimals")}
                                    onSubmitEditing={handleCreateToken}
                                />

                                <ButtonText selected={showUpload} minimal={true} style={{}} disabled={false} onPress={() => {
                                    showUpload ? setShowUpload(false) : handleDocumentPick()
                                }}>
                                    Image
                                </ButtonText>

                                {form.formData.uploadFileUri &&
                                    <View>
                                        {/*<img src={form.formData.uploadFileUri}/>*/}
                                        <Image style={{marginRight: 12}} source={
                                            {
                                                width: 72,
                                                height: 72,
                                                uri: form.formData.uploadFileUri
                                            }
                                        }/>
                                    </View>
                                }

                            </View>
                            {form.formData.mint && form.formData.decimals && wallet.address && (
                                <Flex style={{
                                    marginHorizontal: 12,
                                    justifyContent: "center"
                                }}>
                                    <Text style={{
                                        fontSize:16
                                    }}>
                                        {`Mint ${FormatDecimalAmount(parseInt((parseFloat(form.formData.mint) * Math.pow(10, parseInt(form.formData.decimals))).toFixed(0)), parseInt(form.formData.decimals), parseInt(form.formData.decimals))} ${form.formData.symbol} to `}
                                    </Text>
                                    <AccountNickText address={wallet.address}/>
                                </Flex>
                            )}
                            <View>
                                <ButtonText selected={form.submitting} disabled={form.submitting || !form.formData.symbol} onPress={() => {
                                    handleCreateToken()
                                }}>
                                    {form.submitting ? <Loading/> : "Create New Token"}
                                </ButtonText>
                            </View>
                            {tokenId && (
                                <View>
                                    <Text style={styles.headerHeavy}>Token ID: {tokenId}</Text>
                                    <TokenListItem id={tokenId}/>
                                </View>
                            )}
                        </View>
                    )}

                    {tokenIds.map((id, i) => (
                        <TokenListItem key={'asset' + i} id={id}/>
                    ))}

                </View>
            </ScrollView>
        </View>
    );
}

