//"  Public key (hex):  "0x3720ca6274b37a2fe3e74507922557d7da946e3a9899a066e39a616481c4bc8d"
import {box, randomBytes, sign} from "tweetnacl";

import {decodeBase64, decodeUTF8, encodeBase64, encodeUTF8} from "tweetnacl-util";
import Keyring from "@polkadot/keyring";
import {blake2AsHex, mnemonicToMiniSecret, randomAsHex, randomAsU8a} from '@polkadot/util-crypto';
import * as ed2curve from "ed2curve";

const {
    stringToU8a,
    u8aToString
} = require('@polkadot/util');


export const DiamondKeyring = new Keyring({type: 'ed25519'});

export const newNonce = () => randomBytes(box.nonceLength);
export const generateKeyPair = () => box.keyPair();

export const diamondHash = blake2AsHex

export const amountToSats = (
    amount:string,
    decimals: number
) => {
    let sats = (parseFloat(amount) * Math.pow(10, decimals)).toFixed(0);
    return sats
}



// Encrption
export interface CypherMsgI {
    nonce: any,//string,
    cypherText: any,//string,
    senderPublicKey?: Uint8Array,
}

interface EncryptI {
    secretOrSharedKey?: Uint8Array,
    json: { [key: string]: any }
    key?: Uint8Array
    // omiting your public key will generate a sealed box
    senderPublicKey?: Uint8Array
    nonce?: Uint8Array
}
export const encode = ( json: { [key: string]: any }):string => {
    const messageUint8 = decodeUTF8(JSON.stringify(json));
    return encodeBase64(messageUint8)
}
export const decode = ( data: string) => {
    const messageUint8 = decodeBase64(data)
    const base64Message = encodeUTF8(messageUint8);
    return JSON.parse(base64Message);
}


export const encrypt = (
    {
        secretOrSharedKey,
        json,
        key,
        senderPublicKey,
        nonce,
    }: EncryptI) => {
    if (!nonce) nonce = newNonce();
    console.log(nonce)
    const messageUint8 = decodeUTF8(JSON.stringify(json));

    if (!senderPublicKey || !secretOrSharedKey) {
        let ek = generateKeyPair()
        senderPublicKey = ek.publicKey
        secretOrSharedKey = ek.secretKey
    }

    const encrypted = key
        ? box(messageUint8, nonce, key, secretOrSharedKey)
        : box.after(messageUint8, nonce, secretOrSharedKey);

    //todo destroy ek.secretKey from memory

    // this was for concatenating nonce and message to return a string
    //const fullMessage = new Uint8Array(nonce.length + encrypted.length);
    //fullMessage.set(nonce);
    //fullMessage.set(encrypted, nonce.length);
    // const base64FullMessage = encodeBase64(fullMessage);

    // now we return an object

    let msg: CypherMsgI = {
        nonce: encodeBase64(nonce),
        cypherText: encodeBase64(encrypted),
        senderPublicKey: senderPublicKey
    };
    return msg
};
export const decrypt = (
    secretOrSharedKey: Uint8Array,
    msg: CypherMsgI,
    key?: Uint8Array
) => {

    //const messageWithNonceAsUint8Array = decodeBase64(messageWithNonce);

    const nonce = decodeBase64(msg.nonce) //messageWithNonceAsUint8Array.slice(0, box.nonceLength);
    const message = decodeBase64(msg.cypherText)
    if (!key) key = msg.senderPublicKey
   /* console.log("decrypt function //////// ")
    console.log("secretKey", asHexAddress(secretOrSharedKey))
    console.log('public key', asHexAddress(key))*/


    const decrypted = key
        ? box.open(message, nonce, key, secretOrSharedKey)
        : box.open.after(message, nonce, secretOrSharedKey);

    if (!decrypted) {
        console.log('Could not decrypt message')
        //throw new Error('Could not decrypt message');
        return
    }

    const base64DecryptedMessage = encodeUTF8(decrypted);
    //console.log("BEFORE JSON PARSE", base64DecryptedMessage)
    return JSON.parse(base64DecryptedMessage);
};
export const fromHexString = (hexString: string) => {
    // @ts-ignore
    return Uint8Array.from(hexString.match(/.{1,2}/g).map((byte) => parseInt(byte, 16)));
}
export const toHexString = (bytes: any): string => {
    if (!bytes?.reduce) return ""
    //@ts-ignore
    return bytes.reduce((str, byte) => str + byte.toString(16).padStart(2, '0'), '');
}

export const asHexAddress = (bytes: any): string => {
    if (!bytes?.reduce) return ""
    return `0x${toHexString(bytes)}`
}


export function hexStringToUint8Array(hexString: string) {
    hexString = hexString.slice(2, hexString.length)
    if (hexString.length % 2 !== 0) {
        throw "Invalid hexString";
    }
    var arrayBuffer = new Uint8Array(hexString.length / 2);

    for (var i = 0; i < hexString.length; i += 2) {
        var byteValue = parseInt(hexString.substr(i, 2), 16);
        if (isNaN(byteValue)) {
            throw "Invalid hexString";
        }
        arrayBuffer[i / 2] = byteValue;
    }

    return arrayBuffer;
}


export const generateRandomHexId = (length: number) => {

    const randomHex = randomAsHex(Math.ceil(length / 2));
    //randomAsU8a()

    return randomHex //.slice(0, length);
}

//
// ed25519 & curve25519
//
export const ConvertEdPublic2CurvePublic = (addr: string) => {
    let data = hexStringToUint8Array(addr)
    const curvePublic = ed2curve.convertPublicKey(data)
   // console.log("hex",toHexString(curvePublic))
    return curvePublic
}

export const ConvertEdSecret2CurvePair = (seedPhrase: string) => {
    try {
        const secret = mnemonicToMiniSecret(seedPhrase);
        const curvePrivate = ed2curve.convertSecretKey(secret)
        //const pairSigner = sign.keyPair.fromSeed(secret)
        //const curve = ed2curve.convertKeyPair(pairSigner) //box keypair
        const pair = box.keyPair.fromSecretKey(curvePrivate)
        //console.log('secret',toHexString(pair?.secretKey))
        // console.log('public',toHexString(pair?.publicKey))
        return pair
    } catch (err) {
        console.log("no valid seed for converting")
    }
}


export function d20RollsToPrivateKey(rolls: number[]): bigint {
    if (rolls.length !== 60) {
        throw new Error('Exactly 29 d20 rolls are required');
    }

    let privateKeyBigInt = BigInt(0);

    for (const roll of rolls) {
        if (roll < 1 || roll > 20) {
            throw new Error('All rolls must be between 1 and 20');
        }
        privateKeyBigInt = privateKeyBigInt * BigInt(20) + BigInt(roll - 1);
    }

    return privateKeyBigInt;
}

/*
// Example usage:
 const d20Rolls = [
      5,  12, 20, 3,  15, 10, 19, 17, 7,  8,
      11, 1,  4,  14, 2,  6,  18, 16, 9,  13,
      20, 11, 6,  10, 1,  18, 8,  15, 17, 5,
      12, 19, 7,  3,  13, 9,  14, 4,  16, 2,
      10, 7,  19, 4,  16, 1,  11, 14, 5,  8,
      18, 2,  13, 6,  9,  15, 20, 3,  12, 17
    ];
    const privateKey = d20RollsToPrivateKey(d20Rolls);

    console.log('Private key as a bigint:', privateKey);
    console.log('Private key as a hex string:', privateKey.toString(16).padStart(64, '0'));
    let kp=ed25519PairFromSeed(hexStringToUint8Array(toHexString(privateKey)))
    console.log(kp)

 */

// todo sub addresses

/*

const handlePostMessage = async (e: any) => {
    e.preventDefault()
    if (receiver) {
        let enc = encrypt({
            secretOrSharedKey: sender?.secretKey,
            json: {
                message: form.formData.messageText,
            },
            key: receiver.publicKey,
            senderPublicKey: sender?.publicKey,
        })
        setMessages([
            ...messages,
            enc
        ])
    }
}

*/
