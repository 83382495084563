import {ImageBackground, ScrollView, TextInput, TouchableHighlight} from 'react-native';
import {Flex, Text, View} from '../../components/Themed';
import React, {useEffect, useRef, useState} from "react";
import {useForm} from "../../hooks/useForm";
import {styles} from "../../constants/Styles";
import {mnemonicGenerate, mnemonicToMiniSecret, ed25519PairFromSeed} from "@polkadot/util-crypto";
import {useBlockchain} from "../../diamond-realm-api/hooks/useBlockchain";
import useWallet from "../../diamond-realm-api/hooks/useWallet";
import {box, BoxKeyPair} from "tweetnacl";
import ButtonText from "../../components/ButtonText";
import DisplayKey from "../../components/Keys/DisplayKey";
import {d20RollsToPrivateKey, hexStringToUint8Array, toHexString} from "../../diamond-realm-api/crypto";

export default function GenerateKey() {
  const blockchain = useBlockchain()
  const wallet = useWallet()
  const [secretKeyPair, setSecretKeyPair] = useState<BoxKeyPair>()
  const [phrase, setPhrase] = useState<string>("")

  const initForm = {
    keyInput: ""
  }
  const form = useForm({
    ...initForm,
  })
  const input_ref = useRef<TextInput | null>(null);

  useEffect(() => {

  })

  // end of hooks

  const handleGenerateSeed = async (e: any) => {
    e.preventDefault()

    let p = mnemonicGenerate(12)
    const seedFromMnemonic = mnemonicToMiniSecret(p);
    // this is curve25519 const keypairBox = box.keyPair.fromSecretKey(seedFromMnemonic)
    //we use ed25519 for signing
    const keypair = ed25519PairFromSeed(seedFromMnemonic);
    setSecretKeyPair(keypair)
    setPhrase(p)
  }

  const handleSetLocalKey = (e: any) => {
    e.preventDefault()
    if (!secretKeyPair) return
    wallet.setLocalWallet({
      secretKey: secretKeyPair.secretKey,
      address: secretKeyPair.publicKey,
      seedPhrase: phrase,
    })
  }


// if (isLoading) return <span>Loading ...</span>;
  return (
    <View style={styles.container}>
      <View style={{
        padding: 12,
        width: "100%"
      }}>
        <View>
          <ButtonText disabled={false} onPress={e => handleGenerateSeed(e)}>Generate New Random Seed Phrase</ButtonText>
        </View>
        {phrase && (
          <>
            {secretKeyPair && (
              <DisplayKey localWallet={{
                address: secretKeyPair.publicKey,
                secretKey: secretKeyPair.secretKey,
                seedPhrase: phrase,
              }}/>
            )}
            <ButtonText disabled={false} onPress={e => handleSetLocalKey(e)}>Use this new Key in your Wallet</ButtonText>
          </>
        )}

      </View>
    </View>

  );
}

