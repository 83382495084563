import {ImageBackground, ScrollView, TextInput, TouchableHighlight} from 'react-native';


import {Flex, FormLabel, Text, View} from '../components/Themed';

import React, {useEffect, useRef, useState} from "react";

import {CONSTANTS, styles} from "../constants/Styles";

import useWallet from "../diamond-realm-api/hooks/useWallet";

import {
    asHexAddress,
    ConvertEdPublic2CurvePublic,
    ConvertEdSecret2CurvePair,
    encrypt,
    toHexString
} from '../diamond-realm-api/crypto';

import {useForm} from "../hooks/useForm";
import {useBlockchain} from "../diamond-realm-api/hooks/useBlockchain";
import Loading from "../components/Loading";
import {Contact, ContactHeader, FileHeaderI, FileI, FolderHeaderI} from "../diamond-realm-api/interfaces";
import useInterval from "../hooks/useInterval";
import {FontAwesomeIcon} from "@fortawesome/react-native-fontawesome";
import Colors from "../constants/Colors";
import {faContactBook, faFolder, faContactCard, faHome, faAddressCard} from "@fortawesome/free-solid-svg-icons";
import ButtonUi from "../components/ButtonUi";
import AccountNickText from "../components/AccountNickText";
import DirectoryView from "../components/DirectoryView";
import {GetAllNameIds, NameInfo} from "../diamond-realm-api/nameService";
import OwnedNames from "./OwnedNames";

export default function DirectoryScreen() {
    const blockchain = useBlockchain()
    const input_ref = useRef<TextInput | null>(null);
    const [folders, setFolders] = useState<FolderHeaderI[]>([])
    const [nicks, setNicks] = useState<NameInfo[]>([])
    const [viewEntry, setViewEntry] = useState("")

    useEffect(() => {
        refresh()
    }, [blockchain.api])


    useInterval(() => {
        refresh()
    }, 8000)

    // end of hooks

    const refresh = async () => {
        handleGetAllNicks();
    }

    const handleGetAllNicks = async () => {
        if (!blockchain.api) return
        let data = await GetAllNameIds(blockchain.api)
        data && setNicks(data)
    }

    return (
        <View style={styles.container}>
            <ScrollView
                keyboardShouldPersistTaps={'handled'}
                style={{
                    width: "100%",
                    padding: 12,
                    paddingBottom: 100,
                    //maxWidth: CONSTANTS.pageWidth,
                }}>
                <View style={{
                    alignItems: "center",
                    flex: 1,
                }}>
                    <View style={{
                        maxWidth: CONSTANTS.pageWidth,
                        flex: 1,
                        width: "100%",
                    }}>
                        <View style={{}}>
                            <View>
                                <Text style={styles.headerHeavy}>
                                    Your Account
                                </Text>
                            </View>
                        </View>
                        <OwnedNames/>

                        <View style={{marginTop: 12, marginBottom: 12}}>
                            <View>
                                <Text style={styles.headerHeavy}>
                                    Public Directory
                                </Text>
                            </View>
                            {nicks.map((nick, i) => (
                                <View key={"dir" + i}>
                                    <Flex>
                                        <ButtonUi selected={viewEntry === nick.owner} minimal={true} disabled={false} onPress={() => {
                                            if (viewEntry === nick.owner) {
                                                setViewEntry("")
                                            } else {
                                                nick.owner && setViewEntry(nick.owner)
                                            }

                                        }}>
                                            <Flex style={{alignItems: "center", flexWrap: "wrap"}}>
                                                {/*<FontAwesomeIcon color={Colors.dark.text} icon={faAddressCard}
                                                                 style={{marginRight: 6}}/>*/}
                                                {nick.owner && <AccountNickText address={nick.owner}/>}
                                            </Flex>
                                        </ButtonUi>
                                    </Flex>

                                    {(viewEntry === nick.owner) &&
                                        <View style={{
                                            flex: 1,
                                        }}>
                                            <DirectoryView address={nick.owner}/>
                                        </View>
                                    }

                                </View>
                            ))}
                        </View>

                    </View>
                </View>

            </ScrollView>
        </View>

    );
}

