///
/// Contacts
///

import {ApiPromise} from "@polkadot/api";

import {LocalWallet} from "./hooks/useWallet";
import {BoxKeyPair} from "tweetnacl";
import {Contact, FileHeaderI, FolderHeaderI, Message, MessageHeader} from "./interfaces";
import {
    asHexAddress,
    ConvertEdPublic2CurvePublic,
    CypherMsgI, decrypt,
    DiamondKeyring,
    encrypt, fromHexString,
    newNonce,
    toHexString
} from "./crypto";
import {blake2AsHex} from "@polkadot/util-crypto";
import Toast from "react-native-toast-message";
import {ParseResultEvents, ToastTx} from "../components/Toast";

export const CreateMessage = async (
    api: ApiPromise,
    localWallet: LocalWallet,
    curvePair: BoxKeyPair,
    address: string,
    id: string,
    json: Message,
    callback?: () => any,
    //setSubmitting?: (t: boolean) => any,
) => {
    Toast.show({
        type: 'info',
        text1: `Processing`,
        text2: `Transaction is being processed on chain`
    });
    const idHash = blake2AsHex(id)
    const nonce = newNonce();
    const nonceSent = newNonce();

    let edCurvePublic = ConvertEdPublic2CurvePublic(address)
    console.log("create", idHash, "curveAdddress", asHexAddress(edCurvePublic), "json", json)

    if (!edCurvePublic) {
        throw Error("No public curve")
    }

    let encData = encrypt({
        secretOrSharedKey: curvePair.secretKey,
        json,
        key: edCurvePublic,
        senderPublicKey: curvePair.publicKey,
        nonce,
    })
    console.log("cypher message object", encData)

    let encDataSent = encrypt({
        secretOrSharedKey: curvePair.secretKey,
        json,
        key: curvePair.publicKey,
        senderPublicKey: curvePair.publicKey,
        nonce: nonceSent,
    })
    console.log("sent cypher message object", encDataSent)

    let header: MessageHeader = {
        id: idHash,
        subject: json.subject,
        sender: asHexAddress(localWallet.address),
        recipient: address 
    }

    let encHeader = encrypt({
        secretOrSharedKey: curvePair.secretKey,
        json: header,
        key: edCurvePublic,
        senderPublicKey: curvePair.publicKey,
        nonce,
    })

    console.log("header", toHexString(encHeader.nonce), "data", toHexString(encData.nonce))

    let encHeaderSent = encrypt({
        secretOrSharedKey: curvePair.secretKey,
        json: header,
        key: curvePair.publicKey,
        senderPublicKey: curvePair.publicKey,
        nonce: nonceSent,
    })

    console.log("sent header", toHexString(encHeaderSent.nonce), "data", toHexString(encDataSent.nonce))
    let kp = DiamondKeyring.addFromUri(localWallet.seedPhrase)

    await api.tx.messages.createMessage(
        address,
        idHash,
        encHeader.cypherText,
        encData.cypherText,
        encData.nonce,
        encHeaderSent.cypherText,
        encDataSent.cypherText,
        encDataSent.nonce,
    ).signAndSend(kp, (res) => {
        console.log(res)
        ToastTx(res)
        ParseResultEvents(api,res)
        if (res.status.isInBlock) {
            callback && callback()
        }
    })

    return idHash

}


export const GetMessages = async (
    api: ApiPromise,
    curvePair: BoxKeyPair,
    address: string,
) => {

    let res = await api.query.messages.messageHeaders.entries(address)
  //  console.log("Get Messages ", address, res)

    if (!res) {
        console.log("no storage found for that hash key")
        return
    }
    let headers: MessageHeader[] = []

    res.forEach(([key, header]) => {
        let k = key.args[1].toString()

        let h = header.unwrap()
       // console.log("header", h)
        //  console.log('key arguments:', key.args.map((k) => k.toHuman()));
        //  console.log('     file:', fileHeader.toHuman(), h);
        const senderCurve = ConvertEdPublic2CurvePublic(asHexAddress(h.sender))
        if (!senderCurve) {
            throw new Error("no sender curve")
        }

        let msg: CypherMsgI = {
            nonce: h.nonce.toUtf8(), //.toString(),
            cypherText: h.header.toUtf8(), //.toString(),
            senderPublicKey: senderCurve,
        }

        let d: MessageHeader = decrypt(curvePair.secretKey, msg)
        let date:string = ""
        if (h.created) {
            date = h.created.toString()
          //  console.log("date",date)

        }

        if (d) {
            if (date) {
                d.date = parseInt(date)
            }
            d.id = k
            d.nonce = msg.nonce
           //     console.log("decrypted data from the chain", d)
            headers.push(d)
        }
// "5EMnAuytxcm8Laz9mjSKn1kGfBuDL4ah1FFy5T1QPJRfrFjX" arg 0

    });

    return headers
}


export const GetMessage = async (
    api: ApiPromise,
    curvePair: BoxKeyPair,
    messageHeader: MessageHeader,
) => {
    //console.log("Get Message from header", messageHeader)
    let res = await api.query.messages.messages(messageHeader.id)
    if (res.isEmpty) {
        console.log("no storage found for that hash key")
        return
    }
    let data = res.unwrap()
 //   console.log("Get Message", data)
    const senderCurve = ConvertEdPublic2CurvePublic(messageHeader.sender)
    if (!senderCurve) {
        throw new Error("no sender curve")
    }
    let msg: CypherMsgI = {
        nonce: messageHeader.nonce, //.toString(),
        cypherText: data.data.toUtf8(), //.toString(),
        senderPublicKey: senderCurve,
    }
    let d: Message = decrypt(curvePair.secretKey, msg,)

    let m: Message = {
        ...messageHeader,
    }

    if (d) {
        m.message = d.message
        console.log("decrypted data from the chain", d)

    }
    return m
}



/// sent


export const GetSentMessages = async (
    api: ApiPromise,
    curvePair: BoxKeyPair,
    address: string,
) => {

    let res = await api.query.messages.sentMessageHeaders.entries(address)
    //  console.log("Get Messages ", address, res)

    if (!res) {
        console.log("no storage found for that hash key")
        return
    }
    let headers: MessageHeader[] = []

    res.forEach(([key, header]) => {
        let k = key.args[1].toString()

        let h = header.unwrap()
        // console.log("header", h)
        //  console.log('key arguments:', key.args.map((k) => k.toHuman()));
        //  console.log('     file:', fileHeader.toHuman(), h);
        const senderCurve = ConvertEdPublic2CurvePublic(asHexAddress(h.sender))
        if (!senderCurve) {
            throw new Error("no sender curve")
        }

        let msg: CypherMsgI = {
            nonce: h.nonce.toUtf8(), //.toString(),
            cypherText: h.header.toUtf8(), //.toString(),
            senderPublicKey: senderCurve,
        }

        let d: MessageHeader = decrypt(curvePair.secretKey, msg)
        let date:string = ""
        if (h.created) {
            date = h.created.toString()
            //  console.log("date",date)

        }

        if (d) {
            if (date) {
                d.date = parseInt(date)
            }
            d.id = k
            d.nonce = msg.nonce
            //     console.log("decrypted data from the chain", d)
            headers.push(d)
        }
// "5EMnAuytxcm8Laz9mjSKn1kGfBuDL4ah1FFy5T1QPJRfrFjX" arg 0

    });

    return headers
}


export const GetSentMessage = async (
    api: ApiPromise,
    curvePair: BoxKeyPair,
    messageHeader: MessageHeader,
) => {
    //console.log("Get Message from header", messageHeader)
    let res = await api.query.messages.sentMessages(messageHeader.id)
    if (res.isEmpty) {
        console.log("no storage found for that hash key")
       return
    }
    let data = res.unwrap()
    //   console.log("Get Message", data)
    const senderCurve = ConvertEdPublic2CurvePublic(messageHeader.sender)
    if (!senderCurve) {
        throw new Error("no sender curve")
    }
    let msg: CypherMsgI = {
        nonce: messageHeader.nonce, //.toString(),
        cypherText: data.data.toUtf8(), //.toString(),
        senderPublicKey: senderCurve,
    }
    let d: Message = decrypt(curvePair.secretKey, msg,)

    let m: Message = {
        ...messageHeader,
    }

    if (d) {
        m.message = d.message
        console.log("decrypted data from the chain", d)

    }
    return m
}

