import {Button, Image, ImageBackground, ScrollView, TextInput, TouchableHighlight} from 'react-native';
import {Flex, FormLabel, Text, View} from '../Themed';
import React, {useEffect, useRef, useState} from "react";
import {useForm} from "../../hooks/useForm";
import {CONSTANTS, styles} from "../../constants/Styles";
import Colors from "../../constants/Colors";

import {ChatCompletionResponseMessage} from "openai";
import {

    faMoneyBill1Wave as faAsset
} from "@fortawesome/free-solid-svg-icons"
import {FontAwesomeIcon,} from '@fortawesome/react-native-fontawesome';
import ButtonText from '../ButtonText';
import {randomId} from "../../utils/rando";

import {useBlockchain} from "../../diamond-realm-api/hooks/useBlockchain";
import useWallet from "../../diamond-realm-api/hooks/useWallet";
import Loading from "../Loading";
import {encodeAddress} from "@polkadot/keyring";
import {FormatDecimalAmount, FormatNumber} from "../../utils/NumbersUtils";
import AccountNickText from "../AccountNickText";
import {CreateToken, UpdateMetadata} from "../../diamond-realm-api/tokens";
import TokenListItem from "./TokenListItem";
import {amountToSats, asHexAddress} from "../../diamond-realm-api/crypto";
import Toast from "react-native-toast-message";
import useTokens from "../../diamond-realm-api/hooks/useTokens";
import {getDocumentAsync} from "expo-document-picker";

interface props {
    id: string,
    onFinish?: () => any
}

const EditMetaData = (
    {
        id,
        onFinish,
    }: props
) => {
    const {accounts, metadata, subscribeToken} = useTokens()
    const account = accounts[id] && accounts[id]
    const md = metadata[id] && metadata[id]
    const [showUpload, setShowUpload] = useState(false)

    const initForm = {
        name: "",
        symbol: "",
        decimals: "12",
    }
    const form = useForm(initForm)
    const blockchain = useBlockchain()
    const wallet = useWallet()

    useEffect(() => {
        if (md) {
            form.setFormData({
                //...form.formData,
                name: md.name,
                symbol: md.symbol,
                decimals: md.decimals,
            })
        }
    }, [md])
    const input_ref = useRef<TextInput | null>(null);
    useEffect(() => {

    }, [])

    useEffect(() => {
        //  work()
    }, [blockchain.api, wallet.localWallet])

    // end of hooks // //////////////////////////////////////////////

    const handleUpdateMetadata = async () => {
        if (form.formData.symbol.length < 2) {
            Toast.show({
                type: 'error',
                text1: `Symbol must be at least 2 characters`,
                //text2: `Transaction is being processed on chain`
            });
            return
        }
        form.setSubmitting(true)
        console.log("update token", form.formData.name)
        if (blockchain.api && wallet.localWallet && wallet.address) {
            let image = form.formData.uploadFileUri ? form.formData.uploadFileUri : null
            let res = await UpdateMetadata(
                blockchain.api,
                wallet.localWallet,
                id,
                form.formData.name,
                form.formData.symbol,
                form.formData.decimals,
                image,
                () => {
                    form.setSubmitting(false)
                    onFinish && onFinish()
                    //  form.setFormData(initForm)
                }
            )
            if (res) {
                //form.resetFormData(initForm)
            }


        }
    }

    const handleDocumentPick = async () => {
        setShowUpload(true)
        let res = await getDocumentAsync({
            //type: "image/*"
        })
        if (res.output && res.output[0] && res.assets && res.assets[0]) {
            form.setFormData({
                ...form.formData,
                uploadFileUri: res.assets[0].uri,
                uploadFileName: res.output[0].name,
                uploadFileSize: res.output[0].size,
                //  fileName: res.output[0].name,
            })
        }
        console.log("pick file", res)
    }

    <ButtonText selected={showUpload} minimal={true} style={{}} disabled={false} onPress={() => {
        showUpload ? setShowUpload(false) : handleDocumentPick()
    }}>
        Image
    </ButtonText>


    return (

        <View style={styles.container}>
            <ScrollView
                keyboardShouldPersistTaps={'handled'}
                style={{
                    //alignItems: "center",
                    // backgroundColor: Colors.dark.backgroundAlpha,
                    width: "100%",
                    padding: 12,
                    paddingBottom: 100,
                    maxWidth: CONSTANTS.pageWidth,
                }}>
                <View style={{
                    // alignItems: "center",
                    flex: 1,
                }}>
                    <Flex style={{
                        //flex: 1,
                        justifyContent: "space-between",
                        marginBottom: "2%",
                    }}>
                        <Flex style={{
                            flex: 1,
                            //justifyContent: "flex-end",
                        }}>

                        </Flex>
                    </Flex>

                    <View
                        style={{
                            flex: 1,
                            //flexDirection: "column",
                            marginBottom: 12,
                            marginTop: 12,
                            maxWidth: CONSTANTS.pageWidth,
                            width: "100%",
                        }}
                    >
                        <Text style={[styles.header, styles.titleTop]}>
                            Update Token Metadata
                        </Text>

                        <View //flexDirection={"column"} flexGrow={1}
                            style={{
                                minWidth: 60,
                            }}
                        >
                            <FormLabel>
                                Name
                            </FormLabel>
                            <TextInput
                                ref={input_ref}
                                style={styles.input}
                                placeholder=""
                                autoFocus={false}
                                value={form.formData.name}
                                onChangeText={form.handleChange("name")}
                                //onSubmitEditing={handleSubmit}
                            />

                            <FormLabel>
                                Symbol
                            </FormLabel>
                            <TextInput
                                //ref={input_ref}
                                style={styles.input}
                                placeholder=""
                                autoFocus={false}
                                value={form.formData.symbol}
                                onChangeText={form.handleChange("symbol")}
                                //onSubmitEditing={handleCreateToken}
                            />

                            <FormLabel>
                                Decimals
                            </FormLabel>
                            <TextInput
                                //ref={input_ref}
                                style={styles.input}
                                //placeholder="12"
                                autoFocus={false}
                                value={form.formData.decimals}
                                onChangeText={form.handleChange("decimals")}
                                onSubmitEditing={handleUpdateMetadata}
                            />

                            <ButtonText selected={showUpload} minimal={true} style={{}} disabled={false}
                                        onPress={() => {
                                            showUpload ? setShowUpload(false) : handleDocumentPick()
                                        }}>
                                New Image
                            </ButtonText>
                            {form.formData.uploadFileUri &&
                                <View style={{

                                }}>
                                    {/*<img src={form.formData.uploadFileUri}/>*/}
                                    <Image style={{marginRight: 12}} source={
                                        {
                                            width: 72,
                                            height: 72,
                                            uri: form.formData.uploadFileUri
                                        }
                                    }/>
                                </View>
                            }
                        </View>
                        {form.formData.mint && form.formData.decimals && wallet.address && (
                            <Flex style={{
                                justifyContent: "center"
                            }}>
                                <Text>
                                    {`Mint ${FormatDecimalAmount(parseInt((parseFloat(form.formData.mint) * Math.pow(10, parseInt(form.formData.decimals))).toFixed(0)), parseInt(form.formData.decimals), parseInt(form.formData.decimals))} to `}

                                </Text>

                                <AccountNickText address={wallet.address}/>

                            </Flex>


                        )}
                        <View style={{marginVertical:12}}>
                            <ButtonText disabled={form.submitting || !form.formData.symbol} onPress={() => {
                                handleUpdateMetadata()
                            }}>
                                {form.submitting ? <Loading/> : "Update Metadata"}
                            </ButtonText>
                        </View>

                        <View>
                            <Text style={styles.headerHeavy}>Asset ID: {id}</Text>
                            <TokenListItem id={id}/>
                        </View>


                    </View>


                </View>
            </ScrollView>

        </View>

    );
}

export default EditMetaData