import {FontAwesome} from '@expo/vector-icons';

import {FontAwesomeIcon} from '@fortawesome/react-native-fontawesome'
import {createBottomTabNavigator} from '@react-navigation/bottom-tabs';
import {NavigationContainer, DefaultTheme, DarkTheme} from '@react-navigation/native';
import {createNativeStackNavigator} from '@react-navigation/native-stack';
import * as React from 'react';
import {ColorSchemeName, ImageBackground, Pressable, Text, View} from 'react-native';

import Colors from '../constants/Colors';
import useColorScheme from '../hooks/useColorScheme';
import ModalScreen from '../screens/ModalScreen';
import NotFoundScreen from '../screens/NotFoundScreen';
import WalletScreen from '../screens/WalletScreen';

import {RootStackParamList, RootTabParamList, RootTabScreenProps} from '../types';
import LinkingConfiguration from './LinkingConfiguration';
import {DARK_THEME, LIGHT_THEME} from "../constants/Themes";
import MessagingScreen from "../screens/MessagingScreen";
import {CONSTANTS, styles} from "../constants/Styles";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {
    faEnvelope,
    faFile,
    faKey,
    faAddressBook,
    faFileInvoiceDollar,
    faWallet,
    faUsers
} from "@fortawesome/free-solid-svg-icons"


import DaoScreen from "../screens/DaoScreen";
import FilesScreen from "../screens/FilesScreen";
import SettingsScreen from "../screens/SettingsScreen";
import Die from "../components/Die";
import LoadKey from "../screens/keys/LoadKey";

import RolodexScreen from '../screens/RolodexScreen';
import DirectoryScreen from "../screens/DirectoryScreen";
import AccountMenu from "../components/AccountMenu";

export default function Navigation({colorScheme}: { colorScheme: ColorSchemeName }) {
    return (
        <NavigationContainer
            linking={LinkingConfiguration}
            theme={colorScheme === 'dark' ? DARK_THEME : LIGHT_THEME}>

            <RootNavigator/>
        </NavigationContainer>
    );
}

/**
 * A root stack navigator is often used for displaying modals on top of all other content.
 * https://reactnavigation.org/docs/modal
 */
const Stack = createNativeStackNavigator<RootStackParamList>();

function RootNavigator() {
    return (
        <Stack.Navigator>
            <Stack.Screen name="Root" component={BottomTabNavigator} options={{headerShown: false}}/>

            <Stack.Screen name="NotFound" component={NotFoundScreen} options={{title: 'Oops!'}}/>
            <Stack.Group screenOptions={{presentation: 'modal'}}>
                <Stack.Screen name="Modal" component={ModalScreen}/>
            </Stack.Group>
        </Stack.Navigator>

    );
}

/**
 * A bottom tab navigator displays tab buttons on the bottom of the display to switch screens.
 * https://reactnavigation.org/docs/bottom-tab-navigator
 */
const BottomTab = createBottomTabNavigator<RootTabParamList>();

function BottomTabNavigator() {
    let colorScheme = useColorScheme();
    colorScheme = "dark"

    return (
        <BottomTab.Navigator
            initialRouteName="Wallet"
            screenOptions={{
                headerLeft: () => (
                    <View>
                        <Text style={styles.logo}></Text>
                    </View>
                ),
                headerRight: () => (
                    <View style={{margin: 12}}>
                        <AccountMenu/>
                    </View>
                ),
                tabBarActiveTintColor: Colors[colorScheme].tint,
                tabBarStyle: {
                    height: CONSTANTS.navBarHeight,
                    opacity: 1,
                    paddingHorizontal: 5,
                    paddingTop: 0,
                    paddingBottom: 16,
                    backgroundColor: Colors.dark.background,
                    position: 'absolute',
                    borderTopWidth: 0,
                },
                headerStyle: styles.headerStyle,
            }}
        >


            <BottomTab.Screen
                name="Wallet"
                component={WalletScreen}
                options={{
                    //  title: 'Wallet',
                    //todo style this
//          tabBarIcon: ({color}) => <TabBarIcon name="comments" color={color}/>,
                    tabBarIcon: ({color}) => <FontAwesomeIcon
                        size={30}
                        color={color}
                        style={{}} icon={faWallet}/>,
                    headerShown: false
                }}
            />
            <BottomTab.Screen
                name="Messages"
                component={MessagingScreen}
                //  initialParams={{Messages: { to: ""} }}
                options={{
                    //  title: 'Messaging',
                    //todo style this
//          tabBarIcon: ({color}) => <TabBarIcon name="comments" color={color}/>,
                    tabBarIcon: ({color}) => <FontAwesomeIcon
                        size={30}
                        color={color}
                        style={{}} icon={faEnvelope}/>,
                    //headerShown: false
                }}
            />
            <BottomTab.Screen
                name="Dao"
                component={DaoScreen}
                options={{
                    title: 'Contracts',
                    tabBarIcon: ({color}) => <FontAwesomeIcon
                        size={30}
                        color={color}
                        style={{}} icon={faFileInvoiceDollar}/>,
                    // headerShown: false
                }}
            />

            <BottomTab.Screen
                name="Files"
                component={FilesScreen}
                options={{
                    title: 'Files',
                    tabBarIcon: ({color}) => <FontAwesomeIcon
                        size={30}
                        color={color}
                        style={{}} icon={faFile}/>,
                    // headerShown: false
                }}
            />


            <BottomTab.Screen
                name="Rolodex"
                component={RolodexScreen}
                options={{
                    title: 'Rolodex',
                    tabBarIcon: ({color}) => <FontAwesomeIcon
                        size={30}
                        color={color}
                        style={{}} icon={faAddressBook}/>,
                    // headerShown: false
                }}
            />


            <BottomTab.Screen
                name="Directory"
                component={DirectoryScreen}
                options={{
                    title: 'Directory',
                    tabBarIcon: ({color}) => <FontAwesomeIcon
                        size={30}
                        color={color}
                        style={{}} icon={faUsers}/>,
                    //headerShown: false
                }}
            />


            <BottomTab.Screen
                name="Account"
                component={SettingsScreen}
                options={{
                    title: 'Account',
                    tabBarIcon: ({color}) => <FontAwesomeIcon
                        size={30}
                        color={color}
                        style={{}} icon={faKey}/>,
                    headerShown: false
                }}
            />


        </BottomTab.Navigator>

    );
}
