import React, {useEffect, useState} from "react";
import {AddressShort} from "../utils/addressUtils"
import {View, Flex, Text} from "./Themed";
import useNameService from "../diamond-realm-api/hooks/useNameService";


const {
    u8aToHex
} = require('@polkadot/util');


interface props {
    address?: string
}

const AccountNickText = ({address}: props) => {
    const {nick} = useNameService({address})

    if (!address) {
        return null
    } else {
        return (
            <Text>
                @<strong>{`${nick}`}</strong>{` ${AddressShort(address)}`}
            </Text>
        )
    }
}

export default AccountNickText