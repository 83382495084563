export const ImageDimensions = (image?: string) => {
    let w = 0
    let h = 0
    if (image) {
        let memoryImg = document.createElement('img');
        memoryImg.src = image;
        w = memoryImg.width
        h = memoryImg.height
    }

    return {
        w, h
    }

}