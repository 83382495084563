import {faCopy} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import {View, Text} from "./Themed";
import {FontAwesomeIcon} from "@fortawesome/react-native-fontawesome";
import Colors from "../constants/Colors";
import {TouchableHighlight} from "react-native";
import { styles } from "../constants/Styles";

interface props {
  text?: string
  marginRight?: string,
    copyText?:string,
}

const ClipboardButton = ({text, marginRight,copyText}: props) => {

  return (
    <TouchableHighlight
      underlayColor={Colors.dark.buttonUnderlay}
      disabled={false}
      onPress={() => {
          copyText ? navigator.clipboard.writeText(
              copyText,
        ): navigator.clipboard.writeText(
              text || "",
          )
      }}>
      <View
        //cursor={"copy"}
        style={{
          marginRight: marginRight,
        }}
      >
        <Text numberOfLines={3} style={styles.item}>
        {text}
        </Text>
        {/*<FontAwesomeIcon color={Colors.dark.text} icon={faCopy}/>*/}
      </View>
    </TouchableHighlight>
  )
}
export default ClipboardButton