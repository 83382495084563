import {ImageBackground, ScrollView, TextInput, TouchableHighlight} from 'react-native';

import {Flex, FormLabel, Text, View} from '../components/Themed';
import React, {useEffect, useRef, useState} from "react";
import {CONSTANTS, styles} from "../constants/Styles";
import ButtonText from '../components/ButtonText';
import useWallet from "../diamond-realm-api/hooks/useWallet";

import {useForm} from "../hooks/useForm";
import {useBlockchain} from "../diamond-realm-api/hooks/useBlockchain";
import Loading from "../components/Loading";
import {
    Contact,
    ContactHeader,
    FileHeaderI,
    FileI,
    FolderHeaderI,
    Message,
    MessageHeader
} from "../diamond-realm-api/interfaces";
import useInterval from "../hooks/useInterval";
import {FontAwesomeIcon} from "@fortawesome/react-native-fontawesome";
import Colors from "../constants/Colors";
import {
    faPlus,
    faEnvelope,
    faEnvelopeOpenText as faEnvelopeNew,
    faContactCard,
    faHome, faAddressBook
} from "@fortawesome/free-solid-svg-icons";
import {v4 as uuidv4} from 'uuid';
import {CreateMessage, GetMessages, GetSentMessages} from "../diamond-realm-api/messages";
import MessageView from "../components/Messages/MessageView";
import {AddressShort} from "../utils/addressUtils";
import AccountNickText from "../components/AccountNickText";
import ButtonUi from "../components/ButtonUi";
import RolodexScreen from "./RolodexScreen";
import ContactsPicker from '../components/Contacts/ContactsPicker';
import {blockTimeStampToDate} from "../utils/TimeDate";

import init, * as paillier_wasm from "../diamond-realm-api/paillier-rust/paillier_rust";
import {randomAsU8a} from "@polkadot/util-crypto";
import {u128} from "@polkadot/types-codec";
import {NativeStackScreenProps} from "@react-navigation/native-stack";
import {MessagesStackParamList} from "../types";
import AddressContactsInput from "../components/AddressContactsInput";
import AccountSenderListItem from "../components/AccountSenderListItem";
import MessageListItem from "../components/Messages/MessageListItem";
//import BN from "bn.js";
const BN = require('bn.js');

const MessageScreen = ({route}: NativeStackScreenProps<MessagesStackParamList>) => {
    const blockchain = useBlockchain()
    const wallet = useWallet()
    const input_ref = useRef<TextInput | null>(null);

    const [displayAccount, setDisplayAccount] = useState();

    const [messages, setMessages] = useState<MessageHeader[]>([])
    const [viewMessageHeader, setViewMessageHeader] = useState<MessageHeader>()

    const [sentMessages, setSentMessages] = useState<MessageHeader[]>([])

    const [contacts, setContacts] = useState<{ [key: string]: [MessageHeader] }>({})
    const [viewSender, setViewSender] = useState<string>()

    const [showSidebar, setShowSidebar] = useState(true)

    //todo why route.params not work?
    //@ts-ignore
    let {to, subject, quote} = route.params || ""

    const [showNewMessage, setShowNewMessage] = useState(to ? true : false)

    // let to = ""

    const initForm = {
        diamondAddress: to,
        subject: subject,
        message: "",
        //file?phone: "",
    }

    useEffect(() => {
        if (to || subject || quote) {
            form.setFormData({
                ...form.formData,
                ...(to && {diamondAddress: to}),
                ...(subject && {subject}),
                ...(quote && {message: quote})
            })
            setShowNewMessage(true)
        }
    }, [route.params]);

    const form = useForm(initForm)

    useEffect(() => {
        refresh()
    }, [blockchain.api])

    useEffect(() => {
        //example_tfhe_wasm()

        //handleMessagesFromExtension()
    }, [])


    useInterval(() => {
        refresh()
        //  testPaillierWasm()
    }, 8000)


    useEffect(() => {

        let new_contacts = {
            ...contacts
        }
        for (let i = 0; i < messages.length; i++) {
            let msg = messages[i]
            //  console.log(msg)
            if (new_contacts[msg.sender]) {
                let exists = new_contacts[msg.sender].find(s => s.id === msg.id)
                if (!exists) {
                    new_contacts[msg.sender].push(msg)
                }
            } else {
                new_contacts[msg.sender] = [msg]
            }
        }


        for (let i = 0; i < sentMessages.length; i++) {
            let msg = sentMessages[i]
            //  console.log(msg)
            if (new_contacts[msg.recipient]) {
                let exists = new_contacts[msg.recipient].find(s => s.id === msg.id)
                if (!exists) {
                    new_contacts[msg.recipient].push(msg)
                }
            } else {
                new_contacts[msg.recipient] = [msg]
            }
        }

        const keys = Object.keys(new_contacts)
        for (let i = 0; i < keys.length; i++) {
            let c = new_contacts[keys[i]]
            c.sort((a, b) => a.date - b.date)
            new_contacts[keys[i]] = c
        }

        setContacts(new_contacts)

    }, [messages, sentMessages])
    // end of hooks

    const refresh = async () => {
        postMessage()
        handleGetMessages()
        handleGetSentMessages()
    }

    const postMessage = async () => {
        console.log("hi")
        window.postMessage({
            to: "DIAMOND_REALM_EXTENSION",
            action: "FROM_PAGE",
            text: "Hello from the webpage!"
        }, "*");

        let transaction = {
            hi: "world"
        }
        window.postMessage({
            to: "DIAMOND_REALM_EXTENSION",
            action: "SIGN_TRANSACTION",
            transaction: transaction
        }, "*");
    }

    const handleConnectBlockchain = async () => {
        window.postMessage({
            to: "DIAMOND_REALM_EXTENSION",
            action: "CONNECT_BLOCKCHAIN"
        })
    }

    const handleMessagesFromExtension = async () => {
        console.log("setup listener for extension")
        // Listen for the signed transaction ID
        document.addEventListener('FromExtensionToPage', (event) => {
            const detail = event.type //detail;
            console.log('Data received from extension:', detail);
        });

        window.addEventListener("message", function (event) {
            if (event.data?.origin?.startsWith('substrate-connect')) {
                return
            }
            console.log("Received message:", event.data);
            /*  if (event.data && event.data.type === "TRANSACTION_SIGNED") {
                  console.log("Transaction signed event received:", event.data.transactionId);
              }*/
            /*
            console.log("in browser all events", event)
            if (event.data.type === "TRANSACTION_SIGNED") {
                console.log("in browser found transaction signed", event)
                const transactionId = event.data.transactionId;
                // todo
                // Set up the WebSocket subscription using the transaction ID
                // subscribeToWebSocket(transactionId);
            }*/
        });

    }


    const handleCreateMessage = async () => {
        form.setSubmitting(true)
        console.log("create message", form.formData)
        if (blockchain.api && wallet.localWallet && wallet.address && wallet.curvePair) {
            const f = form.formData
            let id = uuidv4()
            let data: Message = {
                id,
                subject: f.subject,
                message: f.message,
            }
            console.log(data)
            try {
                let res = await CreateMessage(
                    blockchain.api,
                    wallet.localWallet,
                    wallet.curvePair,
                    form.formData.diamondAddress,
                    id,
                    data,
                    () => {
                        form.setFormData(initForm)
                        form.setSubmitting(false)
                        setShowNewMessage(false)
                    }
                )

            } catch (err) {
                console.log(err)
                form.setSubmitting(false)
            }
        }
    }


    const handleGetMessages = async () => {
        if (blockchain.api && wallet.localWallet && wallet.address && wallet.curvePair) {
            let r = await GetMessages(
                blockchain.api,
                wallet.curvePair,
                wallet.address,
            )
            if (r) {
                setMessages(r)
            }
        }
    }

    const handleGetSentMessages = async () => {
        if (blockchain.api && wallet.localWallet && wallet.address && wallet.curvePair) {
            let r = await GetSentMessages(
                blockchain.api,
                wallet.curvePair,
                wallet.address,
            )
            if (r) {
                setSentMessages(r)
            }
        }
    }

    const handleSetAddress = (diamondAddress: string) => {
        form.setFormData({
            ...form.formData,
            diamondAddress
        })
    }

    return (
        <View style={styles.container}>
            <ScrollView
                keyboardShouldPersistTaps={'handled'}
                style={{
                    width: "100%",
                    padding: 12,
                    paddingBottom: 100,
                    maxWidth: CONSTANTS.pageWidth,
                    height: "100%",

                }}>

                <Flex style={{
                    width: "100%",
                    flex: 1,
                    maxWidth: CONSTANTS.pageWidth,
                    alignItems: "flex-start",
                    height: "92%",

                }}>

                    <View style={{
                        width: showSidebar ? "auto" : 72,
                    }}>
                        <Flex style={{
                            flex: 1,
                        }}>
                            <ButtonText minimal={true} style={{marginRight: 6}} disabled={false}
                                        onPress={() => {
                                            setShowSidebar(!showSidebar)
                                        }}>
                                <FontAwesomeIcon color={Colors.dark.text} icon={faEnvelopeNew}/>
                            </ButtonText>
                        </Flex>
                        <Flex style={{
                            //flex: 1,
                            justifyContent: "space-between",
                            marginBottom: "2%",
                            flexWrap: "wrap",
                        }}>
                            <Flex style={{
                                flex: 1,
                                //justifyContent: "flex-end",
                            }}>
                                <ButtonText selected={showNewMessage} minimal={true} style={{marginRight: 6}}
                                            disabled={false}
                                            onPress={() => {
                                                setShowNewMessage(!showNewMessage)
                                            }}>
                                    {showSidebar && <Text style={{marginRight: 12}}>New</Text>}
                                    <FontAwesomeIcon color={Colors.dark.text} icon={faPlus}/>
                                </ButtonText>
                            </Flex>
                        </Flex>


                        {Object.entries(contacts).map(([key, sender], i) => (
                            <View key={"senders" + i}>
                                <ButtonUi minimal={true} onPress={() => {
                                    if (viewSender === key) {
                                        setViewSender(undefined)
                                    } else {
                                        setViewSender(key)
                                        setShowNewMessage(false)
                                        setShowSidebar(false)
                                    }
                                }}>
                                    <View style={{flex: 1}}>
                                        <Flex style={{
                                            alignItems: "center",
                                            flexWrap: "wrap",
                                            justifyContent: "space-between"
                                        }}>
                                            <Flex style={{
                                                alignItems: "center",
                                            }}>
                                                {/* <FontAwesomeIcon color={Colors.dark.text} icon={faEnvelope}
                                                     style={{marginHorizontal: 6}}/>*/}
                                                <AccountSenderListItem abbr={!showSidebar} address={key}/>
                                            </Flex>
                                        </Flex>
                                        {/* <Text style={{fontWeight: "500", marginTop: 16}}>

                            </Text>*/}
                                    </View>
                                </ButtonUi>

                            </View>
                        ))}


                    </View>
                    <View style={{
                        height: "100%",
                        flex: 1,
                        //...styles.cardBorder,
                    }}>
                        {showNewMessage &&
                            <View style={{
                                paddingBottom: 24
                            }}>
                                <Flex style={{
                                    flex: 1,
                                    //justifyContent: "space-between",
                                    alignItems: "flex-start",
                                    maxHeight: "50%",
                                }}>
                                    <View
                                        style={{
                                            flex: 1,
                                            minWidth: 300,
                                        }}
                                    >
                                        <AddressContactsInput form={form}
                                                              contactsPickerSetValue={(addr: string) => {
                                                                  form.setFormData({
                                                                      ...form.formData,
                                                                      diamondAddress: addr,
                                                                  })
                                                              }}/>

                                        <TextInput
                                            style={styles.input}
                                            placeholder="Subject"
                                            autoFocus={false}
                                            value={form.formData.subject}
                                            onChangeText={form.handleChange("subject")}
                                        />
                                    </View>
                                </Flex>
                                <TextInput
                                    textAlignVertical={"top"}
                                    multiline={true}
                                    numberOfLines={12}
                                    style={styles.input}
                                    placeholder="Message"
                                    autoFocus={true}
                                    value={form.formData.message}
                                    onChangeText={form.handleChange("message")}
                                />
                                <View>
                                    <ButtonText selected={form.submitting}
                                                disabled={form.submitting || !form.formData.diamondAddress}
                                                onPress={() => {
                                                    handleCreateMessage()
                                                }}>
                                        {form.submitting ? <Loading/> : "Send Encrypted Message"}
                                    </ButtonText>
                                </View>


                            </View>
                        }
                        <View style={{
                            // paddingBottom: 100,
                            //height: "100%",
                            flex: 1,
                            //justifyContent: "space-between",
                            //marginBottom: "2%",
                            flexWrap: "wrap",
                            //overflow:"hidden",


                        }}>

                            {viewSender &&
                                contacts[viewSender] &&
                                contacts[viewSender]
                                    .sort((a, b) => {
                                        if (b.date && a.date) {
                                            return b.date - a.date
                                        }
                                        return 0
                                    })
                                    .map((m, i) => (
                                        /*<MessageView messageHeader={m} key={"mesg" + i}/>*/
                                        <MessageListItem viewMessageHeader={viewMessageHeader}
                                                         setViewMessageHeader={setViewMessageHeader}
                                                         messageHeader={m} key={"mesg" + i}/>
                                    ))
                            }

                        </View>


                    </View>
                </Flex>
            </ScrollView>
        </View>

    );
}

export default MessageScreen