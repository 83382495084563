
///
/// Contacts
///

import {ApiPromise} from "@polkadot/api";
import {LocalWallet} from "./hooks/useWallet";
import {BoxKeyPair} from "tweetnacl";
import {Contact, FileHeaderI, FolderHeaderI} from "./interfaces";
import {CypherMsgI, decrypt, DiamondKeyring, encrypt, newNonce, toHexString} from "./crypto";
import {GenerateContactsRootParentId, GenerateContactStorageId} from "./storage";
import Toast from "react-native-toast-message";
import {ParseResultEvents, ToastTx} from "../components/Toast";

export const CreateContact = async (
    api: ApiPromise,
    localWallet: LocalWallet,
    curvePair: BoxKeyPair,
    folderHeader: FolderHeaderI,
    fileName: string,
    json: Contact,
    callback?: () => any,
    //setSubmitting?: (t: boolean) => any,
) => {
    Toast.show({
        type: 'info',
        text1: `Processing`,
        text2: `Transaction is being processed on chain`
    });
    if (folderHeader.parentId === "") {
        folderHeader.parentId = GenerateContactsRootParentId(curvePair)
    }

    const folderIdHash = GenerateContactStorageId(curvePair, folderHeader.parentId, folderHeader.name)
    const fileIdHash = GenerateContactStorageId(curvePair, folderHeader.parentId, folderHeader.name, fileName)
    console.log("create", folderHeader, "folderIdHash", folderIdHash,"fileID hash:",fileIdHash ,"contactName",fileName,"json", json,)

    const nonce = newNonce();

    let encData = encrypt({
        secretOrSharedKey: curvePair.secretKey,
        json,
        key: curvePair.publicKey,
        senderPublicKey: curvePair.publicKey,
        nonce,
    })
    console.log("cypher message object", encData)

    let header: FileHeaderI = {
        name: fileName,
        // size: fileSize,
    }

    let encHeader = encrypt({
        secretOrSharedKey: curvePair.secretKey,
        json: header,
        key: curvePair.publicKey,
        senderPublicKey: curvePair.publicKey,
        nonce,
    })


    let kp = DiamondKeyring.addFromUri(localWallet.seedPhrase)
    try {
        api.tx.decentralizedStorage.createEncryptedStorage(
            folderIdHash,
            fileIdHash,
            encHeader.cypherText,
            encData.cypherText,
            encData.nonce,
        ).signAndSend(kp, (res) => {
            console.log("Create Contract Result ", res)
            ToastTx(res)
            ParseResultEvents(api, res)
            if (res.status.isInBlock) {
                callback && callback()
            }
        })
    } catch (error) {
        console.log("catch error", error);
        Toast.show({
            type: 'tomatoToast',
            text1: "",
            //text2: `${result.status.asInBlock}`
        });

    }

    return {
        folderIdHash,
        fileIdHash
    }

}

export const GetContacts = async (
    api: ApiPromise,
    curvePair: BoxKeyPair,
    folderHeader: FolderHeaderI,
) => {
    const folderHeaderCopy = { ...folderHeader };

    if (folderHeaderCopy.parentId === "") {
        folderHeaderCopy.parentId = GenerateContactsRootParentId(curvePair);
    }
    let folderIdHash = GenerateContactStorageId(curvePair, folderHeaderCopy.parentId, folderHeaderCopy.name)

    let res = await api.query.decentralizedStorage.encryptedFileHeaders.entries(folderIdHash)
    console.log("Get Contacts folderId", folderHeader.name,"parentID: ",folderHeader.parentId)
    console.log("currentFolder IDhash",folderIdHash, "Response",res)
    if (!res) {
        console.log("no storage found for that hash key")
        return
    }
    let headers: FileHeaderI[] = []

    res.forEach(([key, fileHeader]) => {
        let h = fileHeader.unwrap()

        //  console.log('key arguments:', key.args.map((k) => k.toHuman()));
        //  console.log('     file:', fileHeader.toHuman(), h);

        let msg: CypherMsgI = {
            nonce: h.nonce.toUtf8(), //.toString(),
            cypherText: h.header.toUtf8(), //.toString(),
            senderPublicKey: curvePair.publicKey,
        }
        //  console.log("msg from chain", msg)

        let d: FileHeaderI = decrypt(curvePair.secretKey, msg)
        //   console.log("decrypted data from the chain", d)

        headers.push(d)
    });

    return headers
}


export const GetContact = async (
    api: ApiPromise,
    curvePair: BoxKeyPair,
    folderHeader: FolderHeaderI,
    contactName: string,
) => {
    if (folderHeader.parentId === "") {
        folderHeader.parentId = GenerateContactsRootParentId(curvePair)
    }
    const fileIdHash = GenerateContactStorageId(curvePair, folderHeader.parentId, folderHeader.name, contactName)

    let res = await api.query.decentralizedStorage.encryptedStorage(fileIdHash)
    if (res.isEmpty) {
        console.log("no storage found for that hash key")
        return
    }
    let data = res.unwrap()
    console.log(data)
    let msg: CypherMsgI = {
        nonce: data.nonce.toUtf8(), //.toString(),
        cypherText: data.data.toUtf8(), //.toString(),
        senderPublicKey: curvePair.publicKey,
    }
    //console.log("msg converted from chain", msg)

    let d: Contact = decrypt(curvePair.secretKey, msg)
    //console.log("decrypted data from the chain", d)

    return d
}





/*
export const SubscribeFiles = async (
    api: ApiPromise,
    curvePair: BoxKeyPair,
    folderRoot: string,
    setFiles: (folders: FileHeaderI[]) => void,
) => {
    let folderId = GenerateStorageId(curvePair, folderRoot)
    try {
        const unsub = await api.query.decentralizedStorage.fileHeaders.entries(folderId, (res) => {
            //  console.log("subscribe files")
            if (!res) {
                console.log("no storage found for that hash key")
                return
            }
            let headers: FileHeaderI[] = []

            res.forEach(([key, fileHeader]) => {
                let h = fileHeader.unwrap()

                //    console.log('key arguments:', key.args.map((k) => k.toHuman()));
                //    console.log('     file:', fileHeader.toHuman(), h);

                let msg: CypherMsgI = {
                    nonce: h.nonce.toUtf8(), //.toString(),
                    cypherText: h.header.toUtf8(), //.toString(),
                    senderPublicKey: curvePair.publicKey,
                }
                //     console.log("msg from chain", msg)

                let d: FileHeaderI = decrypt(curvePair.secretKey, msg)
                //    console.log("decrypted data from the chain", d)

                headers.push(d)
            });
            setFiles(headers)
        })
        return unsub
    } catch (err) {
        console.log(err)
    }
}

export const SubscribeFolders = async (
    api: ApiPromise,
    curvePair: BoxKeyPair,
    folderRoot: string,
    setFolders: (folders: FolderHeaderI[]) => void,
) => {
    let folderId = GenerateStorageId(curvePair, folderRoot)
    try {
        const unsub = await api.query.decentralizedStorage.folderHeaders.entries(folderId, (res) => {

            if (!res) {
                console.log("no storage found for that hash key")
                return
            }
            let headers: FolderHeaderI[] = []

            res.forEach(([key, folderHeader]) => {
                let h = folderHeader.unwrap()

                //      console.log('key arguments:', key.args.map((k) => k.toHuman()));
                //     console.log('     folder:', folderHeader.toHuman(), h);

                let msg: CypherMsgI = {
                    nonce: h.nonce.toUtf8(), //.toString(),
                    cypherText: h.header.toUtf8(), //.toString(),
                    senderPublicKey: curvePair.publicKey,
                }
                //    console.log("msg from chain", msg)

                let d: FolderHeaderI = decrypt(curvePair.secretKey, msg)
                //    console.log("decrypted data from the chain", d)

                headers.push(d)
            });
            setFolders(headers)
        })
        return unsub
    } catch (err) {
        console.log(err)
    }
}
*/