export const randomInt = (m: number) => {
  return Math.floor(Math.random() * m)
}

export function radians_to_degrees(radians: number) {
  var pi = Math.PI
  return radians * (180 / pi)
}

export const FormatNumber = (n: number, decimals: number = 8) => {
  return n.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: decimals})
}

export const FormatDecimalAmount = (amount: number | string, decimals: number, places?: number) => {
  let num: number
  if (typeof amount === 'number') {
    num = amount
  } else {
    num = parseInt(amount)
  }
  return FormatNumber(num / Math.pow(10, decimals), places)
}

export const DecimalAmount = (amount: number | string, decimals: number) => {
  let num: number
  if (typeof amount === 'number') {
    num = amount
  } else {
    num = parseInt(amount)
  }
  return num / Math.pow(10, decimals)
}