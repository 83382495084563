import {Button, ImageBackground, ScrollView, TextInput, TouchableHighlight} from 'react-native';

import {Flex, FormLabel, Text, View} from '../../components/Themed';
import React, {useEffect, useRef, useState} from "react";
import {useForm} from "../../hooks/useForm";
import {styles} from "../../constants/Styles";

import {useBlockchain} from "../../diamond-realm-api/hooks/useBlockchain";
import useWallet from "../../diamond-realm-api/hooks/useWallet";
import {DiamondKeyring, } from "../../diamond-realm-api/crypto";
import ButtonText from "../../components/ButtonText";
import DisplayKey from "../../components/Keys/DisplayKey";
import Loading from "../../components/Loading";
import {FontAwesomeIcon} from "@fortawesome/react-native-fontawesome";
import {faPenToSquare} from '@fortawesome/free-solid-svg-icons';
import Colors from "../../constants/Colors";
import Toast from "react-native-toast-message";


export default function YourKeychain() {
  const blockchain = useBlockchain()
  const wallet = useWallet()


  const input_ref = useRef<TextInput | null>(null);


  // end of hooks

  const handleClearKey = (e: any) => {
    e.preventDefault()
    wallet.clearLocalWallet()
  }

  return (
    <View style={styles.container}>
      <ScrollView
        keyboardShouldPersistTaps={'handled'}
        style={{
          width: "100%",
          padding: 24,
          paddingBottom: 100,
        }}>

      {wallet.localWallet?.address[0] ? (
        <View style={{marginTop: 12, marginBottom: 12}}>
          <Text style={styles.header}>Masterkey</Text>
          <DisplayKey localWallet={wallet.localWallet}/>
          <View style={{
            //margin:24,
            //justifyContent:"flex-end",
            flex: 1
          }}>
            <Text>
              <ButtonText disabled={false} onPress={e => handleClearKey(e)}>Remove your Key from this Wallet</ButtonText>
            </Text>
          </View>
        </View>
      ) : (
        <View>
          <Text>
            Load A key or Generate a new one to get started.
          </Text>
        </View>
      )}
      </ScrollView>
    </View>
  );
}

