import {ISubmittableResult} from "@polkadot/types/types/extrinsic";
import Toast from "react-native-toast-message";
import {ApiPromise} from "@polkadot/api";

export const ParseResultEvents = (api: ApiPromise, result: ISubmittableResult) => {
    const {dispatchError} = result
    if (dispatchError) {
        if (dispatchError.isModule) {
            // for module errors, we have the section indexed, lookup
            const decoded = api.registry.findMetaError(dispatchError.asModule);
            const {docs, name, section} = decoded;
            let msg = `${section}.${name}: ${docs.join(' ')}`
            console.log("module error", msg);
            Toast.show({
                type: 'tomatoToast',
                text1: `Extrinsic Failed`,
                text2: msg,
                visibilityTime: 24000,
            });
        } else {
            // Other, CannotLookup, BadOrigin, no extra info
            console.log(dispatchError.toString());
        }
    }

    result.events.forEach(({phase, event: {data, method, section}}) => {
        // console.log(`\t' ${phase}: ${section}.${method}:: ${data}`);
        if (section === "system" && method === "ExtrinsicFailed") { //ExtrinsicSuccess
            console.log("fail")
            Toast.show({
                type: 'tomatoToast',
                text1: `Extrinsic Failed`,
                text2: `${JSON.stringify(data)}`,
                visibilityTime: 24000,
            });
            console.log(`\t' ${phase}: ${section}.${method}:: ${data}`);
        }
    });
}
export const ToastTx = (result: ISubmittableResult) => {
    if (result.status.isInBlock) {
        Toast.show({
            type: 'success',
            text1: `Transaction included in block`,
            text2: `${result.status.asInBlock}`
        });
        console.log(`Transaction included at blockHash ${result.status.asInBlock}`);
    } else if (result.status.isFinalized) {
        Toast.show({
            type: 'success',
            text1: `Transaction finalized`,
            text2: `${result.status.asFinalized}`
        });
        console.log(`Transaction finalized at blockHash ${result.status.asFinalized}`);
    }
}

export const ToastError = (err:any) => {
    Toast.show({
        type: 'tomatoToast',
        text1: `Error`,
        text2: `${err}`,
        visibilityTime: 12000,
    });
}