import create from 'zustand'
import {persist} from "zustand/middleware"
import {useEffect, useMemo, useState} from "react";
import {ConvertEdSecret2CurvePair, toHexString} from "../crypto";
import {useBlockchain} from "./useBlockchain";
import {omit} from "lodash-es";
import BN from "bn.js";
import {sign} from "tweetnacl";
import * as ed2curve from "ed2curve";
import {encodeAddress} from "@polkadot/keyring";
import useNameService from "./useNameService";

const {
    blake2AsHex,
    blake2AsU8a,
    keccakAsHex,
    mnemonicGenerate,
    mnemonicToMiniSecret,
    mnemonicValidate,
    ed25519PairFromSeed,
} = require('@polkadot/util-crypto');

export interface LocalWallet {
    seedPhrase: string,
    secretKey: Uint8Array,
    address: Uint8Array,
//  addressHex: string,
}

type WalletStore = {
    encryptedLocalWallet?: string,
    setEncryptedLocalWallet: (p: string) => void,
    localWallet?: LocalWallet,
    setLocalWallet: (w: LocalWallet) => void,
    clearLocalWallet: () => void,
}


type WalletEphemeralStore = {
    nick?: string,
    setNick: (n: string) => void
    balance?: BN,
    setBalance: (n: BN) => void
}

const useWalletEphemeralStore = create<WalletEphemeralStore>((set, get) => ({
    setNick: (n) => {
        set((state) => ({nick: n}))
    },
    setBalance: (b) => {
        set((state) => ({balance: b}))
    },

}))


const useWalletStore = create(persist<WalletStore>((set, get) => ({
        setEncryptedLocalWallet: (s) => {
            set((state: WalletStore) => ({encryptedLocalWallet: s}))
        },
        setLocalWallet: (w) => {
            set((state: WalletStore) => ({localWallet: w}))
        },
        clearLocalWallet: () => set((state) => omit(state, ['localWallet']), true),
    }), {
        name: "wallet",
    }
))

const useWallet = () => {
    const {
        encryptedLocalWallet,
        setEncryptedLocalWallet,
        localWallet,
        setLocalWallet,
        clearLocalWallet
    } = useWalletStore()
    const {balance, setBalance} = useWalletEphemeralStore()
    const blockchain = useBlockchain()


    //todo use asHexAddress
    const address = localWallet?.address && `0x${toHexString(localWallet.address)}` //toHexString(localWallet.address)
    const {nick} = useNameService({address})

    const ss58Address = useMemo(() => {
        if (address && address != "0x") {
            //  console.log("address",address)
            return encodeAddress(address);
        }
    }, [address])


    useEffect(() => {
        subscribeBalance()
    }, [blockchain.api])

    useEffect(() => {
        recreateKeys()
    }, [localWallet?.seedPhrase])

    const curvePair = useMemo(() => {
        if (localWallet?.seedPhrase) {
            return ConvertEdSecret2CurvePair(localWallet.seedPhrase)
        }
    }, [localWallet?.seedPhrase])

    /// end of hooks
    const recreateKeys = async () => {
        if (localWallet?.seedPhrase) {
            const seed = mnemonicToMiniSecret(localWallet?.seedPhrase);
            const {publicKey, secretKey} = ed25519PairFromSeed(seed);
            //const { publicKey, secretKey } = box.keyPair.fromSecretKey(seed)
            // the ed25519 has a 64 byte private key that is used for signing and that is the one we need for the account on the blockchain
            // we will also have a curve25519 that is used for encrypting your datas
            setLocalWallet({
                ...localWallet,
                secretKey,
                address: publicKey
            })
        }
    }


    const subscribeBalance = async () => {
        if (!blockchain.api || !address) return

        const unsub = await blockchain.api.query.system.account(address, ({nonce, data: balance}) => {
            console.log(`free balance is ${balance.free} with ${balance.reserved} reserved and a nonce of ${nonce}`);
            setBalance(balance.free)
            //setNonce(nonce)
        });
        return unsub
    }

    return {
        //persisted
        encryptedLocalWallet,
        setEncryptedLocalWallet,
        localWallet,
        setLocalWallet,
        clearLocalWallet,
        //ephemeral
        balance,
        nick,
        address,
        ss58Address,
        curvePair,
    }
}

export default useWallet


/*
const handleGetName = async (e:any) => {
  e.preventDefault()
  if (blockchain.api && localKey.localWallet) {
    let n = await blockchain.api.query.nicks.nameOf(`0x${toHexString(localKey.localWallet.address)}`)
    console.log(n)
    console.log("res",n.toHuman())
    let _name = n.toHuman() as any[]
    if(!_name) return
    console.log(_name)
    if (n) {
      setName(_name[0])
    }
  }
}*/
