import {faFaceGrinBeam} from "@fortawesome/free-solid-svg-icons";

import React from "react";
import {Text} from "./Themed";
import {FontAwesomeIcon} from "@fortawesome/react-native-fontawesome";
import {styles} from "../constants/Styles";
import {ActivityIndicator} from "react-native";
import Colors from "../constants/Colors";

const Loading = () => {
  return (
    <Text
      style={{
        ...styles.item,
        justifyContent: "center",
        alignItems: "center",
        flex: 1
      }}>
      <ActivityIndicator color={styles.item.color} />
    </Text>
  )
}


export default Loading