import {ScrollView} from 'react-native';




import {Text, View} from '../components/Themed';
import {WalletStackParamList} from '../types';
import {CONSTANTS, styles} from "../constants/Styles";
import React, {useEffect, useMemo, useState} from "react";
import BN from "bn.js";
import {useBlockchain} from "../diamond-realm-api/hooks/useBlockchain";
import useWallet from "../diamond-realm-api/hooks/useWallet";
import {createNativeStackNavigator, NativeStackScreenProps} from "@react-navigation/native-stack";
import AccountMenu from "../components/AccountMenu";
import {FormatNumber} from "../utils/NumbersUtils";
import useTokens from "../diamond-realm-api/hooks/useTokens";
import TokenListItem from "../components/tokens/TokenListItem";
import TokenScreen from "./wallet/TokenScreen";
import {PublishTokenAddress, TokenValue} from "../diamond-realm-api/tokens";
import {AddressShort} from "../utils/addressUtils";
import ButtonText from "../components/ButtonText";
import {toHexString} from "../diamond-realm-api/crypto";
import init from "../diamond-realm-api/paillier-rust";
import * as paillier_wasm from "../diamond-realm-api/paillier-rust";
import {useForm} from "../hooks/useForm";
import Loading from "../components/Loading";
import Toast from "react-native-toast-message";
import RegisterAccount from "../components/tokens/RegisterAccount";


const WalletStack = createNativeStackNavigator<WalletStackParamList>();

export default function WalletStackScreen() {
    return (
        <WalletStack.Navigator
            initialRouteName={"Wallet"}
            screenOptions={{
                headerStyle: styles.headerStyle,
                headerRight: () => (
                    <View style={{
                        margin: 12
                    }}>
                        <AccountMenu/>
                    </View>
                ),
            }}>
            <WalletStack.Screen name="Wallet" component={WalletScreen}/>
            <WalletStack.Screen name="Token" component={TokenScreen}/>
        </WalletStack.Navigator>
    );
}


type Props = NativeStackScreenProps<WalletStackParamList>;

function WalletScreen({navigation}: Props) {
    const blockchain = useBlockchain()
    const wallet = useWallet()
    const {accounts, metadata} = useTokens()
    const [assetIds, setAssetIds] = useState<string[]>([])
    // const [tokenAddr, setTokenAddr] = useState("")
    const {tokenAddress} = useTokens()

    const [nonce, setNonce] = useState<BN>()
    const initForm = {}
    const form = useForm(initForm)

    useEffect(() => {
        work()

    }, [blockchain.api, wallet.localWallet])

    useEffect(() => {
        //generatePaillierPublicTokenAddress()
        //testPaillierWasm()
    }, [wallet?.localWallet?.secretKey])

    const work = async () => {
        if (blockchain.api) {
            if (wallet.address) {
                let t = await blockchain.api.query.contracts.contractInfoOf(wallet.address)
                console.log(" Contract info for your address", t)
            }
            //let keys = await api.query.assets.asset.keys()
            let keys = await blockchain.api.query.tokens.token.keys()
            let ids: string[] = []
            for (let i = 0; i < keys.length; i++) {
                let id = keys[i].args[0].toString()
                console.log("id", id)
                ids = ids.concat(id)
            }
            setAssetIds(ids)
        }
    }

    const totalValue = useMemo(() => {
        let t = 0.0
        if (assetIds.length > 0) {
            for (let i = 0; i < assetIds.length; i++) {
                let id = assetIds[i]
                const account = accounts[id]
                const m = metadata[id]
                //  console.log("new balances", id, balance, m)
                if (account && m) {
                    let {worth} = TokenValue(account.balance, m)
                    // console.log("worth",worth)
                    t += worth
                }
            }
        }
        return t
    }, [accounts, assetIds])

    const handlePublishTokenAddress = async () => {
        if (blockchain.api && wallet.localWallet) {
            form.setSubmitting(true)
            await init()
            console.log("finish wasm init")

            let seed = `${toHexString(wallet!.localWallet!.secretKey)}::accounts::0`
            console.log('Seed ', seed)
            let hash = paillier_wasm.hash_string(seed)
            console.log('Wasm hash ', toHexString(hash))

            let publicAddress = paillier_wasm.generate_public_key_from_seed(seed)
            // setTokenAddr(publicAddress)

            Toast.show({
                type: 'info',
                text1: `Processing`,
                text2: `Transaction is being processed on chain`
            });
            try {
                let res = await PublishTokenAddress(blockchain.api, wallet.localWallet, publicAddress, () => {
                    form.setSubmitting(false)
                })
                console.log(res)
            } catch (err) {
                console.log(err)
            }

        }
    }
    const generatePaillierPublicTokenAddress = async () => {
        if (wallet?.localWallet?.secretKey) {
            console.log("start wasm init")
            init().then((_exports) => {
                console.log("finish wasm init")

                let seed = `${toHexString(wallet!.localWallet!.secretKey)}::accounts::0`
                console.log('Seed ', seed)
                let hash = paillier_wasm.hash_string(seed)
                console.log('Wasm hash ', toHexString(hash))

                let publicAddress = paillier_wasm.generate_public_key_from_seed(seed)
                //   setTokenAddr(publicAddress)

            })
        }
    }
    /*const testPaillierWasm = async () => {
        if (wallet?.localWallet?.secretKey) {
            console.log("start wasm init")
            const _exports = await init()
            console.log("finish wasm init")

            let seed = `${toHexString(wallet!.localWallet!.secretKey)}::accounts::0`
            let hash = paillier_wasm.hash_string(seed)
            console.log('Wasm hash ', seed, toHexString(hash))

            let publicAddress = paillier_wasm.generate_public_key_from_seed(seed)


            let n1 = new BN("12000000000000000000")
            let n2 = new BN("1000000000000")
            let n3 = new BN("1")


            let nonce = new BN("234525265")
            let c1 = paillier_wasm.encrypt_number(publicAddress, new Uint8Array(n1.toArray()), new Uint8Array(nonce.toArray()))
            let nonce2 = new BN("23452542342432423265") // randomAsU8a()
            let c2 = paillier_wasm.encrypt_number(publicAddress, new Uint8Array(n2.toArray()), new Uint8Array(nonce2.toArray()))

            let e3 = paillier_wasm.add_ciphertexts(publicAddress, c1, c2)
            let e4 = paillier_wasm.mul_ciphertext_plaintext(publicAddress, c1, new Uint8Array(n2.toArray()))

            let nonce3 = new BN("23452542342432423265") // randomAsU8a()
            let e5 = paillier_wasm.add_ciphertext_plaintext(publicAddress, c1, new Uint8Array(n3.toArray()), new Uint8Array(nonce3.toArray()))

            let e6 = paillier_wasm.sub_ciphertexts(publicAddress, c1, c2)


            console.log("===== ====== ====== decrypted values ==== ===== ======= ")
            console.log("c1 = ", paillier_wasm.decrypt_number(seed, c1))
            console.log("c2 = ", paillier_wasm.decrypt_number(seed, c2))

            console.log("encrypted add")
            console.log(paillier_wasm.decrypt_number(seed, e3))

            console.log("encrypted sub")
            console.log(paillier_wasm.decrypt_number(seed, e6))

            console.log("encrypted multiply")
            console.log(paillier_wasm.decrypt_number(seed, e4))

            console.log("+ plaintext")
            console.log(n3.toString())
            console.log("_______")
            let d5 = paillier_wasm.decrypt_number(seed, e5)

            console.log(d5)


        }
    }*/


    //if (isLoading) return <div>Loading ...</div>;

    return (
        <View style={styles.container}>
            <ScrollView
                keyboardShouldPersistTaps={'handled'}
                style={{
                    paddingTop: 24,
                    paddingHorizontal: 4,
                    paddingBottom: 100,
                    width: "100%",
                    maxWidth: CONSTANTS.pageWidth,
                }}>
                <RegisterAccount />
                <View style={{
                    alignItems: "center",
                    justifyContent: "center",
                    flex: 1,
                    width: "100%",
                    paddingTop: 24,
                    paddingBottom: 24,
                }}>
                    <Text style={{
                        ...styles.centerStage,

                    }}>
                        {`$${FormatNumber(totalValue, 2)}`}
                    </Text>
                </View>
                {assetIds.map((id, i) => (
                    <TokenListItem key={'asset' + i} id={id}/>
                ))}


            </ScrollView>
        </View>
    );
}

