import {GestureResponderEvent, TouchableHighlight} from "react-native";
import {Text, View} from "./Themed";
import {styles} from "../constants/Styles";
import React from "react";
import Colors from "../constants/Colors";

interface UiButtonI {
    disabled: boolean
    onPress?: ((event: GestureResponderEvent) => void) | undefined
    children: React.ReactNode,
    minimal?: boolean,
    style?: any
    selected?: boolean
}

const ButtonText = (
    {
        disabled,
        onPress,
        children,
        minimal,
        style,
        selected
    }: UiButtonI) => {
    let styleBase = minimal ? styles.button : styles.buttonPrimary
    let styleText = minimal ? styles.buttonText : {}
    if (selected) {
        styleBase = {
            ...styleBase,
            ...styles.buttonSelected
        }
    }
    return (
        <TouchableHighlight
            underlayColor={Colors.dark.buttonUnderlay}
            disabled={disabled}
            onPress={onPress}
        >
            <View style={{
                ...styleBase,
                ...(disabled && !selected && {
                    backgroundColor: 'none',//'rgba(1,2,3,0.6)',
                }),
                //backgroundColor: disabled ? 'rgba(1,2,3,0.6)' : styleBase.backgroundColor,
                ...style,
            }}>

                <Text style={{
                    ...styleText,
                    ...(disabled && {
                        color: `rgba(255, 250, 245, 0.1)`,
                        borderColor: `rgba(255, 250, 245, 0.03)`,
                        alignItems: "center",
                    }),
                }}>
                    {children}
                </Text>
            </View>
        </TouchableHighlight>
    )
}

export default ButtonText