import React, {useEffect, useState} from "react";
import {toHexString} from "../diamond-realm-api/crypto";
import {AddressShort} from "../utils/addressUtils"
import useWallet from "../diamond-realm-api/hooks/useWallet";
import {View, Flex, Text} from "./Themed";
import {styles} from "../constants/Styles";
import {NavigationProp, NavigatorScreenParams, useNavigation} from "@react-navigation/native";
import {AccountStackParamList, RootStackParamList, WalletStackParamList} from "../types";
import {TouchableHighlight} from "react-native";
import {NativeStackNavigationProp, NativeStackScreenProps} from "@react-navigation/native-stack";
import {useBlockchain} from "../diamond-realm-api/hooks/useBlockchain";
import useNameService from "../diamond-realm-api/hooks/useNameService";


const {
    u8aToHex
} = require('@polkadot/util');


interface props {
    id: string
}

const NameText = ({id}: props) => {
    const {nameInfo} = useNameService({id})

    return (
     <Text>
         @<strong>{`${nameInfo?.name}`}</strong>{` ${nameInfo?.owner && AddressShort(nameInfo.owner)}`}
     </Text>
    )

}

export default NameText