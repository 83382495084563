import {Button, ImageBackground, ScrollView, TextInput, TouchableHighlight} from 'react-native';

import {Flex, FormLabel, Text, View} from '../Themed';
import React, {useEffect, useRef, useState} from "react";
import {useForm} from "../../hooks/useForm";
import {CONSTANTS, styles} from "../../constants/Styles";
import ButtonText from '../ButtonText';

import {useBlockchain} from "../../diamond-realm-api/hooks/useBlockchain";
import useWallet from "../../diamond-realm-api/hooks/useWallet";
import Loading from "../Loading";
import {RegisterName, UpdateName,} from "../../diamond-realm-api/nameService";
import useNameService from "../../diamond-realm-api/hooks/useNameService";

interface props {
    id: string,
    onFinish?: () => any
}

const EditNameInfo = (
    {
        id,
        onFinish,
    }: props
) => {

    const {nameInfo} = useNameService({id: id})
    const initForm = {
        name: "",
    }
    const form = useForm(initForm)
    const blockchain = useBlockchain()
    const wallet = useWallet()

    useEffect(() => {
        if (nameInfo) {
            form.setFormData({
                //...form.formData,
                name: nameInfo.name,
                www: nameInfo.www,
            })
        }
    }, [nameInfo])

    const input_ref = useRef<TextInput | null>(null);

    useEffect(() => {

    }, [])

    useEffect(() => {
        //  work()
    }, [blockchain.api, wallet.localWallet])

    // end of hooks // //////////////////////////////////////////////


    const handleUpdateName = async (e?: any) => {
        e && e.preventDefault()

        if (blockchain.api &&   nameInfo?.name && wallet.localWallet && wallet.address) {
            form.setSubmitting(true)
            let res = await UpdateName(
                blockchain.api,
                wallet.localWallet,
               nameInfo.name,
                form.formData.www,
                () => {
                    form.setSubmitting(false)
                    onFinish && onFinish()
                })
            console.log("registered name", res)

        }
    }

    return (
        <View style={{}}>
                <Text style={{fontWeight: "700"}}>Website://</Text>
                    <TextInput
                        ref={input_ref}
                        style={styles.input}
                        placeholder="https://"
                        autoFocus={true}
                        value={form.formData.www}
                        onChangeText={form.handleChange("www")}
                        onSubmitEditing={handleUpdateName}
                    />
            <Flex>
                <ButtonText disabled={form.submitting} onPress={handleUpdateName}>
                    {form.submitting ? <Loading/> : "Update Name Info"}
                </ButtonText>
            </Flex>

            <View>
                <Text style={styles.headerHeavy}>Name Info ID: {id}</Text>
            </View>
        </View>

    );
}

export default EditNameInfo