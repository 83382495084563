const fontColor = 'rgba(252,253,255,0.8)'
const iconColor = 'rgba(252,253,255,1)'

const siteGrad = `linear-gradient(-27deg, rgb(18, 23, 31), rgb(38, 43,51))`;
const siteGradA = `linear-gradient(-27deg, rgba(28, 33, 41,0.6), rgba(38, 43,51,0.6))`;
const brightBlue = `rgb(28, 33, 41)`
const brightBlueAlpha = `rgba(28, 33, 41, 0.6)`
const darkBlue = `rgb(16, 19, 22)`
const darkBlueAlpha = `rgba(28, 33, 41, 0.618)`
const gold = "rgb(214,145,30)";
const goldAlpha = "rgba(248,165,4,0.62)";

const tintColorLight = gold;
const tintColorDark = "rgba(248,165,4,0.8)";
const tintColorAlpha = goldAlpha
const buttonUnderlay = "rgba(255,255,255,0.6)"
export default {
  light: {
    text: fontColor,
    background: siteGrad,
    header: darkBlue,
    tint: tintColorLight,
    tintDark: tintColorDark,
    tintAlpha: tintColorAlpha,
    tabIconDefault: iconColor,
    tabIconSelected: iconColor,
    card: "rgba(0,3,6,0.12)",
    modal: "rgb(12,16,21)",
    brightBlue,
    brightBlueAlpha,
    goldAlpha,
    buttonUnderlay
  },
  dark: {
    text: fontColor,
    background: darkBlue,
    backgroundAlpha: darkBlueAlpha,
    header: darkBlue,
    tint: tintColorLight,
    tintDark: tintColorDark,
    tintAlpha: tintColorAlpha,
    tabIconDefault: iconColor,
    tabIconSelected: iconColor,
    card: "rgba(0,3,6,0.12)",
    modal: "rgb(12,16,21)",
    brightBlue,
    brightBlueAlpha,
    goldAlpha,
    buttonUnderlay
  },
};

