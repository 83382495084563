import React from "react";
import {toHexString} from "../diamond-realm-api/crypto";
import {AddressShort} from "../utils/addressUtils"
import useWallet from "../diamond-realm-api/hooks/useWallet";
import {View, Flex, Text} from "./Themed";
import {styles} from "../constants/Styles";
import {NavigationProp, NavigatorScreenParams, useNavigation} from "@react-navigation/native";
import {AccountStackParamList, RootStackParamList, WalletStackParamList} from "../types";
import {TouchableHighlight} from "react-native";
import {NativeStackNavigationProp, NativeStackScreenProps} from "@react-navigation/native-stack";


const {
  u8aToHex
} = require('@polkadot/util');


export type RootTabParamList = {
  Wallet: NavigatorScreenParams<WalletStackParamList>;
  Messages: undefined;
  Dao: undefined;
  Files: undefined;
  Account: NavigatorScreenParams<AccountStackParamList>;
};

type MyComponentNavigationProp = NativeStackNavigationProp<RootTabParamList, "Wallet">;

interface props {
  address?: Uint8Array
}

const AccountDisplay = ({address}:props) => {

  return (

          <Text style={{fontSize: 10, marginTop: 1, fontWeight: "800"}}>
            <Text>0x</Text>{address ? AddressShort(toHexString(address)) : "0x..."}
          </Text>

  )
}

export default AccountDisplay