import {Dimensions, StyleSheet} from "react-native";
import Colors from "./Colors";

const colorScheme = "dark"

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;
export const heightCalc = (percent: number) => {
  return windowHeight * percent / 100
}
export const widthCalc = (percent: number) => {
  return windowWidth * percent / 100
}

export const CONSTANTS = {
  borderRadius: 6,
  navBarHeight: 78,
  pageWidth: 900,//"100%",//720,
}

export const stylesModal = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: heightCalc(2),
  },
  modalView: {
    margin: 20,
    marginTop: 42,
    backgroundColor: Colors.dark.modal,
    borderRadius: CONSTANTS.borderRadius,
    borderColor: Colors.dark.goldAlpha,
    padding: 36,
    width: "90%",
    maxWidth: 720,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.5,
    shadowRadius: 20,
    elevation: 20,
  },
  textStyle: {
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  modalText: {
    marginBottom: 15,
    textAlign: 'center',
  },
  buttonClose: {
    backgroundColor: "rgba(250,252,254,0.62)"
  }
});

export const styles = StyleSheet.create({
  screen: {
    flex: 1,
    maxWidth: 1024,
    flexDirection: "row",
    alignItems: "center",
    //justifySelf: "center",
    margin: "auto",
    width: "100%",
    height: "100%",
  },
  headerStyle: {
    // has no effect on header react native paddingTop: 12,
    height: CONSTANTS.navBarHeight,
    borderBottomWidth: 0,
    //borderColor: Colors[colorScheme].header,
    backgroundColor: Colors[colorScheme].header,
  },
  container: {
    flex: 1,
    height: "100%",
    width: "100%",
    alignItems: 'center',
    justifyContent: 'flex-start',
    //borderColor: `rgb(200, 0, 0)`,
    //padding: 12,

  },
  centerStage: {
    marginTop: 32,
    marginBottom: 32,
    fontSize: 42,
    fontWeight: "200",
    //textDecorationLine: "underline",
  },
  bordered: {
    borderStyle: "dashed",
    borderColor: `rgb(0, 220, 0)`,
    borderWidth: 2,
  },
  borderedB: {
    borderStyle: "dashed",
    borderColor: `rgb(0, 220, 220)`,
    borderWidth: 2,
  },
  titleTop: {
    marginTop: 18,
  },
  title: {
    color: 'rgba(252,254,255,0.92)',
    fontSize: 36,
    fontWeight: "100",
    //marginBottom: 24,
    //marginTop: 36,
  },
  titleHeavy: {
    color: 'rgba(252,254,255,0.92)',
    fontSize: 36,
    fontWeight: "800",
    //marginBottom: 24,
    //marginTop: 36,
  },
  subTitle: {
    // marginTop: -24,
    marginBottom: 26,
    fontWeight: "700",
  },
  header: {
    color: 'rgba(252,254,255,0.92)',
    fontSize: 24,
    fontWeight: "200",
    marginBottom: 2,
    marginTop: 6,
  },
  headerHeavy: {
    color: 'rgba(252,254,255,0.92)',
    fontSize: 24,// widthCalc(200),
    fontWeight: "900",
    marginBottom: 0,
    marginTop: 4,
  },
  item: {
    marginVertical: 4,
    color: Colors.dark.text,
    fontWeight: "700",
  },
  logo: {
    color: 'rgba(252,254,255,0.92)',
    fontSize: 24,
    fontWeight: "800",
    //marginLeft: 24,
    marginRight: 0,
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
  input: {
    color: Colors.dark.text,
    marginVertical: 10,
    paddingHorizontal: 16,
    paddingVertical: 8,
    borderStyle: "solid",
    borderColor: Colors.dark.text,
    borderRadius: 8,
    borderWidth: 1,
    backgroundColor: Colors['dark'].card,

  },
  button: {
    marginHorizontal: 2,
    marginVertical: 2,
    paddingVertical: 10,
    paddingHorizontal: 12,
    backgroundColor: Colors.dark.backgroundAlpha,
    borderRadius: CONSTANTS.borderRadius,
    color: Colors.dark.text,
    //justifyContent: "center",
    //justifyItems: "center"
    alignItems:"center"
  },
  buttonSelected: {
    backgroundColor: Colors.dark.tintAlpha,
    color: Colors.dark.tabIconSelected,
  },
  buttonPrimary: {
    color: Colors.dark.text,
    marginHorizontal: 2,
    marginVertical: 6,
    paddingVertical: 16,
    paddingHorizontal: 18,
    backgroundColor: `rgba(10, 5, 0, 0.1)`,
    borderColor: `rgba(255, 250, 245, 0.2)`,
    borderWidth: 1,
    borderRadius: CONSTANTS.borderRadius,
    flexDirection: "row",
    justifyContent: "center",
    //justifyItems: "center"
  },

  buttonText: {
    fontWeight: "900",
    fontSize: 18,
    fontFamily: "sans-serif",
  },
  image: {
    flex: 1,
    width: "100%",
    justifyContent: 'center',
  },
  card: {
    fontSize: 16,
    padding: "2%",
    marginVertical: 6,
    marginHorizontal: 12,
    //maxWidth: 600,
    backgroundColor: Colors.dark.background,
    borderWidth: 1,
    borderRadius: CONSTANTS.borderRadius,
    borderColor: Colors.dark.brightBlue,
    //borderColor: "rgba(248,165,4,0.92)",//`${Colors.dark.tint}`
  },
  cardSmall: {
    fontSize: 14,
    padding: 12,
    margin: 4,
    maxWidth: 400,
    backgroundColor: Colors.dark.background,
    //borderWidth: 1,
    borderRadius: CONSTANTS.borderRadius,
    borderColor: Colors['dark'].background
  },
  cardBorder: {
    borderWidth: 1,
    borderRadius: CONSTANTS.borderRadius,
    borderColor: Colors.dark.brightBlue,
  },
  listItem: {
    flexDirection: "column",
    overflow: "hidden",
    // flex: 1,
    paddingHorizontal: 16,
    paddingBottom: 6,
    paddingTop: 8,
    //border: `1px solid ${COLORS.borderColorLight}`,
    //cursor: "pointer",
    marginHorizontal: 4,
    marginVertical: 2,
    backgroundColor: Colors.dark.backgroundAlpha,
    borderRadius: CONSTANTS.borderRadius,
    /* _hover: {
       background: `rgba(200, 254, 203, 0.1)`,
     }*/
  },
  listItemActive: {
    backgroundColor: `rgba(10, 5, 0, 0.15)`,
    borderWidth: 1,
    borderColor: `${Colors.dark.tint}`
  },
  //text

});
